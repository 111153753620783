import pdfIcon from '../../../assets/pdfIcon.svg';
import textIcon from '../../../assets/textIcon.svg';
import csvIcon from '../../../assets/csvIcon.svg';
import folder from '../../../assets/folder.png';
import emptyFolder from '../../../assets/emptyFolder.png';
import fileIcon from '../../../assets/fileIcon.svg';
import videoIcon from '../../../assets/videoIcon.svg';
import pictureIcon from '../../../assets/pictureIcon.png';
import { firestore } from '../../../firebase/config';
import { FILE_COLLECTION, PROJECT_COLLECTION } from '../../../firebase/paths';
import { arrayRemove, arrayUnion } from 'firebase/firestore';

/**
 * Return a formatted string of the given bytes
 * @param {number} bytes
 * @param {number} decimals
 * @returns {string}
 */
export function formatBytes(bytes, decimals = 2) {
	if (bytes === 0) return '0 Octets';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Octets', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));
	let formattedNumber = (bytes / Math.pow(k, i)).toFixed(dm);

	// Remplacer le point par une virgule et supprimer les zéros inutiles
	formattedNumber = formattedNumber.replace('.', ',').replace(/,?0+$/, '');

	return formattedNumber + ' ' + sizes[i];
}

/**
 * Return the icon file corresponding to the given type
 * @param {string} type
 * @returns {string}
 */
export function chooseIconFile(type) {
	if (type === 'application/pdf') {
		return pdfIcon;
	} else if (type?.includes('image') || type?.includes('video') || type?.includes('heic') || type?.includes('heif')) {
		return pictureIcon;
	} else if (type?.includes('video')) {
		return videoIcon;
	} else if (type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
		return textIcon;
	} else if (type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
		return csvIcon;
	} else if (type === 'folder') {
		return folder;
	} else if (type === 'emptyFolder') {
		return emptyFolder;
	} else {
		return fileIcon;
	}
}

export async function modifyPermissionsFile(
	uid,
	type,
	agencyId,
	projectId,
	setFiles,
	file,
	permissionIds,
	clientPermission,
	isAdd,
	isInsideFolder
) {
	setFiles((prevState) => {
		const nextState = [...prevState];
		const index = nextState.findIndex((f) => f.id === file.id);
		if (index !== -1) {
			if (permissionIds) {
				if (isAdd) {
					nextState[index].permissions = [...nextState[index].permissions, ...permissionIds];
				} else {
					nextState[index].permissions = nextState[index].permissions.filter(
						(permission) => !permissionIds.includes(permission)
					);
				}
			}
			if (clientPermission) {
				nextState[index].clientPermission = isAdd;
			}
		}
		return nextState;
	});
	if (isInsideFolder) {
		let ref = firestore
			.collection(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}`)
			.where('path', '>=', `${file.path}${file.id}/`)
			.where('path', '<', `${file.path}${file.id}/` + '\uf8ff');
		if (type === 'clients') {
			ref = ref.where('createdBy.userId', '==', uid);
		} else {
			ref = ref.where('createdBy.agencyId', '==', agencyId);
		}
		ref.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					if (doc.exists) {
						if (permissionIds) {
							doc.ref.update({
								permissions: isAdd ? arrayUnion(...permissionIds) : arrayRemove(...permissionIds),
							});
						}
						if (clientPermission) {
							doc.ref.update({
								clientPermission: isAdd,
							});
						}
					}
				});
			})
			.catch((error) => {
				console.error('Error updating document: ', error);
			});
	}
	if (isAdd) {
		for (const folderId of file.path.split('/').filter((id) => id)) {
			if (permissionIds) {
				await firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${folderId}`).update({
					permissions: isAdd ? arrayUnion(...permissionIds) : arrayRemove(...permissionIds),
				});
			}
			if (clientPermission) {
				await firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${folderId}`).update({
					clientPermission: isAdd,
				});
			}
		}
	}
	if (permissionIds) {
		await firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${file.id}`).update({
			permissions: isAdd ? arrayUnion(...permissionIds) : arrayRemove(...permissionIds),
		});
	}
	if (clientPermission) {
		await firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${file.id}`).update({
			clientPermission: isAdd,
		});
	}
}

export const favoritesId = 'favorites';
export const recentsId = 'recents';
export const trashId = 'trash';

export const optionFolders = [
	{
		id: favoritesId,
		isFolder: true,
		name: 'Favoris',
		path: '/',
		isOptionFolder: true,
	},
	{
		id: recentsId,
		isFolder: true,
		name: 'Récents',
		path: '/',
		isOptionFolder: true,
	},
	{
		id: trashId,
		isFolder: true,
		name: 'Supprimés récemment',
		path: '/',
		isOptionFolder: true,
	},
];
