import React from 'react';
import Modal from "react-modal";
import crossIcon from "../../../assets/crossIcon.png";
import placeholderPhoto2 from "../../../assets/placeholderPhoto2.png";

export default function ModalAgencePartenaire({modalAgencePartenaire, setModalAgencePartenaire}) {

    return(
        <Modal isOpen={modalAgencePartenaire} className={'modalAgencePartenaire'} overlayClassName="OverlayModalNewAccount" closeTimeoutMS={300}>
            <img src={crossIcon} alt="crossIcon" className={'crossIcon'} onClick={() => {
                setModalAgencePartenaire(false)
            }}/>

            <p className={'title'}>Vous n'avez pas accès à cette fonctionnalités</p>

            <p className={'corps'}>
                Votre agence à un statut d'<span style={{color: '#f27b32'}}>Agence Partenaire</span>. Ce statut limite votre accès aux fonctionnalités d'OPUS.
                <br/> <br/>
                Si vous souhaitez rejoindre un projet crée par une entreprise sur OPUS, transmettez lui votre identifiant d'agence :

            </p>

            <div className={'IDAgence'}>
                R4VjHiq7KKQmX55giM9HYX6e7EQ2
            </div>

            <p className={'corps'}>

                Si vous souhaitez ajouter vos projets, ou que vous pensez que ce statut relève d'une erreur, veuillez nous contacter.
            </p>


            <div className={'contact'}>
                contact@opusoft.fr
            </div>
        </Modal>
        )
}
