import React, { useContext, useState } from 'react';
import '../_contributorAccountsContainer.scss';
import Modal from 'react-modal';
import crossIcon from '../../../../../assets/crossIcon.png';
import peopleIcon from '../../../../../assets/peopleIcon.svg';
import emailIcon from '../../../../../assets/emailIcon.svg';
import searchIcon from '../../../../../assets/searchIcon.png';
import inviteSent from '../../../../../assets/inviteSent.svg';
import { useTranslation } from 'react-i18next';
import ItemListAddClient from '../List Item/ItemListAddClient';
import gerySearchIcon from '../../../../../assets/gerySearchIcon.png';
import { mailtoHref } from '../../utils';
import { ROLES } from '../../../../Join Project/JoinProject';
import AgencyContext from '../../../../../contexts/agencyContext';

export default function ModalNewClient({
	modalNewClient,
	setModalNewClient,
	clients,
	actors,
	projectId,
	projectName,
	projectActive,
	projectTag,
	mandataireUid,
}) {
	const { t } = useTranslation();
	const [research, setResearch] = useState('');
	const [researchNumber, setResearchNumber] = useState(0);

	return (
		<Modal
			isOpen={modalNewClient}
			className={'modalProjectAccounts modalDecale modalAdd'}
			overlayClassName="overlayModalProjectAccounts">
			<img
				src={crossIcon}
				alt=""
				className={'crossIcon'}
				onClick={() => {
					setModalNewClient(false);
					setResearch('');
					setResearchNumber(0);
				}}
			/>

			<p className={'titleModal'}>{t('translation.addClientToProject')}</p>

			<div className={'containerSearchButton'}>
				<div className={'searchContainer'}>
					<img src={searchIcon} alt="" className={'searchIconAccounts'} />
					<input
						onChange={(e) => setResearch(e.target.value)}
						value={research}
						type="email"
						className={'searchInput'}
						placeholder={t('translation.researchNameMail')}
					/>
					<img
						src={crossIcon}
						alt=""
						className={'crossIconSearch hover'}
						style={{ opacity: research.length > 1 ? 1 : 0 }}
						onClick={() => setResearch('')}
					/>
				</div>
			</div>

			<div className={'scrollableList'}>
				<div style={{ display: 'flex' }}>
					<div className={'barModalAccounts'} />
				</div>

				{clients &&
					clients.map((item) => (
						<ItemListAddClient
							projectId={projectId}
							projectName={projectName}
							projectTag={projectTag}
							type={'clients'}
							clientId={item.uid}
							actors={actors && actors.map((item) => item.uid)}
							research={research}
							setResearchNumber={setResearchNumber}
							isActive={projectActive}
							mandataireUid={mandataireUid}
						/>
					))}

				{clients.length === 0 && (
					<div className={'placeholderMo'}>
						<p>
							{t('translation.noMoEntreprise')} <br /> <br /> {t('translation.giveTheBestExperience')}
						</p>
					</div>
				)}

				{researchNumber === 0 && research.length > 1 && (
					<div className={'noResult'}>
						<img
							src={gerySearchIcon}
							alt=""
							style={{
								width: 30,
							}}
						/>
						<p className={'noResultFound'}>{t('common.no_result_found')}</p>
						<p className={'noResultFound'}>{t('research_project.no_result_2')}</p>
					</div>
				)}

				<div style={{ display: 'flex' }}>
					<div className={'comble'} />
				</div>
			</div>
		</Modal>
	);
}
