import React from 'react';
import 'firebase/firestore';
import provence from '../../../assets/provence.jpg';
import bureauxLondres from '../../../assets/bureauxLondres.jpg';
import haussman from '../../../assets/haussman.png';
import Ajaccio from '../../../assets/Ajaccio.jpg';

export default function CardOnBoardingProjet({ nom, number }) {
	return (
		<div className={'projetCard'} style={{ opacity: 0.3 }}>
			<img
				src={
					number === 1
						? Ajaccio
						: number === 2
						  ? provence
						  : number === 3
						    ? haussman
						    : number === 4
						      ? bureauxLondres
						      : null
				}
			/>
			<div className="content">
				<div className="head">
					<div style={{ display: 'flex' }}>
						<p className="name">{nom}</p>
					</div>

					<div className={'row'}>
						<div className="bar" />
						<p
							style={{
								marginLeft: -35,
								marginTop: -30,
								fontSize: 14,
							}}
							className="pourcentage">
							{number === 1
								? '20 %'
								: number === 2
								  ? '30 %'
								  : number === 3
								    ? '50 %'
								    : number === 4
								      ? '70 %'
								      : null}
						</p>
					</div>
				</div>
				<div className="progressBars">
					<div className="firstBar">
						<div
							className="secondBar"
							style={{
								width: `calc(100% * ${
									number === 1
										? 0.4
										: number === 2
										  ? 0.6
										  : number === 3
										    ? 0.8
										    : number === 4
										      ? 0.9
										      : null
								}`,
								maxWidth: '100%',
								backgroundColor: '#56759F',
							}}
						/>
					</div>

					<div className="firstBar">
						<div
							className="secondBar"
							style={{
								width: `calc(100% * ${
									number === 1
										? 0.1
										: number === 2
										  ? 0.2
										  : number === 3
										    ? 0.4
										    : number === 4
										      ? 0.7
										      : null
								}`,
								maxWidth: '100%',
								backgroundColor: '#56759F',
							}}
						/>
					</div>

					<div className="firstBar">
						<div
							className="secondBar"
							style={{
								width: `calc(100% * ${
									number === 1
										? 0.3
										: number === 2
										  ? 0.2
										  : number === 3
										    ? 0.7
										    : number === 4
										      ? 0.6
										      : null
								}`,
								maxWidth: '100%',
								backgroundColor: '#62964d',
							}}
						/>
					</div>

					<div className="firstBar">
						<div
							className="secondBar"
							style={{
								width: `calc(100% * ${
									number === 1
										? 0.6
										: number === 2
										  ? 0.4
										  : number === 3
										    ? 0.1
										    : number === 4
										      ? 0.2
										      : null
								}`,
								maxWidth: '100%',
								backgroundColor: '#62964d',
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
