import { useEffect, useState } from 'react';
import { firestore } from '../../../../../../firebase/config';
import {
	AGENCY_COLLECTION,
	CLIENT_COLLECTION,
	COLLABORATOR_COLLECTION,
	USER_COLLECTION,
} from '../../../../../../firebase/paths';
import { dateString, timeString } from '../../../../../../useful/UsefulFunctions';
import { useTranslation } from 'react-i18next';

export default function SeenByComponent({ userId, seenAt }) {
	const { t } = useTranslation();

	const [imgUrl, setImgUrl] = useState(null);
	const [surname, setSurame] = useState(null);
	const [name, setName] = useState(null);

	// TODO (Quentin) : generalize call to firestore for performance and cost
	useEffect(() => {
		firestore
			.collection(USER_COLLECTION)
			.doc(userId)
			.get()
			.then((doc) => {
				if (doc.exists) {
					const data = { ...doc.data(), id: doc.id };
					if (data.type === 'clients') {
						firestore
							.collection(CLIENT_COLLECTION)
							.doc(userId)
							.get()
							.then((doc) => {
								if (doc.exists) {
									const data = { ...doc.data(), id: doc.id };
									setImgUrl(data.imgUrl);
									setSurame(data.surname);
									setName(data.name);
								}
							});
					} else {
						firestore
							.doc(`${AGENCY_COLLECTION}/${data.agency}/${COLLABORATOR_COLLECTION}/${userId}`)
							.get()
							.then((doc) => {
								if (doc.exists) {
									const data = { ...doc.data(), id: doc.id };
									setImgUrl(data.imgUrl);
									setSurame(data.surname);
									setName(data.name);
								}
							});
					}
				}
			});
	}, [userId]);

	return (
		<div className={'consultConfirmItem'}>
			<div className={'left'}>
				{imgUrl ? (
					<img src={imgUrl} alt="user" className={'profilePicture'} />
				) : (
					<div className={'placehodlerProfilePicture'} />
				)}

				<p className={'nameSurname'}>
					{surname} {name}
				</p>
			</div>

			<div className={'right'}>
				<p className={'dateHour'}>
					{dateString(t, seenAt)} {timeString(seenAt)}
				</p>
			</div>
		</div>
	);
}
