import React, { useContext, useEffect, useState } from 'react';
import AgencyContext from '../../../contexts/agencyContext';
import TypeContext from '../../../contexts/typeContext';
import 'firebase/firestore';
import { firestore } from '../../../firebase/config';

export default function ProjetCard(props, isWhite) {
	const agency = useContext(AgencyContext);
	const type = useContext(TypeContext);

	const [projectData, setProjectData] = useState({});
	const doc = firestore.doc(`projects/${props.id}`);
	useEffect(() => {
		doc.get().then((doc) => {
			if (doc && doc.exists) {
				setProjectData({ ...doc.data(), id: doc.id });
			}
		});
	}, [agency, props]);

	return (
		<div
			className="blankProjetCard"
			style={{
				backgroundColor: isWhite ? '#fff' : null,
				marginRight: isWhite ? 18 : null,
				height: isWhite ? 2 : null,
			}}></div>
	);
}
