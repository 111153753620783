import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import crossIcon from '../assets/crossIcon.png';
import { useTranslation } from 'react-i18next';
import Matterport from '../assets/Matterport.png';

export default function ModalTyping({
	modalTyping,
	setModalTyping,
	title,
	func,
	placeholder,
	defaultValue,
	matterportActive,
	isDate,
}) {
	const { t } = useTranslation();

	const [text, setText] = useState('');
	const ref = useRef();

	useEffect(() => {
		if (modalTyping) {
			setTimeout(() => {
				ref.current?.focus();
			}, 500);
		}
	}, [modalTyping]);

	useEffect(() => {
		setText(defaultValue ? defaultValue : '');
	}, [modalTyping]);

	return (
		<Modal
			isOpen={modalTyping}
			className={matterportActive ? 'bigModalTyping' : 'modalTyping'}
			overlayClassName="overlayModalTyping"
			closeTimeoutMS={300}>
			<div
				className={'crossButton'}
				onClick={() => {
					setModalTyping(false);
					setText('');
				}}>
				<img src={crossIcon} alt="" className={'crossIcon'} />
			</div>
			<p className={'nomTyping'}>{title}</p>

			{matterportActive ? (
				<div>
					<input
						type="text"
						ref={ref}
						className={'inputTyping'}
						placeholder={t('common.last_name')}
						value={text}
						onChange={(e) => setText(e.target.value)}
					/>
					<div style={{ display: 'flex' }}>
						<button
							onClick={async (e) => {
								e.preventDefault();
								setModalTyping(false);
								await func(text);
								setText('');
							}}
							className={'buttonTyping'}
							style={{ borderWidth: 0 }}>
							{t('photos.create_standard_album')}
						</button>
						<button
							className={'buttonTyping'}
							style={{ borderWidth: 0, marginRight: 20 }}
							onClick={async (e) => {
								e.preventDefault();
								setModalTyping(false);
								await func(text, true);
								setText('');
							}}>
							{t('photos.create_matterport_album')}
							<img
								src={Matterport}
								alt=""
								style={{
									width: 22,
									objectFit: 'contain',
									display: 'flex',
									position: 'absolute',
									right: 0,
									marginRight: 27,
								}}
							/>
						</button>
					</div>
				</div>
			) : (
				<form
					onSubmit={async (e) => {
						e.preventDefault();
						setModalTyping(false);
						await func(text);
						setText('');
					}}>
					<input
						type={isDate ? 'date' : 'text'}
						ref={ref}
						className={'inputTyping'}
						placeholder={placeholder ?? t('common.last_name')}
						value={text}
						onChange={(e) => setText(e.target.value)}
					/>
					<button className={'buttonTyping'} style={{ borderWidth: 0 }}>
						{t('common.save')}
					</button>
				</form>
			)}
		</Modal>
	);
}
