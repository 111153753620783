import React, { useContext, useState } from 'react';
import downloadIcon from '../../../../../../assets/downloadIcon.svg';
import fullBlueStar from '../../../../../../assets/fullBlueStar.svg';
import emptyStar from '../../../../../../assets/emptyStar.svg';
import trashIcon from '../../../../../../assets/trashIcon.svg';
import bigEditIcon from '../../../../../../assets/bigEditIcon.svg';
import moveIcon from '../../../../../../assets/moveIcon.svg';
import { getPathStorageFromUrl } from '../../../../../../firebase/utils';
import ModalTyping from '../../../../../../components/ModalTyping';
import { auth, firestore } from '../../../../../../firebase/config';
import { FILE_COLLECTION, PROJECT_COLLECTION } from '../../../../../../firebase/paths';
import chargement from '../../../../../../assets/chargement.png';
import { useTranslation } from 'react-i18next';
import { normalizeString } from '../../../../../../useful/UsefulFunctions';
import AgencyContext from '../../../../../../contexts/agencyContext';

export default function ButtonsDocumentInfos({
	projectId,
	selectedFileIds,
	setSelectedFileIds,
	files,
	setFiles,
	path,
	setPath,
	canModify,
	isInTrash,
	selectedFiles,
	setModalMoveItem,
	setIsLoading,
	isLoading,
}) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const agencyId = useContext(AgencyContext);

	// Download
	const handleDownload = async () => {
		try {
			const file = selectedFiles[0];
			const a = document.createElement('a');
			if (file.isFolder) {
				a.href = `https://transfer.opusoft.app/downloadFolder/${encodeURIComponent(projectId)}/${encodeURIComponent(
					file.id
				)}/${encodeURIComponent(file.name)}/${encodeURIComponent(uid)}`;
			} else {
				a.href = `https://transfer.opusoft.app/download/${encodeURIComponent(
					getPathStorageFromUrl(file.url)
				)}/${encodeURIComponent(file.name)}`;
			}
			a.download = file.name;
			a.click();
		} catch (error) {
			console.error('Download failed:', error);
			alert('Failed to download file. Please check your network connection and try again.');
		}
	};

	// Favorite
	const handleFavorite = async () => {
		setIsLoading(true);
		const newFavorite = selectedFiles.every((it) => it.isFavorite);
		setFiles((prevState) => {
			const nextState = [...prevState];
			for (const file of selectedFiles) {
				const index = nextState.findIndex((f) => f.id === file.id);
				if (index !== -1) {
					nextState[index].isFavorite = newFavorite;
				}
			}
			return nextState;
		});
		for (const file of selectedFiles) {
			await firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${file.id}`).update({
				isFavorite: newFavorite,
			});
		}
		setIsLoading(false);
	};

	const handleDelete = async () => {
		if (!window.confirm('Êtes-vous sûr de vouloir supprimer ces éléments ?')) {
			return;
		}

		setIsLoading(true);

		const batchs = [];
		const promises = [];

		try {
			for (const file of selectedFiles) {
				if (path.includes(file.id)) {
					setPath(path.slice(0, path.indexOf(file.id)));
				}

				setFiles((prevState) =>
					prevState.filter((it) => it.id !== file.id && !it.path.includes(`${file.path}/${file.id}/`))
				);
				setSelectedFileIds([]);

				let batch = firestore.batch();
				let batchCount = 500;

				if (file.isFolder) {
					promises.push(
						new Promise((resolve, reject) => {
							firestore
								.collection(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}`)
								.where('path', '>=', `${file.path}${file.id}/`)
								.where('path', '<', `${file.path}${file.id}/\uf8ff`)
								.get()
								.then((querySnapshot) => {
									querySnapshot.forEach(async (doc) => {
										if (doc.exists) {
											batch.update(
												firestore.doc(
													`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${doc.id}`
												),
												{
													path: doc.data().path.replace(file.path, '/trash/'),
													previousPath: doc.data().path,
													deletedAt: new Date().toISOString(),
													modifiedAt: new Date().toISOString(),
													clientPermission: false,
													permissions: [agencyId],
												}
											);
											batchCount--;
											if (batchCount === 0) {
												batchs.push(batch);
												batchCount = 500;
												batch = firestore.batch();
											}
										}
									});

									resolve();
								})
								.catch(reject);
						})
					);
				}

				batch.update(firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${file.id}`), {
					path: '/trash/',
					previousPath: file.path,
					deletedAt: new Date().toISOString(),
					modifiedAt: new Date().toISOString(),
					clientPermission: false,
					permissions: [agencyId],
				});

				batchs.push(batch);
			}

			await Promise.all(promises);

			for (const batch of batchs) {
				await batch.commit();
			}

			setIsLoading(false);
		} catch (error) {
			console.error('Error deleting files:', error);
			setIsLoading(false);
		}
	};

	// Rename
	const [modalRename, setModalRename] = useState(false);
	const handleRename = () => {
		setModalRename(true);
	};

	const renameFile = async (newName, file) => {
		setFiles((prevFiles) => {
			return prevFiles.map((prevFile) => {
				if (prevFile.id === file.id) {
					return {
						...prevFile,
						name: newName.length > 0 ? newName : prevFile.name,
						normalizedName: normalizeString(newName.length > 0 ? newName : prevFile.name),
					};
				} else {
					return prevFile;
				}
			});
		});
		await firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${file.id}`).update({
			name: newName.length > 0 ? newName : file.name,
			normalizedName: normalizeString(newName.length > 0 ? newName : file.name),
		});
	};

	// Move item

	function handleMoveItem() {
		setModalMoveItem(true);
	}

	return (
		<div className="containerButtons">
			<div>
				<div className="hover" onClick={handleDownload}>
					<img src={downloadIcon} alt="" />
				</div>

				{canModify && !isInTrash && (
					<div className="hover" onClick={handleMoveItem}>
						<img src={moveIcon} alt="" />
					</div>
				)}

				{selectedFiles.every((it) => !it.isFolder) && !isInTrash && (
					<div className="hover" onClick={handleFavorite}>
						<img src={selectedFiles.every((it) => it.isFavorite) ? fullBlueStar : emptyStar} alt="" />
					</div>
				)}

				{canModify && !isInTrash && (
					<div className="hover" onClick={handleDelete}>
						{isLoading ? (
							<div className={'chargementContainer'} style={{ width: 20, height: 20, marginLeft: 0 }}>
								<img src={chargement} alt="" className={'chargement'} />
							</div>
						) : (
							<img src={trashIcon} alt="" />
						)}
					</div>
				)}

				{selectedFiles.length === 1 && canModify && !isInTrash && (
					<div className="hover" onClick={handleRename}>
						<img src={bigEditIcon} alt="" />
					</div>
				)}
			</div>

			<ModalTyping
				modalTyping={modalRename}
				setModalTyping={setModalRename}
				func={(text) => renameFile(text, selectedFiles[0])}
				defaultValue={selectedFiles[0]?.name}
				placeholder={t('common.last_name')}
				title={t('common.rename')}
			/>
		</div>
	);
}
