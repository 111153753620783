import React, { useContext, useEffect, useState } from 'react';
import 'firebase/auth';
import LastProjectMenu from './LastProjectMenu';
import TypeContext from '../../contexts/typeContext';
import AgencyContext from '../../contexts/agencyContext';
import { NavLink, useNavigate } from 'react-router-dom';
import MenuNav from './MenuNav';
import settingsIcon from '../../assets/settingsIcon.png';
import messagerieMenuIcon from '../../assets/messagerieMenuIcon.svg';
import { auth, firestore } from '../../firebase/config';
import { Pages } from '../../pages/Fiche Projet/utils';
import LanguageSelector from '../LanguageSelector/LanguageSelector';

export default function Menu(props) {
	const type = useContext(TypeContext);
	const agency = useContext(AgencyContext);
	const uid = auth.currentUser?.uid;
	const navigate = useNavigate();

	const [isAdministrator, setIsAdministrator] = useState(localStorage.getItem('administrator') === 'true');

	const [pastilleNumber, setPastilleNumber] = useState(0);

	useEffect(() => {
		firestore
			.collection(`users/${uid}/messaging`)
			.where('isUnread', '==', true)
			.limit(9)
			.onSnapshot((querySnapshot) => {
				if (querySnapshot) {
					setPastilleNumber(querySnapshot.size);
				}
			});
	}, [uid, type, agency]);

	useEffect(() => {
		if (type === 'collaborators' && agency) {
			firestore
				.doc(`agencies/${agency}`)
				.get()
				.then((documentSnapshot) => {
					if (documentSnapshot && documentSnapshot.exists) {
						const agencyData = documentSnapshot.data();
						if (!documentSnapshot.data().integrationFinish) {
							firestore
								.doc(`agencies/${agency}/collaborators/${uid}`)
								.get()
								.then((documentSnapshot) => {
									if (documentSnapshot && documentSnapshot.exists) {
										localStorage.setItem(
											'administrator',
											documentSnapshot.data().subtype === 'administrator' ? 'true' : 'false'
										);
										setIsAdministrator(documentSnapshot.data().subtype === 'administrator');
									}
								});
						}
					}
				});
		}
	}, [agency, uid, type]);

	return (
		<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
			<div className="menu" style={{ borderBottomColor: '#f3f3f3' }}>
				<div className={'menuWidget'}>
					<div style={{ width: 300 }}>
						{!props.lastProject ? (
							<NavLink
								className={'hover'}
								style={{ display: 'flex', marginLeft: 5 }}
								exact
								to={{
									pathname: '/projets',
								}}
								activeClassName="nav-active">
								<LastProjectMenu isAdministrator={isAdministrator} projectId={null} />
							</NavLink>
						) : (
							<div
								className={'hover'}
								style={{ display: 'flex', marginLeft: 5 }}
								onClick={async () => {
									navigate('/fiche-projet', {
										state: { projectId: props.lastProject },
									});
									localStorage.setItem('projectId', props.lastProject);
									localStorage.setItem('page', Pages.FICHIERS);
									if (type === 'collaborators') {
										await firestore
											.doc(`agencies/${agency}/${type}/${uid}/projects/${props.lastProject}`)
											.update({ lastClick: new Date().toISOString(), seen: true });
										await firestore
											.doc(`agencies/${agency}/collaborators/${uid}`)
											.update({ lastConnexion: new Date().toISOString() });
									} else {
										await firestore
											.doc(`${type}/${uid}/projects/${props.lastProject}`)
											.update({ lastClick: new Date().toISOString(), seen: true });
									}
								}}>
								<LastProjectMenu projectId={props.lastProject} />
							</div>
						)}
					</div>

					<MenuNav
						grasComptes={props.grasComptes}
						grasProjet={props.grasProjet}
						backToOrganisation={props.backToOrganisation}
					/>

					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							width: 300,
						}}>
						<NavLink exact to="/global-messagerie">
							<div>
								<div
									className={'hover'}
									style={{
										marginTop: 19,
										display: 'flex',
										marginRight: 20,
										alignItems: 'center',
										justifyContent: 'center',
									}}>
									<img
										src={messagerieMenuIcon}
										alt=""
										style={{
											width: 18,
											objectFit: 'contain',
										}}
									/>
									{pastilleNumber > 0 && (
										<div style={{ display: 'flex', zIndex: 2 }}>
											<div className={'notifsMessages'}>{pastilleNumber}</div>
										</div>
									)}
								</div>
							</div>
						</NavLink>

						<NavLink exact to="/parametres-profil">
							<div>
								<div className={'hover'} style={{ marginTop: 18 }}>
									<img
										src={settingsIcon}
										alt=""
										style={{
											width: 20,
											marginRight: 10,
											objectFit: 'contain',
										}}
									/>
								</div>
							</div>
						</NavLink>

						<div style={{ marginTop: 8, marginRight: 15 }}>
							<LanguageSelector openBottom={true} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
