import React, { useContext, useEffect, useState } from 'react';
import crossIcon from '../../../assets/crossIcon.png';
import placeholderPhoto2 from '../../../assets/placeholderPhoto2.png';
import Modal from 'react-modal';
import { NavLink } from 'react-router-dom';
import arrowWhite from '../../../assets/arrowWhite.png';
import AgencyContext from '../../../contexts/agencyContext';
import ModalChooseCollab from './ModalChooseCollab';
import { generateUniqueFirestoreId } from '../../../firebase/utils';
import { createProject } from '../../../useful/UsefulForProject';
import { useTranslation } from 'react-i18next';
import { Pages } from '../../Fiche Projet/utils';
import { firestore } from '../../../firebase/config';
import TypeContext from '../../../contexts/typeContext';

export default function ModauxAddProject({ auth, modalAddProject, setModalAddProject }) {
	const { t } = useTranslation();

	const type = useContext(TypeContext);
	const agency = useContext(AgencyContext);

	const [modalFelicitation, setModalFelicitation] = useState(false);
	const [modalChooseReferent, setModalChooseReferent] = useState(false);

	const [loading, setLoading] = useState(false);
	const [name, setName] = useState('');
	const [cant, setCant] = useState(false);
	const [newProjectId, setNewProjectId] = useState();
	const [img, setImg] = useState(placeholderPhoto2);
	const [imgFile, setImgFile] = useState(null);

	const imageHandler = async (e) => {
		const reader = new FileReader();
		reader.onload = () => {
			if (reader.readyState === 2) {
				setImg(reader.result);
			}
		};
		reader.readAsDataURL(e.target.files[0]);
		setImgFile(e.target.files[0]);
	};

	async function newProject() {
		setLoading(true);
		const projectId = generateUniqueFirestoreId();
		const end = () => {
			setModalAddProject(false);
			setLoading(false);
			setNewProjectId(projectId);
			setModalFelicitation(true);
		};

		await createProject(auth, agency, projectId, imgFile, name, end, t);
	}

	// ------------------------------ Animation Modal Felicitation ----------------------------------

	const [count1, setCount1] = useState(false);
	const [photoBorderRadius, setPhotoBorderRadius] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			setCount1(true);
		}, 0);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			if (count1) {
				setPhotoBorderRadius(15);
			}
		}, 1000);
	}, [count1]);

	function Loading() {
		const [marginLeftBar, setMarginLeftBar] = useState(0);
		const [marginRightBar, setMarginRightBar] = useState(-400);
		const [widthBar, setWidthBar] = useState(0);

		setTimeout(() => {
			setWidthBar(290);
		}, 500);

		if (loading) {
			return (
				<div className={'loadingCreateProject'} style={{ marginLeft: 0 }}>
					<p
						style={{
							marginTop: 10,
							fontSize: 13,
							fontWeight: 500,
							color: '#8d8d8d',
							marginLeft: 30,
							marginRight: 30,
							textAlign: 'center',
						}}>
						{t('modaux_add_project.loading_creation_project')}
					</p>
					<div className={'barGrise'}>
						<div
							style={{ marginLeft: marginLeftBar, width: widthBar, marginRight: marginRightBar }}
							className={'barBeige'}
						/>
					</div>
				</div>
			);
		} else {
			return null;
		}
	}

	return (
		<>
			<Modal
				isOpen={modalAddProject}
				className={'modalNewProjet'}
				overlayClassName="OverlayModalNewProjet"
				closeTimeoutMS={300}>
				{!loading && (
					<img
						src={crossIcon}
						alt="crossIcon"
						className={'crossIcon'}
						onClick={() => {
							setModalAddProject(false);
							setName('');
							setImg(placeholderPhoto2);
							setImgFile(null);
							setNewProjectId(null);
							setCant(false);
						}}
					/>
				)}

				<div style={{ display: 'flex', flexDirection: 'row' }}>
					{name && name ? <h2>{name}</h2> : <h2>{t('modaux_add_project.add_a_new_project')}</h2>}
				</div>

				<div style={{ marginTop: 0, marginBottom: -20 }}>
					{!loading && <p className={'photoTitle'}>{t('modaux_add_project.photo_project')}</p>}

					<div className="photo">
						<div className="photos">
							<div className="img-holder">
								<div className="label">
									<label htmlFor="input">
										<img src={img} className="photos" />
									</label>
								</div>
							</div>
						</div>
						{!loading && (
							<input type="file" id="input" accept="image/*,.heic, .heif" onChange={imageHandler} />
						)}
					</div>

					<Loading />

					{!loading && (
						<div className={'informationsGenerales'}>
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								<div>
									<p className={'inputTitle'}>{t('modaux_add_project.name_project')}</p>
									<div style={{ display: 'flex' }}>
										<input
											type="text"
											maxLength={58}
											className="inputName"
											id={'name'}
											placeholder={t('modaux_add_project.name_project')}
											value={name}
											onChange={(e) => setName(e.target.value)}
										/>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>

				{!loading && name.length > 1 ? (
					<div style={{ display: 'flex', marginTop: 35 }} className={'buttonGroup'}>
						<div className={'jeConfirmeButton'} onClick={newProject}>
							<p className={'textJeConfirme'} style={{ fontSize: 13, fontWeight: 600 }}>
								{t('modaux_add_project.add_this_project')}
							</p>
						</div>
						{/*<img src={checkBleu} alt="choice" className={'choice'} onClick={createProject}/>*/}
					</div>
				) : (
					!loading && (
						<div
							style={{ display: 'flex', marginTop: 35 }}
							className={'buttonGroup'}
							onClick={() => setCant(true)}>
							<div className={'jeConfirmeButton'}>
								<p className={'textJeConfirme'} style={{ fontSize: 13, fontWeight: 600 }}>
									{t('modaux_add_project.add_this_project')}
								</p>
							</div>
							{/*<img src={checkBleu} alt="choice" className={'choice'} onClick={() => setCant(true)}/>*/}
						</div>
					)
				)}

				{!loading && (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: cant ? 30 : 0,
							marginTop: 10,
						}}>
						<p className={'requireCreate'} style={{ fontSize: cant ? (!imgFile ? 13 : 0) : 0 }}>
							{t('modaux_add_project.requirement_project')}
						</p>
					</div>
				)}

				<div style={{ height: 10 }} />
			</Modal>

			<Modal
				isOpen={modalFelicitation}
				className={'modalFelicitation'}
				overlayClassName="OverlayModalFelicitation"
				closeTimeoutMS={300}>
				<img
					src={crossIcon}
					alt="crossIcon"
					className={'crossIcon'}
					onClick={() => {
						setModalFelicitation(false);
						setName('');
						setImg(placeholderPhoto2);
						setImgFile(null);
						setNewProjectId(null);
						setCant(false);
					}}
				/>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
						width: 520,
						overflowX: 'hidden',
					}}>
					<p className={'message'}>{t('modaux_add_project.success_creation', { name: name })}</p>
					<img style={{ height: 250, borderRadius: photoBorderRadius }} src={img} className={'imgProjet'} />

					<NavLink
						exact
						to={{
							pathname: '/fiche-projet',
							state: { projectId: newProjectId },
						}}
						onClick={async () => {
							localStorage.setItem('projectId', newProjectId);
							localStorage.setItem('page', Pages.FICHIERS);
							setModalFelicitation(false);
							setName('');
							setImg(placeholderPhoto2);
							setImgFile(null);
							setNewProjectId(null);
							setCant(false);
							await firestore
								.doc(`agencies/${agency}/${type}/${auth}/projects/${id}`)
								.update({ lastClick: new Date().toISOString(), seen: true });
							await firestore
								.doc(`agencies/${agency}/collaborators/${auth}`)
								.update({ lastConnexion: new Date().toISOString() });
						}}>
						<div style={{ display: 'flex' }} className={'hover'}>
							<p
								className={'color1'}
								style={{
									fontSize: 13,
									marginTop: -12,
									marginBottom: 12,
									fontWeight: 600,
								}}>
								{t('modaux_add_project.access_the_project')}
							</p>
						</div>
					</NavLink>

					<div style={{ display: 'flex', marginTop: 5 }} className={'buttonGroup'}>
						<div
							className={'jeConfirmeButton'}
							onClick={() => {
								setModalFelicitation(false);
								setModalAddProject(true);
								setName('');
								setImg(placeholderPhoto2);
								setImgFile(null);
								setNewProjectId(null);
								setCant(false);
							}}>
							<p className={'textJeConfirme'}>{t('modaux_add_project.add_another_project')}</p>
							<img src={arrowWhite} alt="" className={'arrowWhite'} />
						</div>
					</div>
				</div>
			</Modal>

			<ModalChooseCollab
				auth={auth}
				projectId={newProjectId}
				modal={modalChooseReferent}
				setModal={setModalChooseReferent}
				setModalFelecitation={setModalFelicitation}
				projectName={name}
				projectTag={name}
				mandataireUid={agency}
			/>
		</>
	);
}
