import React from 'react';
import Modal from 'react-modal';
import { BrowserRouter, Link, useNavigate } from 'react-router-dom';
import { dateString } from '../../../../useful/UsefulFunctions';
import { useTranslation } from 'react-i18next';
import crossIcon from '../../../../assets/crossIcon.png';
import { auth } from '../../../../firebase/config';
import { signOut } from 'firebase/auth';

export default function ModalResiliation({ modal, setModal, canceledDate, deletionDate }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<Modal isOpen={modal} className={'modalResiliation'} overlayClassName="OverlayModal">
			<img
				src={crossIcon}
				alt="crossIcon"
				className={'crossIcon'}
				onClick={() => {
					if (process.env.NODE_ENV === 'development') {
						setModal(false);
					} else {
						signOut(auth).then(() => navigate('/'));
					}
				}}
			/>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}>
				<p className={'title'} style={{ marginTop: 25, marginBottom: 8 }}>
					<span style={{ color: '#4B5FB0' }}>{t('billing.plan_cancel')}</span>
				</p>
				<p className={'title'} style={{ marginTop: 0, marginBottom: 10 }}>
					{t('billing.you_end_your_plan')}
				</p>
				<p className={'corps'}>
					{t('billing.info_end_plan_1')}{' '}
					<span style={{ color: '#4B5FB0' }}>{dateString(t, canceledDate * 1000)}</span>
					{t('billing.info_end_plan_2')}{' '}
					<span style={{ color: '#4B5FB0' }}>l{t('billing.info_end_plan_3')}</span>
				</p>

				<div className={'joursRestant'}>
					<p className={'nombreJours'}>{Math.round((deletionDate - canceledDate) / 86400)}</p>
					<p className={'restant'}>{t('billing.days_left')}</p>
				</div>

				<div className={'buttonAnnuler'}>
					<p className={'textButtonAnnuler'}>{t('billing.access_my_account')}</p>
				</div>

				<BrowserRouter>
					<Link to={{ pathname: 'https://opusoft.fr/contact' }} target="_blank">
						<p className={'contact'}>{t('billing.contact_our_help_service')}</p>
					</Link>
				</BrowserRouter>
			</div>
		</Modal>
	);
}
