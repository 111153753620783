import React, { useContext, useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import crossIcon from '../../assets/crossIcon.png';
//import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";
import { useTranslation } from 'react-i18next';
import AuthDataContext from '../../contexts/authDataContext';
import { auth, firestore } from '../../firebase/config';
import ModalPassword from '../../components/ModalPassword';
import TypeContext from '../../contexts/typeContext';
import {
	RecaptchaVerifier,
	PhoneAuthProvider,
	EmailAuthProvider,
	PhoneMultiFactorGenerator,
	reauthenticateWithCredential,
} from 'firebase/auth';

export default function ModalCodeDoubleAuth({ modalCodeDoubleAuth, setModalCodeDoubleAuth, isConfigMFA, verifId }) {
	const { t } = useTranslation();

	const user = auth.currentUser;
	const userData = useContext(AuthDataContext);
	const type = useContext(TypeContext);

	const [phone, setPhone] = useState(userData?.phone ?? '');
	const [code, setCode] = useState('');
	const [error, setError] = useState(false);
	const [askPhone, setAskPhone] = useState(false);
	const [askPassword, setAskPassword] = useState(false);
	const [password, setPassword] = useState('');

	const [verificationId, setVerificationId] = useState(null);
	const [phoneProvider, setPhoneProvider] = useState(null);
	const [recaptcha, setRecaptcha] = useState(null);

	const phoneRef = useRef(null);
	const codeRef = useRef(null);
	const passwordRef = useRef(null);

	useEffect(() => {
		if (modalCodeDoubleAuth) {
			if (isConfigMFA) {
				setTimeout(() => {
					phoneRef.current?.focus();
				}, 500);
			} else if (askPassword) {
				setTimeout(() => {
					passwordRef.current?.focus();
				}, 500);
			} else {
				setTimeout(() => {
					codeRef.current?.focus();
				}, 500);
			}
		}
	}, [isConfigMFA, verifId, modalCodeDoubleAuth, codeRef, phoneRef, passwordRef]);

	const handleSubmitPhone = (e) => {
		e.preventDefault();
		setAskPhone(false);
		setAskPassword(true);
		setTimeout(() => {
			passwordRef.current?.focus();
		}, 500);
	};

	const handleSubmitPassword = (e) => {
		e.preventDefault();
		const func = () => {
			user.multiFactor.getSession().then(function (multiFactorSession) {
				const recaptchaVerifier = new RecaptchaVerifier('boutonMfa', { size: 'invisible' });
				setRecaptcha(recaptchaVerifier);
				// Specify the phone number and pass the MFA session.
				const phoneInfoOptions = {
					phoneNumber: phone,
					session: multiFactorSession,
				};

				const phoneAuthProvider = new PhoneAuthProvider();
				setPhoneProvider(phoneAuthProvider);
				// Send SMS verification code.
				phoneAuthProvider
					.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
					.then(function (verificationId) {
						setVerificationId(verificationId);
						setAskPassword(false);
						setTimeout(() => {
							codeRef.current?.focus();
						}, 500);
					});
			});
		};
		if (isConfigMFA) {
			const credential = EmailAuthProvider.credential(user.email, password);
			reauthenticateWithCredential(user, credential)
				.then(() => {
					func();
				})
				.catch((error) => {
					if (error.code === 'auth/wrong-password') {
						window.alert('Wrong password.');
					} else {
						window.alert(error.message);
					}
				});
		} else {
			const credential = EmailAuthProvider.credential(user.email, password);
			reauthenticateWithCredential(user, credential)
				.then(() => {
					func();
				})
				.catch((error) => {
					setError(error);
				});
		}
	};

	const handleSubmitCode = (e) => {
		e.preventDefault();
		if (code.length === 6) {
			const cred = PhoneAuthProvider.credential(verificationId, code);
			const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

			// Complete enrollment.
			user.multiFactor
				.enroll(multiFactorAssertion, 'phone 1')
				.then(async () => {
					await firestore
						.doc(
							type === 'collaborators'
								? `agencies/${userData?.agency}/collaborators/${user?.uid}`
								: `${type}/${user?.uid}`
						)
						.update({
							mfaPhone: phone,
						});
					setModalCodeDoubleAuth(false);
					setAskPhone(isConfigMFA);
					setAskPassword(!isConfigMFA);
					setPhone(userData?.phone ?? '');
					setPassword('');
					setCode('');
				})
				.catch((error) => alert(t('common.error') + error));
		}
	};

	const resendCode = () => {
		phoneProvider?.verifyPhoneNumber(phone, recaptcha).then(function (verificationId) {
			setVerificationId(verificationId);
		});
	};

	return (
		<Modal
			isOpen={modalCodeDoubleAuth}
			className={'modalCodeDoubleAuth'}
			overlayClassName="OverlayModal"
			closeTimeoutMS={300}>
			<img
				src={crossIcon}
				alt="crossIcon"
				className={'crossIcon'}
				onClick={() => {
					setModalCodeDoubleAuth(false);
					setTimeout(() => {
						setAskPhone(isConfigMFA);
						setAskPassword(!isConfigMFA);
						setPhone(userData?.phone ?? '');
						setPassword('');
						setCode('');
					}, 1000);
				}}
			/>

			<div>
				<p className={'title'}>{t('settings_safety.two_steps_auth')}</p>

				{askPhone ? (
					<>
						<p className={'subtitle'} style={{ marginBottom: askPhone ? 30 : null }}>
							{t('settings_safety.requirement_activate_two_steps')}
						</p>
					</>
				) : (
					<>
						<p className={'subtitle'}>{t('settings_safety.continue_config_double_auth')}</p>

						<p className={'subtitle'}>
							{t('settings_safety.code_unreceived')}
							<span style={{ color: '#4F66E3', fontWeight: 600, opacity: 1 }} onClick={resendCode}>
								{t('settings_safety.resend')}
							</span>
							.
						</p>
					</>
				)}
			</div>

			{askPhone ? (
				<form onSubmit={handleSubmitPhone}>
					<div>
						{/*<PhoneInput
                            ref={phoneRef}
                            withCountryCallingCode={true}
                            international={true}
                            value={phone}
                            onChange={setPhone}
                            style={{
                                marginLeft: 10,
                                marginRight: 10,
                            }}
                            required={true}
                        />*/}
					</div>

					<button id={'boutonMfa'} className={'buttonContinuer'} style={{ marginTop: 40 }}>
						{t('common.continue')}
					</button>
				</form>
			) : askPassword ? (
				<form onSubmit={handleSubmitPassword}>
					<div>
						<input
							ref={passwordRef}
							type={'password'}
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							required={true}
						/>
					</div>

					<button id={'boutonMfa'} className={'buttonContinuer'}>
						{t('common.continue')}
					</button>
				</form>
			) : (
				<div>
					<div>
						<input
							ref={codeRef}
							type="text"
							className={'inputCode'}
							placeholder={'000000'}
							value={code}
							onChange={(e) => setCode(e.target.value)}
							maxLength={6}
						/>
					</div>

					<button
						id={'boutonMfa'}
						className={'buttonContinuer'}
						onClick={handleSubmitCode}
						disabled={code.length !== 6}>
						{t('common.continue')}
					</button>
				</div>
			)}
		</Modal>
	);
}
