import React, { useState } from 'react';
import crossIcon from '../../../../assets/crossIcon.png';
import Modal from 'react-modal';
import ModalPassword from '../../../../components/ModalPassword';
import { useTranslation } from 'react-i18next';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../../firebase/config';

export default function ModalDeleteCollaborator({
	modal,
	setModal,
	accountData,
	setAccounts,
	setIsModifContactOpen,
	setDeletionLoading,
}) {
	const { t } = useTranslation();

	const [modalPassword, setModalPassword] = useState(false);

	async function supprCollab() {
		setDeletionLoading(true);
		const suppr = httpsCallable(functions, 'deleteUser');
		suppr({
			uid: accountData.uid,
		})
			.then((res) => {
				setAccounts((prevState) => prevState.filter((collab) => collab.uid !== accountData.uid));
				setIsModifContactOpen(false);
				setDeletionLoading(false);
			})
			.catch((err) => {
				setDeletionLoading(false);
				window.alert(t('common.error_deleting_collab'));
			});
	}

	return (
		<>
			<Modal
				isOpen={modal}
				className={'modalConfirmationSuppressionCompte'}
				overlayClassName="OverlayModal"
				closeTimeoutMS={300}>
				<img
					src={crossIcon}
					alt="crossIcon"
					className={'crossIcon'}
					onClick={() => {
						setModal(false);
					}}
				/>

				<div className={'center'}>
					<p className={'felicitation'}>{t('delete_partner.confirmation')}</p>

					<div className={'emailContainer'}>
						<p className={'email'}>{accountData.surname + ' ' + accountData.name}</p>
					</div>

					<p className={'detailFelicitation'}>{t('delete_partner.want_to_delete_collab')}</p>

					<p className={'detailFelicitation'}>{t('delete_partner.by_deleting_infos')}</p>

					<div style={{ display: 'flex', marginTop: 20 }} className={'buttonGroup'}>
						<div
							className={'jeConfirmeButton'}
							onClick={() => {
								setModal(false);
								setModalPassword(true);
							}}>
							<p className={'textJeConfirme'}>{t('common.delete')}</p>
						</div>
					</div>
				</div>
			</Modal>

			<ModalPassword modalPassword={modalPassword} setModalPassword={setModalPassword} toExecute={supprCollab} />
		</>
	);
}
