import React, { useContext, useEffect, useRef, useState } from 'react';
import './_folderPath.scss';
import rightIcon from '../../../../../assets/righticon.png';
import replacePictures from '../../../../../assets/replacePictures.svg';
import ModalReplaceTemplate from '../../../components/ModalReplaceTemplate';
import { useProjectContext } from '../../../../../contexts/projectContext';
import { auth, firestore, storage } from '../../../../../firebase/config';
import { FILE_COLLECTION, PROJECT_COLLECTION } from '../../../../../firebase/paths';
import { generateUniqueFirestoreId, getPathStorageFromUrl } from '../../../../../firebase/utils';
import TypeContext from '../../../../../contexts/typeContext';
import AgencyContext from '../../../../../contexts/agencyContext';

export default function FolderPath({ t, path, setPath, files, setFiles, setSelectedFileIds }) {
	const ref = useRef(null);
	const [modalReplaceTemplate, setModalReplaceTemplate] = useState(false);
	const [project, setProject] = useProjectContext();
	const type = useContext(TypeContext);
	const uid = auth.currentUser?.uid;
	const agencyId = useContext(AgencyContext);

	// Listener path
	useEffect(() => {
		// scroll to end (right)
		if (ref && ref.current) {
			ref.current.scrollLeft = ref.current.scrollWidth;
		}
	}, [path]);

	async function deleteAllFiles() {
		const BATCH_SIZE = 500;
		const mediasSnapshot = await firestore
			.collection(`${PROJECT_COLLECTION}/${project.id}/${FILE_COLLECTION}`)
			.get();

		let batch = firestore.batch();
		let batchPromises = [];
		let deletePromises = [];
		let operationCounter = 0;

		for (const media of mediasSnapshot.docs) {
			batch.delete(media.ref);

			if (media.data().url) {
				const fileRef = storage.ref(getPathStorageFromUrl(media.data().url));
				deletePromises.push(fileRef.delete());
			}

			operationCounter++;

			if (operationCounter === BATCH_SIZE) {
				batchPromises.push(batch.commit());
				batch = firestore.batch();

				await Promise.all(deletePromises);
				deletePromises = [];

				operationCounter = 0;
			}
		}

		if (operationCounter > 0) {
			batchPromises.push(batch.commit());
			await Promise.all(deletePromises);
		}

		await Promise.all(batchPromises);

		setFiles([]);
	}

	async function replaceByTemplate(projectModel) {
		const BATCH_SIZE = 500;
		try {
			await deleteAllFiles();

			const folders = await firestore
				.collection(`${PROJECT_COLLECTION}/${projectModel.id}/${FILE_COLLECTION}`)
				.where('isFolder', '==', true)
				.where('deletedAt', '==', null)
				.orderBy('createdAt')
				.get();

			let batch = firestore.batch();
			const newFolders = [];
			const mapIds = {};

			for (let i = 0; i < folders.docs.length; i++) {
				const folder = folders.docs[i];
				const id = generateUniqueFirestoreId();
				mapIds[folder.id] = id;

				const data = {
					...folder.data(),
					clientPermission: type === 'clients',
					createdAt: new Date().toISOString(),
					createdBy: {
						userId: uid,
						userType: type === 'clients' ? 'client' : 'collaborator',
						agencyId: type === 'clients' ? uid : agencyId,
					},
					deletedAt: null,
					id,
					isFavorite: false,
					modifiedAt: new Date().toISOString(),
					path: folder
						.data()
						.path.split('/')
						.map((it) => mapIds[it])
						.join('/'),
					permissions: type === 'collaborators' ? [agencyId] : [],
					previousPath: null,
					projectId: project.id,
					seenBy: [{ userId: uid, seenAt: new Date().toISOString() }],
					size: null,
					type: null,
					url: null,
				};
				newFolders.push(data);

				const newFolderRef = firestore.doc(`${PROJECT_COLLECTION}/${project.id}/${FILE_COLLECTION}/${id}`);
				batch.set(newFolderRef, data);

				if ((i + 1) % BATCH_SIZE === 0) {
					await batch.commit();
					batch = firestore.batch();
				}
			}

			if (newFolders.length % BATCH_SIZE !== 0) {
				await batch.commit();
			}

			console.log('newFolders', newFolders);
			setFiles(newFolders);
			setPath([]);
			setSelectedFileIds([]);
		} catch (error) {
			console.error(error);
			alert(t('templates.error'));
		}
	}

	return (
		<div className={'folderPath'}>
			<div className={'inner'} ref={ref}>
				<div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}>
					<p
						style={{
							fontSize: 27,
							marginTop: 14,
						}}
						className={path.length === 0 ? 'nav-active' : 'nav'}
						onClick={() => {
							setPath([]);
							setSelectedFileIds([]);
						}}>
						{t('fiche_project.files')}
					</p>

					<img src={rightIcon} className={'icon'} style={{ marginTop: -5 }} alt={''} />
				</div>

				<div className={'containerListPath'}>
					{path.map((item, index) => (
						<div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}>
							<p
								style={{
									marginTop: 22,
								}}
								className={index === path.length - 1 ? 'nav-active' : 'nav'}
								onClick={() => {
									setPath(path.slice(0, index + 1));
									setSelectedFileIds([item]);
								}}>
								{files.find((it) => it.id === item)?.name}
							</p>

							<img src={rightIcon} className={'icon'} style={{ marginTop: -5 }} alt={''} />
						</div>
					))}
				</div>

				<div onClick={() => setModalReplaceTemplate(true)} className={'buttonProgression hover'}>
					<img className={'iconReplace'} src={replacePictures} alt="iconPlus" style={{ width: 30 }} />
					<p className={'titleButton'} style={{ whiteSpace: 'nowrap' }}>
						{t('progression_page.replace_by_a_template')}
					</p>
				</div>
			</div>

			<ModalReplaceTemplate
				modalReplaceTemplate={modalReplaceTemplate}
				setModalReplaceTemplate={setModalReplaceTemplate}
				handleReplaceTemplate={replaceByTemplate}
			/>
		</div>
	);
}
