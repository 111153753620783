/**
 * Merges multiple arrays, orders them according to a lambda function, and removes duplicates based on a specified key.
 *
 * @param {Function} orderBy - A lambda function to determine the order of the elements.
 * @param {string} uniqueKey - The key to check for duplicates.
 * @param {...Array} arrays - A variable number of arrays to be merged.
 * @returns {Array} - A new array that is merged, ordered, and without duplicates.
 */
export function mergeAndOrder(orderBy, uniqueKey, ...arrays) {
	const mergedArray = arrays.flat();
	const uniqueArray = mergedArray.reduce((acc, item) => {
		if (item) {
			if (!acc.some((el) => el && el[uniqueKey] === item[uniqueKey])) {
				acc.push(item);
			}
		}
		return acc;
	}, []);
	return uniqueArray.sort(orderBy);
}

/**
 * Check if the difference between two dates is one hour or more.
 * @param date1
 * @param date2
 * @returns {boolean}
 */
export function isDifferenceOneHourOrMore(date1, date2) {
	if (!date1 || !date2) {
		return true;
	}

	const dateObj1 = new Date(date1);
	const dateObj2 = new Date(date2);
	const differenceInMilliseconds = Math.abs(dateObj2 - dateObj1);
	const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
	return differenceInHours >= 1;
}

/**
 * Check if a date is today.
 * @param {string} dateISOString
 * @returns {boolean}
 */
export function isToday(dateISOString) {
	if (!dateISOString) {
		return false;
	}

	const date = new Date(dateISOString);
	const today = new Date();
	return (
		date.getDate() === today.getDate() &&
		date.getMonth() === today.getMonth() &&
		date.getFullYear() === today.getFullYear()
	);
}

/**
 * Returns the month of a date using the translation fonction.
 * @param {Function} t - The translation function.
 * @param {string} dateISOString - The date to get the month from.
 * @returns {string} - The month in the right language.
 */
export function getMonth(t, dateISOString) {
	if (dateISOString) {
		const date = new Date(dateISOString);
		const month = date.getMonth();
		const months = [
			t('long_months.january'),
			t('long_months.february'),
			t('long_months.march'),
			t('long_months.april'),
			t('long_months.may'),
			t('long_months.june'),
			t('long_months.july'),
			t('long_months.august'),
			t('long_months.september'),
			t('long_months.october'),
			t('long_months.november'),
			t('long_months.december'),
		];
		return months[month];
	}

	return '';
}

/**
 * Returns the year of a date.
 * @param {string} dateISOString - The date to get the year from.
 * @returns {string} - The year.
 */
export function getYear(dateISOString) {
	if (dateISOString) {
		const date = new Date(dateISOString);
		return date.getFullYear();
	}

	return '';
}

/**
 * Get the decimal separator depending on the language
 * @param locale The locale language
 * @returns {string} The decimal separator
 */
export function getDecimalSeparator(locale) {
	const numberWithDecimal = (1.1).toLocaleString(locale);

	const decimalSeparator = numberWithDecimal.match(/1(.)1/);

	return decimalSeparator ? decimalSeparator[1] : '.';
}

/**
 * Get the thousand separator depending on the language
 * @param locale The locale language
 * @returns {string} The thousand separator
 */
export function getThousandSeparator(locale) {
	const numberWithDecimal = (1000).toLocaleString(locale);

	const decimalSeparator = numberWithDecimal.match(/1(.)000/);

	return decimalSeparator ? decimalSeparator[1] : '';
}
