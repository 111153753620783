import { adjustText, transformString } from '../../../../../useful/UsefulFunctions';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AgencyContext from '../../../../../contexts/agencyContext';
import { getPathStorageFromUrl } from '../../../../../firebase/utils';
import { firestore, functions, storage } from '../../../../../firebase/config';
import { httpsCallable } from 'firebase/functions';
import { createdByConstructor } from '../../../utils';

export default function ItemListPartnerCompany({
	uid,
	projectId,
	actors,
	research,
	isActive,
	setResearchNumber,
	projectName,
	projectTag,
}) {
	const { t } = useTranslation();

	const agency = useContext(AgencyContext);

	const [isLoading, setIsLoading] = useState(false);
	const [name, setName] = useState();
	const [diminutif, setDiminutif] = useState();
	const [partnerData, setPartnerData] = useState();
	const [oldResearch, setOldResearch] = useState(false);

	useEffect(() => {
		firestore
			.doc(`agencies/${uid}`)
			.get()
			.then((documentSnapshot) => {
				if (documentSnapshot && documentSnapshot.exists) {
					setName(documentSnapshot.data().name);
					setDiminutif(documentSnapshot.data().diminutifAgency);
					setPartnerData({ ...documentSnapshot.data(), id: documentSnapshot.id });
				}
			});
	}, [uid, agency, projectId]);

	async function ajouter(e) {
		e.preventDefault();

		setIsLoading(true);

		const promises = [];

		await firestore
			.collection(`agencies/${uid}/collaborators`)
			.where('subtype', '==', 'administrator')
			.get()
			.then((querySnapshot) => {
				if (querySnapshot) {
					querySnapshot.forEach(async (documentSnapshot) => {
						if (documentSnapshot && documentSnapshot.exists) {
							const call = httpsCallable(functions, 'addUserToProject');
							promises.push(
								call({
									createdBy: createdByConstructor(documentSnapshot.id, 'collaborator', uid),
									projectId: projectId,
									parent: partnerData,
									data: { ...documentSnapshot.data(), id: documentSnapshot.id },
									senderName: documentSnapshot.data().surname + ' ' + documentSnapshot.data().name,
									projectName,
									projectImgUrl: '',
								})
							);
						}
					});
				}
			});

		await Promise.all(promises);

		setIsLoading(false);
	}

	async function deleteCollection(
		collectionWithCondition,
		collectionWithoutCondition,
		deleteStorage1 = false,
		attributUrl1,
		deleteStorage2 = false,
		attributUrl2
	) {
		collectionWithCondition.get().then((querySnapshot) => {
			if (querySnapshot) {
				querySnapshot.forEach(async (documentSnapshot) => {
					if (documentSnapshot && documentSnapshot.exists) {
						if (deleteStorage1) {
							if (documentSnapshot.data()[attributUrl1]) {
								await storage
									.ref(getPathStorageFromUrl(documentSnapshot.data()[attributUrl1]))
									.delete();
							}
						}
						if (deleteStorage2) {
							if (documentSnapshot.data()[attributUrl2]) {
								await storage
									.ref(getPathStorageFromUrl(documentSnapshot.data()[attributUrl2]))
									.delete();
							}
						}
						await collectionWithoutCondition.doc(documentSnapshot.id).delete();
					}
				});
			}
		});
	}

	async function deleteMessaging(pathWithCondition, pathWithoutCondition) {
		pathWithCondition.get().then((querySnapshot) => {
			if (querySnapshot) {
				querySnapshot.forEach(async (documentSnapshot) => {
					await deleteCollection(
						pathWithoutCondition.doc(documentSnapshot.id).collection('pictures'),
						pathWithoutCondition.doc(documentSnapshot.id).collection('pictures'),
						true,
						'contents'
					);
					await deleteCollection(
						pathWithoutCondition.doc(documentSnapshot.id).collection('files'),
						pathWithoutCondition.doc(documentSnapshot.id).collection('files'),
						true,
						'contents'
					);
					await deleteCollection(
						pathWithoutCondition.doc(documentSnapshot.id).collection('messages'),
						pathWithoutCondition.doc(documentSnapshot.id).collection('messages')
					);
					await deleteCollection(pathWithCondition, pathWithoutCondition);
				});
			}
		});
	}

	async function deleteCollaborator(uid, agency) {
		const path = firestore.doc(`agencies/${agency}/collaborators/${uid}/projects/${projectId}`);
		await firestore.doc(`projects/${projectId}/accounts/${uid}`).delete();
		await deleteMessaging(path.collection('messaging').where('group', '==', false), path.collection('messaging'));
		path.collection('messaging')
			.where('group', '==', true)
			.get()
			.then((querySnapshot) => {
				if (querySnapshot) {
					querySnapshot.forEach((documentSnapshot) => {
						if (documentSnapshot && documentSnapshot.exists) {
							firestore
								.doc(`projects/${projectId}/messaging/${documentSnapshot.id}/participants/${uid}`)
								.delete()
								.then(() => {
									path.collection('messaging')
										.doc(documentSnapshot.id)
										.delete()
										.then(() => {
											firestore
												.collection(
													`projects/${projectId}/messaging/${documentSnapshot.id}/participants`
												)
												.get()
												.then(async (querySnapshot) => {
													if (querySnapshot && querySnapshot.empty) {
														await deleteMessaging(
															firestore
																.collection(`projects/${projectId}/messaging`)
																.where('id', '==', documentSnapshot.id),
															firestore.collection(`projects/${projectId}/messaging`)
														);
													} else if (!querySnapshot) {
														await deleteMessaging(
															firestore
																.collection(`projects/${projectId}/messaging`)
																.where('id', '==', documentSnapshot.id),
															firestore.collection(`projects/${projectId}/messaging`)
														);
													}
												});
										});
								});
						}
					});
				}
			});
		await path.delete();
		const pathAccount = firestore.doc(`agencies/${agency}/collaborators/${uid}`);
		pathAccount
			.collection('calendar')
			.where('projectId', '==', projectId)
			.get()
			.then((querySnapshot) => {
				if (querySnapshot) {
					querySnapshot.forEach((documentSnapshot) => {
						firestore
							.doc(`projects/${projectId}/calendar/${documentSnapshot.id}/participants/${uid}`)
							.delete()
							.then(() => {
								firestore
									.collection(`projects/${projectId}/calendar/${documentSnapshot.id}/participants`)
									.get()
									.then(async (querySnapshot) => {
										if (querySnapshot && querySnapshot.empty) {
											await deleteCollection(
												firestore
													.collection(`projects/${projectId}/calendar`)
													.where('id', '==', documentSnapshot.id),
												firestore.collection(`projects/${projectId}/calendar`),
												true,
												'fileUrl',
												true,
												'fileUrlFinish'
											);
										} else if (!querySnapshot) {
											await deleteCollection(
												firestore
													.collection(`projects/${projectId}/calendar`)
													.where('id', '==', documentSnapshot.id),
												firestore.collection(`projects/${projectId}/calendar`),
												true,
												'fileUrl',
												true,
												'fileUrlFinish'
											);
										}
									});
							});
					});
				}
			});
		pathAccount
			.collection('tasks')
			.where('projectId', '==', projectId)
			.get()
			.then((querySnapshot) => {
				if (querySnapshot) {
					querySnapshot.forEach((documentSnapshot) => {
						if (documentSnapshot && documentSnapshot.exists) {
							firestore
								.doc(`projects/${projectId}/tasks/${documentSnapshot.id}/participants/${uid}`)
								.delete()
								.then(() => {
									firestore
										.collection(`projects/${projectId}/tasks/${documentSnapshot.id}/participants`)
										.get()
										.then(async (querySnapshot) => {
											if (querySnapshot && querySnapshot.empty) {
												await deleteCollection(
													firestore.collection(
														`projects/${projectId}/tasks/${documentSnapshot.id}/comments`
													),
													firestore.collection(
														`projects/${projectId}/tasks/${documentSnapshot.id}/comments`
													)
												);
												await deleteCollection(
													firestore.collection(
														`projects/${projectId}/tasks/${documentSnapshot.id}/participants`
													),
													firestore.collection(
														`projects/${projectId}/tasks/${documentSnapshot.id}/participants`
													)
												);
												await deleteCollection(
													firestore.collection(
														`projects/${projectId}/tasks/${documentSnapshot.id}/todoList`
													),
													firestore.collection(
														`projects/${projectId}/tasks/${documentSnapshot.id}/todoList`
													)
												);
												await deleteCollection(
													firestore.collection(
														`projects/${projectId}/tasks/${documentSnapshot.id}/files`
													),
													firestore.collection(
														`projects/${projectId}/tasks/${documentSnapshot.id}/files`
													),
													true,
													'url'
												);
												await firestore
													.doc(`projects/${projectId}/tasks/${documentSnapshot.id}`)
													.delete();
											} else if (!querySnapshot) {
												await deleteCollection(
													firestore.collection(
														`projects/${projectId}/tasks/${documentSnapshot.id}/comments`
													),
													firestore.collection(
														`projects/${projectId}/tasks/${documentSnapshot.id}/comments`
													)
												);
												await deleteCollection(
													firestore.collection(
														`projects/${projectId}/tasks/${documentSnapshot.id}/participants`
													),
													firestore.collection(
														`projects/${projectId}/tasks/${documentSnapshot.id}/participants`
													)
												);
												await deleteCollection(
													firestore.collection(
														`projects/${projectId}/tasks/${documentSnapshot.id}/todoList`
													),
													firestore.collection(
														`projects/${projectId}/tasks/${documentSnapshot.id}/todoList`
													)
												);
												await deleteCollection(
													firestore.collection(
														`projects/${projectId}/tasks/${documentSnapshot.id}/files`
													),
													firestore.collection(
														`projects/${projectId}/tasks/${documentSnapshot.id}/files`
													),
													true,
													'url'
												);
												await firestore
													.doc(`projects/${projectId}/tasks/${documentSnapshot.id}`)
													.delete();
											}
										});
								});
						}
					});
				}
			});
	}

	async function deleteAccount(e) {
		e.preventDefault();

		if (window.confirm(t('contributors.sure_to_delete_this_partner'))) {
			setIsLoading(true);
			firestore
				.collection(`projects/${projectId}/accounts`)
				.where('agency', '==', uid)
				.get()
				.then((querySnapshot) => {
					if (querySnapshot) {
						querySnapshot.forEach(async (documentSnapshot) => {
							await deleteCollaborator(documentSnapshot.id, 'collaborators', uid);
						});
					}
				});
			await firestore.doc(`projects/${projectId}/accounts/${uid}`).delete();
			setIsLoading(false);
		}
	}

	useEffect(() => {
		if (name) {
			if (transformString(name).includes(transformString(research)) && !oldResearch) {
				setResearchNumber((prevValue) => prevValue + 1);
				setOldResearch(true);
			} else if (!transformString(name).includes(transformString(research)) && oldResearch) {
				setResearchNumber((prevValue) => prevValue - 1);
				setOldResearch(false);
			}
		}
	}, [research, name]);

	return (
		<>
			{transformString(name).includes(transformString(research)) && (
				<div className={'itemListUser'}>
					<div className={'leftPartItemUser'}>
						<div className={'profilePictureBleu'} style={{ fontSize: 12, borderRadius: 7 }}>
							{
								/*accountData.surname.charAt(0).toUpperCase()}{accountData.name.charAt(0).toUpperCase()*/ diminutif
							}
						</div>

						<div className={'nameEmail'}>
							<p className={'name'}>
								{name && adjustText(name, 26)}{' '}
								{name && <span className={'diminutif'}> • {diminutif}</span>}
							</p>
						</div>
					</div>

					{actors && actors.map((item) => item.uid).includes(uid) ? (
						<div
							className={'rightPartItemUser'}
							onClick={deleteAccount}
							style={{ justifyContent: 'flex-end' }}>
							<div className={'retirer hover'}>{t('translation.extirp')}</div>
						</div>
					) : (
						<div className={'rightPartItemUser'} onClick={ajouter} style={{ justifyContent: 'flex-end' }}>
							<div className={'ajouter hover'}>{t('translation.add')}</div>
						</div>
					)}
				</div>
			)}
		</>
	);
}
