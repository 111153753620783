import React, { useState } from 'react';
import ProjetListItem from './ProjetListItem';
import searchIcon from '../../../assets/searchIcon.png';
import gerySearchIcon from '../../../assets/gerySearchIcon.png';
import { useTranslation } from 'react-i18next';
import crossIcon from '../../../assets/crossIcon.png';
import Modal from 'react-modal';

export default function ModalAddProjet({ modal, setModal, authProjects, projectsToAdd, setProjectsToAdd }) {
	const { t } = useTranslation();

	const [research, setResearch] = useState();
	const [researchNumber, setResearchNumber] = useState(0);

	return (
		<Modal isOpen={modal} className={'modalAddProjetCollaborateur'} overlayClassName="OverlayModal">
			<img
				src={crossIcon}
				alt="crossIcon"
				className={'crossIcon'}
				onClick={() => {
					setModal(false);
				}}
			/>
			<div>
				<p className={'titleModal'}>
					{t('common.add')} <span style={{ color: '#4B5FB0' }}>{t('common.this_user')}</span>{' '}
					{t('agency.to_your_projects')}
				</p>
				<p className={'subtitle'}>{t('agency.choose_project_for_collab')}</p>

				<div style={{ display: 'flex', marginLeft: -5, marginTop: 30, marginBottom: 0 }}>
					<input
						style={{ width: 510 }}
						type={'text'}
						placeholder={t('agency.search_for_a_project')}
						className={'rechercheParticipants'}
						value={research}
						onChange={(e) => setResearch(e.target.value)}
					/>
					<img src={searchIcon} alt="" className={'searchIcon'} style={{ marginLeft: -538 }} />
				</div>

				<div className={'listProjetAjoute'}>
					<div className={'innerListProjetAjoute'}>
						{authProjects &&
							authProjects.map((item, index) => (
								<ProjetListItem
									key={index}
									id={item}
									projectsToAdd={projectsToAdd}
									setProjectsToAdd={setProjectsToAdd}
									research={research}
									setResearchNumber={setResearchNumber}
								/>
							))}
						{research && researchNumber === 0 && (
							<div
								style={{
									width: 590,
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									justifyContent: 'center',
									marginTop: 40,
								}}>
								<img
									src={gerySearchIcon}
									alt=""
									style={{
										width: 30,
									}}
								/>
								<p
									style={{
										fontSize: 13,
										color: '#8c8c8c',
										fontWeight: 500,
										letterSpacing: 0.6,
										marginBottom: 8,
									}}>
									{t('common.no_result_found')}
								</p>
								<p
									style={{
										margin: 0,
										fontSize: 12,
										color: '#8c8c8c',
										fontWeight: 400,
										letterSpacing: 0.6,
									}}>
									{t('research_project.no_result_2')}
								</p>
							</div>
						)}
						<div style={{ height: 20 }} />
					</div>
				</div>
			</div>
		</Modal>
	);
}
