import React, { useContext, useState } from 'react';
import '../_choosePlanPage.scss';
import { auth, firestore, functions } from '../../../../../firebase/config';
import { httpsCallable } from 'firebase/functions';
import AgencyContext from '../../../../../contexts/agencyContext';
import { useStripeContext } from '../../../../../contexts/stripeContext';
import blackCheckPlan from '../../../../../assets/blackCheckPlan.svg';
import whiteCheckPlan from '../../../../../assets/whiteCheckPlan.svg';
import { useTranslation } from 'react-i18next';
import { AGENCY_COLLECTION, COLLABORATOR_COLLECTION, COMPANIES_COLLECTION } from '../../../../../firebase/paths';
import { useIntercom } from 'react-use-intercom';
import AgencyDataContext from '../../../../../contexts/agencyDataContext';
import opusErvices from '../../../../../assets/opusErvices.png';
import { generateUniqueFirestoreId } from '../../../../../firebase/utils';

export default function ItemPlanChoice({ plan, index, currency, planType, isCustom }) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const agency = useContext(AgencyContext);
	const stripe = useStripeContext();
	const agencyData = useContext(AgencyDataContext);
	const { show } = useIntercom();

	const [isLoading, setIsLoading] = useState(false);

	const handleChoosePlan = async () => {
		if ((stripe.isFreeTrial || stripe.priceId) && !isLoading) {
			if (stripe.isFreeTrial) {
				setIsLoading(true);
				if (!stripe || !stripe.stripeId) {
					const createCustomer = httpsCallable(functions, 'createCustomer');
					await createCustomer({
						companyId: agency,
					}).catch((error) => {
						alert(error.message);
						setIsLoading(false);
					});
				}

				let numberCollaborators;
				if (isCustom) {
					const agencyData = await firestore.collection(AGENCY_COLLECTION).doc(agency).get();
					numberCollaborators =
						agencyData.data().limitCollaborators - (agencyData.data().freeCollaborators ?? 0);
				} else {
					numberCollaborators = (
						await firestore
							.collection(AGENCY_COLLECTION)
							.doc(agency)
							.collection(COLLABORATOR_COLLECTION)
							.get()
					).docs.filter((doc) => !doc.data().isDeleted).length;
				}

				const random = generateUniqueFirestoreId();

				const docRef = await firestore
					.collection(COMPANIES_COLLECTION)
					.doc(agency)
					.collection('checkout_sessions')
					.add({
						mode: planType === 'fiveYearly' ? 'payment' : 'subscription',
						automatic_tax: true,
						price:
							planType === 'monthly'
								? plan.priceIdMonthly
								: planType === 'fiveYearly'
									? plan.priceIdFiveYearly
									: plan.priceIdYearly,
						success_url:
							window.location.origin +
							`/projets?billing=true&random=${random}&priceId=${
								planType === 'monthly'
									? plan.priceIdMonthly
									: planType === 'fiveYearly'
										? plan.priceIdFiveYearly
										: plan.priceIdYearly
							}`,
						cancel_url: window.location.origin + '/choose-plan',
						quantity: numberCollaborators,
						currency: currency === 'eur' ? 'eur' : currency === 'aed' ? 'aed' : 'usd',
						allow_promotion_codes: true,
						random,
					})
					.catch((error) => {
						alert(error.message);
						setIsLoading(false);
					});

				docRef.onSnapshot(async (snap) => {
					const { error, url } = snap.data();
					if (error) {
						setIsLoading(false);
						if (
							error?.message?.includes('No such customer') ||
							error?.message?.includes('no user record')
						) {
							await firestore.collection(COMPANIES_COLLECTION).doc(agency).update({
								stripeId: null,
							});
						} else {
							alert(`An error occured: ${error.message}`);
						}
					}
					if (url) {
						setIsLoading(false);
						window.location.assign(url);
					}
				});
			} else if (
				stripe.priceId !== plan.priceIdMonthly &&
				stripe.priceId !== plan.priceIdYearly &&
				stripe.priceId !== plan.priceIdFiveYearly
			) {
				window.alert(t('billing.contact_our_help_service'));
			}
		}
	};

	const handleManagePlan = async () => {
		setIsLoading(true);
		const functionRef = httpsCallable(functions, 'createBillingPortalLink');
		await functionRef({
			companyId: agency,
			returnUrl: window.location.origin + '/choose-plan',
			locale: 'auto',
		})
			.then((res) => {
				setIsLoading(false);
				console.log(res);
				window.location.assign(res.data.url);
			})
			.catch((error) => {
				alert(error.message);
				setIsLoading(false);
			});
	};

	return (
		<div style={{ opacity: plan.tag === 'free' ? 0.4 : 1 }} className={'itemPlanChoiceBlue'}>
			<div>
				<p className={'titlePlan'}>{plan.title}</p>
				<p className={'subtitlePlan'}>{plan.subtitle}</p>

				{isCustom ? (
					<div className={'numberSeats'}>
						<div className={'paidSeats'}>
							<p className={'number'}>{agencyData?.limitCollaborators - agencyData?.freeCollaborators}</p>{' '}
							Paid seats
						</div>
						{agencyData?.freeCollaborators > 0 && (
							<div className={'freeSeats'}>
								<p className={'number'}>{agencyData?.freeCollaborators}</p> Free seats
							</div>
						)}
					</div>
				) : (
					index === 0 &&
					plan.checks.map((option, index) => (
						<CheckOption key={index} title={option} index={index} whiteCheck={true} />
					))
				)}

				<div className={'bar'} />

				<p className={'price'}>
					<span style={{ textDecoration: plan.discount && 'line-through' }}>
						{currency === 'eur'
							? planType === 'monthly'
								? plan.price
								: planType === 'annually'
									? plan.priceYearly
									: plan.priceFiveYearly
							: currency === 'aed'
								? planType === 'monthly'
									? plan.priceAED
									: planType === 'annually'
										? plan.priceAEDYearly
										: plan.priceAEDFiveYearly
								: planType === 'monthly'
									? plan.priceUSD
									: planType === 'annually'
										? plan.priceUSDYearly
										: plan.priceUSDFiveYearly}
					</span>{' '}
				</p>
				<p className={'subtitlePrice'}>
					{isCustom
						? planType === 'monthly'
							? t('translation.simpleSubTitlePrince')
							: planType === 'annually'
								? t('translation.simpleSubTitlePrinceYear')
								: t('translation.simpleSubTitlePrinceFiveYear')
						: planType === 'monthly'
							? t('translation.subTitlePrince')
							: planType === 'annually'
								? t('translation.subTitlePrinceYear')
								: t('translation.subTitlePrinceFiveYear')}
				</p>

				<div className={'buttonPlan'} onClick={handleChoosePlan}>
					{stripe.priceId === plan.priceIdMonthly ||
					stripe.priceId === plan.priceIdYearly ||
					stripe.priceId === plan.priceIdFiveYearly
						? t('translation.actualPlan')
						: isLoading
							? t('common.loading')
							: t('translation.choosePlan')}
				</div>

				{(stripe.priceId === plan.priceIdMonthly ||
					stripe.priceId === plan.priceIdYearly ||
					stripe.priceId === plan.priceIdFiveYearly) && (
					<div className={'currentPlan hover'} style={{ marginTop: -13 }} onClick={handleManagePlan}>
						{t('translation.managePlan')}
					</div>
				)}
				{(stripe.priceId === plan.priceIdMonthly ||
					stripe.priceId === plan.priceIdYearly ||
					stripe.priceId === plan.priceIdFiveYearly) && (
					<div
						className={'currentPlan hover'}
						style={{ marginTop: 10, marginBottom: 18 }}
						onClick={handleManagePlan}>
						{t('translation.seeBills')}
					</div>
				)}
			</div>
			<img src={opusErvices} alt="" className={'illustration'} />
		</div>
	);
}

function CheckOption({ title, index, whiteCheck }) {
	return (
		<div className={'checkOption'}>
			<img src={whiteCheck ? whiteCheckPlan : blackCheckPlan} alt="" className={'simpleCheck'} />
			{title}
		</div>
	);
}
