import React, { useContext, useEffect, useState } from 'react';
import crossIcon from '../../../assets/crossIcon.png';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import searchIcon from '../../../assets/searchIcon.png';
import ProjetListItem from '../../../components/ProjectListItem';
import gerySearchIcon from '../../../assets/gerySearchIcon.png';
import { auth, firestore } from '../../../firebase/config';
import TypeContext from '../../../contexts/typeContext';
import AgencyContext from '../../../contexts/agencyContext';

export default function ModalReplaceTemplate({ modalReplaceTemplate, setModalReplaceTemplate, handleReplaceTemplate }) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const type = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);

	const [research, setResearch] = useState();
	const [researchNumber, setResearchNumber] = useState(0);
	const [projectIds, setProjectIds] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (modalReplaceTemplate) {
			let ref;
			switch (type) {
				case 'collaborators':
					ref = firestore.collection(`agencies/${agencyId}/projects`);
					break;
				case 'clients':
					ref = firestore.collection(`clients/${uid}/projects`);
					break;
				default:
					console.log('error type:', type);
					return;
			}
			ref.get().then((querySnapshot) => {
				setProjectIds(querySnapshot.docs.map((doc) => doc.id));
			});
		}
	}, [modalReplaceTemplate, type]);

	const handleClick = async (project) => {
		if (window.confirm(t('translation.confirmReplaceByTemplate'))) {
			setLoading(true);
			await handleReplaceTemplate(project);
			setLoading(false);
			setModalReplaceTemplate(false);
		}
	};

	return (
		<Modal isOpen={modalReplaceTemplate} className={'modalAddProjetCollaborateur'} overlayClassName="OverlayModal">
			<img
				src={crossIcon}
				alt="crossIcon"
				className={'crossIcon'}
				onClick={() => {
					setModalReplaceTemplate(false);
				}}
			/>
			<div>
				<p className={'titleModal'}>{t('translation.chooseProjectToCopy')}</p>
				<p className={'subtitle'}>{t('translation.explanationTemplate')}</p>

				<div style={{ display: 'flex', marginLeft: -5, marginTop: 30, marginBottom: 0 }}>
					<input
						style={{ width: 525 }}
						type={'text'}
						placeholder={t('agency.search_for_a_project')}
						className={'rechercheParticipants'}
						value={research}
						onChange={(e) => setResearch(e.target.value)}
					/>
					<img src={searchIcon} alt="" className={'searchIcon'} style={{ marginLeft: -551 }} />
				</div>

				<div className={'listProjetAjoute'}>
					<div className={'innerListProjetAjoute'} style={{ opacity: loading ? 0.5 : 1 }}>
						{projectIds &&
							projectIds.map((item, index) => (
								<ProjetListItem
									id={item}
									key={index}
									research={research}
									setResearchNumber={setResearchNumber}
									handleClick={handleClick}
									loading={loading}
								/>
							))}
						{research && researchNumber === 0 && (
							<div
								style={{
									width: 590,
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									justifyContent: 'center',
									marginTop: 40,
								}}>
								<img
									src={gerySearchIcon}
									alt=""
									style={{
										width: 30,
									}}
								/>
								<p
									style={{
										fontSize: 13,
										color: '#8c8c8c',
										fontWeight: 500,
										letterSpacing: 0.6,
										marginBottom: 8,
									}}>
									{t('common.no_result_found')}
								</p>
								<p
									style={{
										margin: 0,
										fontSize: 12,
										color: '#8c8c8c',
										fontWeight: 400,
										letterSpacing: 0.6,
									}}>
									{t('research_project.no_result_2')}
								</p>
							</div>
						)}
						<div style={{ height: 20 }} />
					</div>
				</div>
			</div>
		</Modal>
	);
}
