import React from 'react';

export default function ItemAccessibilityPhotos({ t, type, name, isActivated, handleSimpleChoice }) {
	return (
		<div
			className={'itemAccessibility transition'}
			style={{ backgroundColor: isActivated && '#EAEEFB' }}
			onClick={handleSimpleChoice}>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<div style={{ width: 280 }}>
					<p className={'titleAccessibility'} style={{ color: isActivated && '#2D56D5' }}>
						{type === 'partner' ? name : t('common.mo')}
					</p>
				</div>

				<div className={'roundedButton'} style={{ borderColor: isActivated && '#2D56D5', marginTop: 0 }}>
					<div className={'littleCircle'} style={{ backgroundColor: isActivated && '#2D56D5' }} />
				</div>
			</div>
		</div>
	);
}
