import React from 'react';

export default function ItemAccessibility({ isActivated, name, onClick, disabled }) {
	return (
		<div style={{ opacity: disabled ? 0.5 : 1 }}>
			<div
				className={'itemAccessibility transition'}
				onClick={async () => {
					if (!disabled) {
						await onClick(!isActivated);
					}
				}}
				style={{ backgroundColor: isActivated && '#EAEEFB' }}>
				<div style={{ display: 'flex' }}>
					<div style={{ width: 280 }}>
						<p className={'titleAccessibility'} style={{ color: isActivated && '#2D56D5' }}>
							{name}
						</p>
					</div>

					<div className={'roundedButton'} style={{ borderColor: isActivated && '#2D56D5' }}>
						<div className={'littleCircle'} style={{ backgroundColor: isActivated && '#2D56D5' }} />
					</div>
				</div>
			</div>
		</div>
	);
}
