import React from 'react';
import Modal from 'react-modal';
import crossIcon from '../../../../assets/crossIcon.png';
import { useTranslation } from 'react-i18next';
import './ModalModifGlobalProgression.scss';

export default function ModalModifGlobalProgression({
	modalModifGlobalProgression,
	setModalModifGlobalProgression,
	globalProgression,
	setGlobalProgression,
	changeGlobalProgression,
}) {
	const { t } = useTranslation();

	return (
		<Modal
			isOpen={modalModifGlobalProgression}
			className={'modalModifGlobalProgression'}
			overlayClassName="noOverlay"
			closeTimeoutMS={300}>
			<img
				src={crossIcon}
				alt="crossIcon"
				className={'crossIcon'}
				onClick={() => {
					setModalModifGlobalProgression(false);
				}}
			/>

			<div className={'widgetPourcentage'}>
				<p className="title">{t('progression_page.edit_progression')}</p>

				<p className={'message'}>{t('progression_page.info_edit_progression')}</p>

				<input
					type="text"
					className={'inputTyping'}
					placeholder={'100'}
					value={globalProgression}
					onChange={(e) => setGlobalProgression(e.target.value)}
				/>
			</div>

			<div className={'jeConfirmeButton hover'} onClick={changeGlobalProgression}>
				<p className={'textJeConfirme'}>{t('common.save')}</p>
			</div>
		</Modal>
	);
}
