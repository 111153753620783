import React, { useEffect, useState } from 'react';
import chevronRightVisit from '../../../../assets/chevronRightVisit.svg';
import { dateString } from '../../../../useful/UsefulFunctions';
import { useTranslation } from 'react-i18next';
import { firestore } from '../../../../firebase/config';
import { MEDIA_COLLECTION, PROJECT_COLLECTION } from '../../../../firebase/paths';
import whiteEye from '../../../../assets/whiteEye.svg';
import eye from '../../../../assets/eye.svg';

export default function VisitCard({ isLastVisit, visit, handleSelectAlbum, visits, index, displayEye }) {
	const { t } = useTranslation();

	const [pictures, setPictures] = useState([]);
	const [creator, setCreator] = useState();

	useEffect(() => {
		if (visit.id && visit.projectId) {
			const ref = firestore
				.collection(`${PROJECT_COLLECTION}/${visit.projectId}/${MEDIA_COLLECTION}`)
				.where('albumId', '==', visit.id)
				.where('type', '==', 'image');

			ref.orderBy('orderDate', 'desc')
				.limit(8)
				.get()
				.then((querySnapshot) => {
					const pictures = [];
					querySnapshot.forEach((doc) => {
						if (doc && doc.exists) {
							pictures.push({ ...doc.data(), id: doc.id });
						}
					});
					setPictures(pictures);
				});
		}
		if (visit.createdBy && visit.createdBy.userId) {
			const ref =
				visit.createdBy.userType === 'collaborator'
					? firestore
							.collection(`agencies/${visit.createdBy.agencyId}/collaborators`)
							.doc(visit?.createdBy.userId)
					: firestore.collection('clients').doc(visit?.createdBy.userId);
			ref.get().then((doc) => {
				if (doc.exists) {
					setCreator({ ...doc.data(), id: doc.id });
				}
			});
		}
	}, [visit.createdBy]);

	return (
		<div
			style={{ display: 'flex' }}
			onClick={() =>
				handleSelectAlbum({ ...visit, name: `${t('common.visit')} ${visits.length - index}`, creator })
			}>
			<div className={isLastVisit ? 'firstVisitCard' : 'visitCard'}>
				<div className={'mosaiquePhotos'}>
					{pictures.map((picture, index) => (
						<img
							key={index}
							src={picture.thumbnailUrl ?? picture.url}
							alt=""
							className={'visitCardImage'}
						/>
					))}
				</div>
				<div className={'headerVisitCard'}>
					<p className={'visitName'}>{dateString(t, visit?.date ? visit?.date : visit?.orderDate, true)}</p>
					<img src={chevronRightVisit} alt="" className={'chevronRightVisit'} />
				</div>
				<p className={'commentsTitle'}>{t('translation.generalAnnotations')}</p>
				<p className={'comments'}>{visit?.description ? visit?.description : t('translation.noAnnotations')}</p>
				<div className={'footerVisitCard'}>
					{creator && (
						<div className={'creatorComponent'}>
							<div className={'innitiales'}>
								{creator?.surname?.charAt(0)?.toUpperCase() + creator?.name?.charAt(0)?.toUpperCase()}
							</div>
							<div>
								<p className={'createdBy'}>{t('translation.createdBy')}</p>
								<p className={'creator'}>{creator.surname + ' ' + creator.name}</p>
							</div>
						</div>
					)}

					{displayEye && (
						<img
							style={{
								opacity: 1,
								objectFit: 'contain',
								marginTop: 6,
								marginRight: 10,
								width: 21,
							}}
							src={isLastVisit ? whiteEye : eye}
							alt=""
							className={'eye'}
						/>
					)}
				</div>
			</div>
		</div>
	);
}
