import React, { useContext } from 'react';
import loadingUpload from '../../assets/loadingUpload.svg';
import checkVert from '../../assets/checkVert.svg';
import searchIcon from '../../assets/searchIcon.svg';
import greyFodler from '../../assets/greyFodler.svg';
import crossIcon from '../../assets/crossIcon.png';
import { useNavigate } from 'react-router-dom';

import { Pages } from '../../pages/Fiche Projet/utils';
import { auth, firestore } from '../../firebase/config';
import AgencyContext from '../../contexts/agencyContext';
import TypeContext from '../../contexts/typeContext';

function ItemWidgetUpload({ data }) {
	const navigate = useNavigate();
	const uid = auth.currentUser?.uid;
	const agency = useContext(AgencyContext);
	const type = useContext(TypeContext);

	const progress = data.bytesTransferred / data.totalBytes;

	const handleClick = async () => {
		if (progress >= 1) {
			localStorage.setItem('projectId', data.projectId);
			if (data.albumId) {
				localStorage.setItem('page', Pages.PHOTOS);
				navigate(`fiche-projet`, {
					state: {
						page: Pages.PHOTOS,
						projectId: data.projectId,
						selectedAlbumId: data.albumId,
					},
				});
			} else {
				localStorage.setItem('page', Pages.FICHIERS);
				navigate(`fiche-projet`, {
					state: {
						page: Pages.FICHIERS,
						projectId: data.projectId,
						path: data.path.split('/').filter((e) => e !== ''),
						selectedFileIds: [data.id],
					},
				});
			}
			if (type === 'collaborators') {
				await firestore
					.doc(`agencies/${agency}/${type}/${uid}/projects/${id}`)
					.update({ lastClick: new Date().toISOString(), seen: true });
				await firestore
					.doc(`agencies/${agency}/collaborators/${uid}`)
					.update({ lastConnexion: new Date().toISOString() });
			} else {
				await firestore
					.doc(`${type}/${uid}/projects/${id}`)
					.update({ lastClick: new Date().toISOString(), seen: true });
			}
		}
	};

	return (
		<div className={'itemWidgetUpload'} onClick={handleClick}>
			<div style={{ display: 'flex' }}>
				{data.error ? (
					<div style={{ width: 35, margin: 0, height: 20, marginLeft: -35 }}>
						<img src={crossIcon} alt="" className={'checkVert'} />
					</div>
				) : progress < 1 ? (
					<div
						className={'chargementContainer'}
						style={{ width: 35, margin: 0, height: 20, marginLeft: -35 }}>
						<img
							src={loadingUpload}
							alt=""
							className={'chargement'}
							style={{ opacity: 1, width: 14, margin: 0 }}
						/>
					</div>
				) : (
					<div style={{ width: 35, margin: 0, height: 20, marginLeft: -35 }}>
						<img src={checkVert} alt="" className={'checkVert'} />
					</div>
				)}

				<div className={'leftPart'}>
					<p className={'title'}>{data?.name}</p>
					{data.error ? (
						<div style={{ display: 'flex' }}>
							<img src={greyFodler} alt="" className={'greyFolder'} />
							<p className={'location'}>Erreur lors de l'importation</p>
						</div>
					) : progress < 1 ? (
						<div className={'backBar'}>
							<div className={'frontBar'} style={{ width: `${progress * 100}%` }} />
						</div>
					) : (
						<div style={{ display: 'flex' }}>
							<img src={greyFodler} alt="" className={'greyFolder'} />
							<p className={'location'}>Importé dans {data?.containerName}</p>
						</div>
					)}
				</div>
			</div>
			<div className={'buttonItem'} onClick={handleClick}>
				{progress >= 1 && <img src={searchIcon} alt="" className={'searchIconItem'} />}
			</div>
		</div>
	);
}

export default React.memo(ItemWidgetUpload);
