import React, { useContext, useEffect, useState } from 'react';
import { auth, firestore, storage } from '../../../firebase/config';
import 'firebase/firestore';
import 'firebase/auth';
import agencyContext from '../../../contexts/agencyContext';
import ModalTyping from '../../../components/ModalTyping';
import FinanceAppIcon from '../../../assets/FinanceAppIcon.png';
import switchIcon from '../../../assets/switchIcon.svg';
import { addSpaceToNumber, changeSortString } from '../../../useful/UsefulFunctions';
import Category from './components/Category';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import AuthDataContext from '../../../contexts/authDataContext';
import ModalModifPosteDepense from './components/ModalModifPosteDepense';
import plusSquare from '../../../assets/plusSquare.svg';
import doubleArrows from '../../../assets/doubleArrows.svg';
import { generateUniqueFirestoreId, getPathStorageFromUrl } from '../../../firebase/utils';
import { useTranslation } from 'react-i18next';
import './_finances.scss';
import replacePictures from '../../../assets/replacePictures.svg';
import ModalReplaceTemplate from '../components/ModalReplaceTemplate';
import mixpanel from 'mixpanel-browser';

export default function Finances({ type, projectId, isMandataire, isModeLecture }) {
	const { t } = useTranslation();
	const [modalReplaceTemplate, setModalReplaceTemplate] = useState(false);

	const uid = auth.currentUser?.uid;
	const agency = useContext(agencyContext);
	const authData = useContext(AuthDataContext);
	const [isAdministrator, setIsAdministrator] = useState(
		localStorage.getItem('administrator') === 'true' || authData?.subtype === 'administrator'
	);

	const [selectCurrency, setSelectCurrency] = useState(false);

	const [reorganise, setReorganise] = useState(false);
	const [expenses, setExpenses] = useState([]);
	const [globalPaid, setGlobalPaid] = useState(0);
	const [globalEstimate, setGlobalEstimate] = useState(0);
	const [ttc, setTtc] = useState(false);
	const [currency, setCurrency] = useState('€');

	const [modalNewCategory, setModalNewCategory] = useState(false);

	const [modalModifPosteDepense, setModalModifPosteDepense] = useState(false);
	const [selectedExpenseData, setSelectedExpenseData] = useState({});

	useEffect(() => {
		const subscriber1 = firestore.doc(`projects/${projectId}`).onSnapshot((documentSnapshot) => {
			if (documentSnapshot && documentSnapshot.exists) {
				setTtc(documentSnapshot.data().ttc);
				setCurrency(documentSnapshot.data().currency);
			}
		});
		firestore
			.collection(`projects/${projectId}/expenses`)
			.get()
			.then((querySnapshot) => {
				if (querySnapshot) {
					const expenses = [];
					querySnapshot.forEach((documentSnapshot) => {
						if (documentSnapshot && documentSnapshot.exists) {
							expenses.push({ ...documentSnapshot.data(), id: documentSnapshot.id });
						}
					});
					setExpenses(order(expenses));
				}
			});
		return () => {
			subscriber1();
		};
	}, [projectId, isAdministrator, isMandataire]);

	async function newCategory(name) {
		const id = generateUniqueFirestoreId();
		const data = {
			category: '\\**\\' + (name ? name : t('common.without_title')),
			dummy: true,
			date: new Date().toISOString(),
			id,
			uid,
			agency,
		};
		setExpenses((oldValue) => [[data], ...oldValue]);
		await firestore.doc(`projects/${projectId}/expenses/${id}`).set(data);

		mixpanel.track('Category Created', {
			'Project ID': projectId,
			Page: 'finances',
		});
	}

	function order(list) {
		if (list && list.length > 0) {
			const sortedList = list.sort((a, b) => -a.date?.localeCompare(b.date));
			const orderedList = [[]];
			let estimate = 0;
			let paid = 0;
			for (const item of sortedList) {
				let findCateg = false;
				if (!item.isConfidential || isAdministrator || type === 'clients') {
					estimate += parseInt(item.estimate ?? 0);
					paid += parseInt(item.paid ?? 0);
				}
				for (const orderedItem of orderedList) {
					if (!orderedItem[0] || item.category === orderedItem[0].category) {
						orderedItem.push(item);
						findCateg = true;
						break;
					}
				}
				if (!findCateg) {
					orderedList.push([item]);
				}
			}

			setGlobalEstimate(estimate);
			setGlobalPaid(paid);

			for (const [index, item] of orderedList.entries()) {
				if (item.length === 0) {
					orderedList.splice(index, 1);
				}
			}

			return orderedList.sort((a, b) =>
				a[0].category ? (b[0].category ? a[0].category.localeCompare(b[0].category) : 1) : -1
			);
		}
		return [];
	}

	const SortableItem = SortableElement(({ value, sortIndex }) => (
		<Category
			type={type}
			reorganise={reorganise}
			projectId={projectId}
			categName={value[0] && value[0].category}
			categIndex={sortIndex}
			expenses={value}
			setExpenses={setExpenses}
			ttc={ttc}
			mandataire={isMandataire}
			setGlobalPaid={setGlobalPaid}
			setGlobalEstimate={setGlobalEstimate}
			setModalModifPosteDepense={setModalModifPosteDepense}
			setSelectedExpenseData={setSelectedExpenseData}
			isAdministrator={isAdministrator}
		/>
	));

	const SortableList = SortableContainer(({ items }) => (
		<div>
			{items.map((value, index) => (
				<SortableItem key={`item-${index}`} index={index} value={value} sortIndex={index} />
			))}
		</div>
	));

	const getPercentage = () => {
		if (!globalPaid) {
			return 0; // Si globalPaid est 0 ou falsey, retourne 0
		}

		if (!globalEstimate || globalPaid > globalEstimate) {
			return 1; // Si globalEstimate est 0 ou falsey, ou si globalPaid est plus grand que globalEstimate, retourne 100% (1)
		}

		return globalPaid / globalEstimate; // Autrement, retourne la proportion de globalPaid par rapport à globalEstimate
	};

	async function deleteAllExpenses() {
		const BATCH_SIZE = 500;

		let batch = firestore.batch();
		let batchCount = 0;
		let deletePromises = [];

		for (const item of expenses) {
			for (const expense of item) {
				batch.delete(firestore.doc(`projects/${projectId}/expenses/${expense.id}`));
				batchCount++;

				const files = await firestore.collection(`projects/${projectId}/expenses/${expense.id}/files`).get();
				for (const file of files.docs) {
					batch.delete(file.ref);
					batchCount++;

					if (file.data().url) {
						const fileRef = storage.ref(getPathStorageFromUrl(file.data().url));
						deletePromises.push(fileRef.delete());
					}

					if (batchCount === BATCH_SIZE) {
						await batch.commit();
						batch = firestore.batch();
						batchCount = 0;
						await Promise.all(deletePromises);
						deletePromises = [];
					}
				}

				if (batchCount === BATCH_SIZE) {
					await batch.commit();
					batch = firestore.batch();
					batchCount = 0;
					await Promise.all(deletePromises);
					deletePromises = [];
				}
			}
		}

		if (batchCount > 0) {
			await batch.commit();
			await Promise.all(deletePromises);
		}

		setExpenses([]);
	}

	async function replaceByTemplate(project) {
		try {
			await deleteAllExpenses();

			const BATCH_SIZE = 500;
			const expenses = await firestore.collection(`projects/${project.id}/expenses`).get();
			const newExpenses = [];
			let batch = firestore.batch();
			let batchCount = 0;

			for (const expense of expenses.docs) {
				const id = generateUniqueFirestoreId();
				const newData = {
					...expense.data(),
					id,
					uid,
					agency,
					date: new Date().toISOString(),
					paid: 0,
					estimate: 0,
				};
				newExpenses.push(newData);
				batch.set(firestore.doc(`projects/${projectId}/expenses/${id}`), newData);
				batchCount++;

				if (batchCount === BATCH_SIZE) {
					await batch.commit();
					batch = firestore.batch();
					batchCount = 0;
				}
			}

			if (batchCount > 0) {
				await batch.commit();
			}

			setExpenses(order(newExpenses));
		} catch (error) {
			console.error(e);
			alert(t('templates.error'));
		}
	}

	return (
		<div className="financePage">
			<div className={'innerFinance'}>
				<div style={{ display: 'flex', marginBottom: 10 }}>
					<div style={{ display: 'flex' }}>
						<p className={'titlePage'}>{t('fiche_project.finances')}</p>
					</div>

					<div className={'containerButtonsFinances'}>
						{type !== 'clients' && !isModeLecture && (
							<div onClick={() => setModalReplaceTemplate(true)} className={'buttonFinances hover'}>
								<img className={'icon'} src={replacePictures} alt="iconPlus" />
								<p className={'titleButton'} style={{ whiteSpace: 'nowrap', marginRight: 13 }}>
									{t('progression_page.replace_by_a_template')}
								</p>
							</div>
						)}

						{type !== 'clients' && expenses.length > 0 && !isModeLecture && (
							<div
								onClick={async () => {
									if (reorganise === true) {
										setReorganise(false);
										for (let i = 0; i < expenses.length; i++) {
											const newName =
												`\\*${10000 + i}*\\` + changeSortString(expenses[i][0].category);
											setExpenses((oldValue) => {
												const newValue = [...oldValue];
												newValue[i][0].category = newName;
												return newValue;
											});
											for (const item of expenses[i]) {
												await firestore
													.doc(`projects/${projectId}/expenses/${item.id}`)
													.update({ category: newName });
											}
										}
									} else {
										setReorganise(true);
									}
								}}
								style={{ backgroundColor: reorganise ? '#d6f8d2' : '#eeeeee' }}
								className={'buttonFinances hover'}>
								{!reorganise && <img className={'icon'} src={doubleArrows} alt="iconPlus" />}
								<p className={'titleButton'} style={{ color: reorganise ? '#328536' : '#000' }}>
									{reorganise ? t('finances.valid_the_order') : t('finances.rearrange_categories')}
								</p>
							</div>
						)}

						{type !== 'clients' && !isModeLecture && (
							<div
								onClick={async () => {
									const newTtc = !ttc;
									setTtc((oldValue) => !oldValue);
									await firestore.doc(`projects/${projectId}`).update({
										ttc: newTtc,
									});
								}}
								className={'buttonFinances hover'}>
								<img className={'icon'} src={switchIcon} alt="iconPlus" />
								<p className={'titleButton'}>
									{ttc ? t('finances.switch_ht') : t('finances.switch_ttc')}
								</p>
							</div>
						)}

						<div className={'buttonFinances hover'}>
							<div
								onClick={async () => {
									setSelectCurrency(!selectCurrency);
								}}
								style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
								<p style={{ fontWeight: '700', marginRight: 3, fontSize: 15 }}>
									{currency && currency.length > 0 ? currency : '€'}
								</p>
								<p className={'titleButton'}>{t('translation.currency')}</p>
							</div>
							<div
								className={'containerCurrencies'}
								style={{
									width: selectCurrency ? 140 : 0,
									borderLeftColor: selectCurrency ? null : '#eeeeee',
									marginLeft: selectCurrency ? 5 : 0,
								}}>
								<div
									className={currency === '€' ? 'currency currencyActive' : 'currency'}
									style={{ marginLeft: 5 }}
									onClick={async () => {
										setCurrency('€');
										await firestore.doc(`projects/${projectId}`).update({
											currency: '€',
										});
										setSelectCurrency(false);
									}}>
									€
								</div>
								<div
									className={currency === '£' ? 'currency currencyActive' : 'currency'}
									onClick={async () => {
										setCurrency('£');
										await firestore.doc(`projects/${projectId}`).update({
											currency: '£',
										});
										setSelectCurrency(false);
									}}>
									£
								</div>
								<div
									className={currency === '$' ? 'currency currencyActive' : 'currency'}
									onClick={async () => {
										setCurrency('$');
										await firestore.doc(`projects/${projectId}`).update({
											currency: '$',
										});
										setSelectCurrency(false);
									}}>
									$
								</div>
								<div
									className={currency === 'AED' ? 'currency currencyActive' : 'currency'}
									onClick={async () => {
										setCurrency('AED');
										await firestore.doc(`projects/${projectId}`).update({
											currency: 'AED',
										});
										setSelectCurrency(false);
									}}>
									AED
								</div>
							</div>
						</div>

						{type !== 'clients' && !isModeLecture && (
							<div onClick={() => setModalNewCategory(true)} className={'buttonFinances hover'}>
								<img className={'icon'} src={plusSquare} alt="iconPlus" />
								<p className={'titleButton'}>{t('finances.add_expense_category')}</p>
							</div>
						)}
					</div>
				</div>

				<div className={'horizontalBarFinances'} />

				<div className="globalFinanceProgression">
					<div style={{ display: 'flex' }}>
						<div className={'containerGlobalPercentage'}>
							<p className={'globalPercentage'}>
								{globalPaid
									? !globalEstimate && globalPaid > 0
										? 999
										: Math.round((100 * globalPaid) / globalEstimate) > 999
											? 999
											: Math.round((100 * globalPaid) / globalEstimate)
									: 0}
								%
							</p>
						</div>
					</div>

					<div className={'containerProgressBarGlobalPercentage'}>
						<div className={'containerPayed'}>
							<p className={'payed'}>
								{t('finances.paid')} {isNaN(globalPaid) ? 0 : addSpaceToNumber(globalPaid)}{' '}
								{currency && currency.length > 0 ? currency : '€'}{' '}
								{ttc ? t('modal_edit_expense_item.ttc') : t('modal_edit_expense_item.ht')}
							</p>
						</div>
						<div className={'backBarFinances'}>
							<div className={'frontBarFinances'} style={{ width: `calc(100% * ${getPercentage()})` }} />
						</div>
						<div className={'containerTotal'}>
							<p className={'total'}>
								Total : {isNaN(globalEstimate) ? 0 : addSpaceToNumber(globalEstimate)}{' '}
								{currency && currency.length > 0 ? currency : '€'}{' '}
								{ttc ? t('modal_edit_expense_item.ttc') : t('modal_edit_expense_item.ht')}
							</p>
						</div>
					</div>
				</div>

				<div className="categories">
					<div className="list">
						{expenses && expenses.length < 1 ? (
							<div className={'placeholderCategoriesFinance'}>
								<img src={FinanceAppIcon} alt="" className={'imgAucunePhase'} />
								<p className={'title'}>{t('finances.placeholder_1')}</p>
								<p className={'subtitle'}>{t('finances.placeholder_2')}</p>

								<button onClick={() => setModalNewCategory(true)} className={'buttonAddCategory'}>
									{t('finances.create_category')}
								</button>
							</div>
						) : reorganise ? (
							<SortableList
								pressDelay={200}
								axis={'y'}
								items={expenses}
								onSortEnd={async ({ oldIndex, newIndex }) => {
									if (oldIndex !== newIndex) {
										const items = [...expenses];
										const [reorderedItem] = items.splice(oldIndex, 1);
										items.splice(newIndex, 0, reorderedItem);
										setExpenses(items);
									}
								}}
							/>
						) : (
							expenses.map((value, index) => (
								<Category
									key={index}
									type={type}
									reorganise={reorganise}
									projectId={projectId}
									categName={value[0] && value[0].category}
									categIndex={index}
									expenses={value}
									setExpenses={setExpenses}
									ttc={ttc}
									mandataire={isMandataire}
									setGlobalPaid={setGlobalPaid}
									setGlobalEstimate={setGlobalEstimate}
									setModalModifPosteDepense={setModalModifPosteDepense}
									setSelectedExpenseData={setSelectedExpenseData}
									isAdministrator={isAdministrator}
									isModeLecture={isModeLecture}
									currency={currency}
								/>
							))
						)}
					</div>
				</div>
			</div>

			<ModalTyping
				modalTyping={modalNewCategory}
				setModalTyping={setModalNewCategory}
				title={t('finances.category_name')}
				func={newCategory}
			/>

			<ModalModifPosteDepense
				type={type}
				modalModifPosteDepense={modalModifPosteDepense}
				setModalModifPosteDepense={setModalModifPosteDepense}
				projectId={projectId}
				expenseData={selectedExpenseData}
				ttc={ttc}
				mandataire={isMandataire}
				setExpenses={setExpenses}
				categIndex={selectedExpenseData.categIndex}
				expenseIndex={selectedExpenseData.expenseIndex}
				setGlobalPaid={setGlobalPaid}
				setGlobalEstimate={setGlobalEstimate}
				currency={currency}
			/>

			<ModalReplaceTemplate
				modalReplaceTemplate={modalReplaceTemplate}
				setModalReplaceTemplate={setModalReplaceTemplate}
				handleReplaceTemplate={replaceByTemplate}
			/>
		</div>
	);
}
