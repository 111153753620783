import React, { useContext, useEffect, useState } from 'react';
import '../_contributorAccountsContainer.scss';
import Modal from 'react-modal';
import crossIcon from '../../../../../assets/crossIcon.png';
import circlePlus from '../../../../../assets/circlePlus.svg';
import searchIcon from '../../../../../assets/searchIcon.png';
import ItemListCollabs from '../List Item/ItemListCollabs';
import gerySearchIcon from '../../../../../assets/gerySearchIcon.png';
import { useTranslation } from 'react-i18next';
import AgencyDataContext from '../../../../../contexts/agencyDataContext';
import { firestore } from '../../../../../firebase/config';

export default function ModalCollabsAccounts({
	modalCollabsAccounts,
	setModalCollabsAccounts,
	setModalNewCollab,
	accounts,
	client,
	projectId,
	uid,
}) {
	const [searchFill, setSearchFill] = useState('');
	const { t } = useTranslation();
	const agencyData = useContext(AgencyDataContext);

	const [research, setResearch] = useState('');
	const [researchNumber, setResearchNumber] = useState(0);
	const [roleIds, setRoleIds] = useState(['']);

	useEffect(() => {
		if (uid) {
			firestore
				.doc(`projects/${projectId}/accounts/${uid}`)
				.get()
				.then((documentSnapshot) => {
					if (documentSnapshot && documentSnapshot.exists) {
						setRoleIds(documentSnapshot.data().roleIds ?? ['']);
					}
				});
		}
	}, [uid]);

	const saveRole = async (role) => {
		setRoleIds([role]);
		await firestore.doc(`projects/${projectId}/accounts/${uid}`).update({
			role,
		});
	};

	return (
		<Modal
			isOpen={modalCollabsAccounts}
			className={'modalProjectAccounts'}
			overlayClassName="overlayModalProjectAccounts">
			<img
				src={crossIcon}
				alt=""
				className={'crossIcon'}
				onClick={() => {
					setModalCollabsAccounts(false);
					setResearch('');
					setResearchNumber(0);
				}}
			/>

			<p className={'titleModal'}>{agencyData?.name}</p>

			<div className={'containerSearchButton'}>
				<div className={'searchContainer'}>
					<img src={searchIcon} alt="" className={'searchIconAccounts'} />
					<input
						onChange={(e) => setResearch(e.target.value)}
						value={research}
						type="email"
						className={'searchInput'}
						placeholder={t('translation.researchNameMail')}
					/>
					<img
						src={crossIcon}
						alt=""
						className={'crossIconSearch hover'}
						style={{ opacity: searchFill.length > 1 ? 1 : 0 }}
						onClick={() => setSearchFill('')}
					/>
				</div>

				<div className={'buttonNew hover'} onClick={() => setModalNewCollab(true)}>
					<img src={circlePlus} alt="" className={'circlePlus'} />
					{t('translation.addACollab')}
				</div>
			</div>

			<div className={'barModalAccounts'} />

			<div className={'scrollableList'} style={{ height: 522 }}>
				{accounts &&
					accounts.map((item, index) => (
						<div key={index}>
							<ItemListCollabs
								uid={item.uid}
								agency={uid}
								research={research}
								setResearchNumber={setResearchNumber}
								projectId={projectId}
								blockDelete={accounts.length <= 1}
							/>
						</div>
					))}

				{researchNumber === 0 && research.length > 1 && (
					<div className={'noResult'}>
						<img
							src={gerySearchIcon}
							alt=""
							style={{
								width: 30,
							}}
						/>
						<p className={'noResultFound'}>{t('common.no_result_found')}</p>
						<p className={'noResultFound'}>{t('research_project.no_result_2')}</p>
					</div>
				)}

				<div style={{ display: 'flex' }}>
					<div className={'comble'} />
				</div>
			</div>
		</Modal>
	);
}
