import React, { useContext, useEffect, useState } from 'react';
import MenuNav from './MenuNav';
import 'firebase/auth';
import grey from '../../assets/grey.jpg';
import settingsIcon from '../../assets/settingsIcon.png';
import { NavLink } from 'react-router-dom';
import { auth, firestore } from '../../firebase/config';
import AgencyContext from '../../contexts/agencyContext';
import { useTranslation } from 'react-i18next';
import TypeContext from '../../contexts/typeContext';
import messagerieMenuIcon from '../../assets/messagerieMenuIcon.svg';
import LanguageSelector from '../LanguageSelector/LanguageSelector';

export default function MenuProjet({ img, name, ficheProjet, clientName, isModeLecture, setDisplayNotifications }) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const type = useContext(TypeContext);
	const agency = useContext(AgencyContext);

	const [count, setCount] = useState(false);
	const [opacity1, setOpacity1] = useState(0);

	const [pastilleNumber, setPastilleNumber] = useState(0);

	useEffect(() => {
		firestore
			.collection(`users/${uid}/messaging`)
			.where('isUnread', '==', true)
			.limit(9)
			.onSnapshot((querySnapshot) => {
				if (querySnapshot) {
					setPastilleNumber(querySnapshot.size);
				}
			});
	}, [uid, type, agency]);

	useEffect(() => {
		setTimeout(() => {
			setCount(true);
		}, 400);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			if (count) {
				setOpacity1(1);
			}
		}, 0);
	}, [count]);

	const [isVisible, setIsVisible] = useState(false);
	const [heightMenu, setHeightMenu] = useState(50);

	function Test() {
		if (isVisible) {
			setIsVisible(false);
			setHeightMenu(50);
		} else {
			setIsVisible(true);
			setHeightMenu();
		}
	}

	return (
		<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
			<div className="menu" style={{ borderBottomColor: isModeLecture ? '#0057FE' : null }}>
				<div className={'menuWidget'}>
					<div style={{ width: 300 }}>
						<div>
							<div
								className={'transitionMenuProjet'}
								style={{
									display: 'flex',
									flexDirection: 'column',
									position: 'absolute',
									marginTop: 5,
									width: 450,
									flex: 1,
									height: heightMenu,
									overflow: 'hidden',
									borderRadius: 5,
									backgroundColor: 'rgba(169,80,80,0)',
								}}>
								<div
									style={{ display: 'flex', marginTop: -6, marginLeft: -15 }}
									className={'hover'}
									onClick={() => Test()}>
									<div className="backgroundImage" style={{ opacity: opacity1 }}>
										<img src={img ? img : grey} alt="" className={'img'} />
									</div>

									<div>
										{name ? (
											<div
												style={{
													width: 500,
													display: 'flex',
													position: 'absolute',
													alignItems: 'center',
													marginBottom: 0,
													marginTop: 0,
													marginLeft: 17,
												}}>
												{ficheProjet ? (
													<p className={'titleProject'} style={{ maxWidth: 300 }}>
														{name}
														{type === 'clients' ? null : clientName && ' • '}
														{type === 'clients'
															? null
															: clientName && (
																	<span style={{ color: '#4B5FB0', marginLeft: 0 }}>
																		{clientName}
																	</span>
																)}{' '}
													</p>
												) : (
													<p className={'titleProject'}>{name} </p>
												)}
											</div>
										) : (
											<div
												style={{
													width: 100,
													height: 26,
													marginTop: 15,
													marginBottom: 13,
													marginLeft: 40,
													backgroundColor: '#F8F8F8',
												}}
											/>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>

					<MenuNav isFicheProjet={true} grasProjet={true} />

					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							width: 300,
							zIndex: 0,
						}}>
						<NavLink exact to="/global-messagerie">
							<div>
								<div
									className={'hover'}
									style={{
										marginTop: 19,
										display: 'flex',
										marginRight: 20,
										alignItems: 'center',
										justifyContent: 'center',
									}}>
									<img
										src={messagerieMenuIcon}
										alt=""
										style={{
											width: 18,
											objectFit: 'contain',
											zIndex: 1,
										}}
									/>
									{pastilleNumber > 0 && (
										<div style={{ display: 'flex', zIndex: 2 }}>
											<div className={'notifsMessages'}>{pastilleNumber}</div>
										</div>
									)}
								</div>
							</div>
						</NavLink>

						<NavLink exact to="/parametres-profil">
							<div>
								<div className={'hover'} style={{ marginTop: 18 }}>
									<img
										src={settingsIcon}
										alt=""
										style={{
											width: 20,
											marginRight: 10,
											objectFit: 'contain',
										}}
									/>
								</div>
							</div>
						</NavLink>

						<div style={{ marginTop: 8, marginRight: 15 }}>
							<LanguageSelector openBottom={true} />
						</div>
					</div>
				</div>
				<div className={'barMenu'} />
			</div>
			{isModeLecture && (
				<div className={'readOnly hover'} onClick={() => setDisplayNotifications(true)}>
					{t('translation.readOnlyJoinProject')}
				</div>
			)}
		</div>
	);
}
