import { functions } from '../../../firebase/config';
import { useTranslation } from 'react-i18next';
import { httpsCallable } from 'firebase/functions';

export const NotificationSource = {
	FILES: 'files',
	PHOTOS: 'photos',
	PROGRESSION: 'progress',
	INTERVENANTS: 'contributors',
	JOIN_PROJECT: 'joinProject',
};

export const NotificationObject = {
	NEW_DOC_ADDED: 'newDocAdded',
	NEW_FOLDER_ADDED: 'newFolderAdded',
	NEW_ALBUM_CREATED: 'newAlbumCreated',
	NEW_VISIT_CREATED: 'newVisitCreated',
	PHOTOS_ADDED_TO_ALBUM: 'photosAddedToAlbum',
	PHOTOS_ADDED_TO_VISIT: 'photosAddedToVisit',
	VALID_PHASE: 'validPhase',
	VALID_STEP: 'validStep',
	PROGRESSION_PERCENTAGE_MODIFIED: 'progressionPercentageModified',
	NEW_COLLAB_IN_PROJECT: 'newCollabInProject',
	NEW_AGENCY_IN_PROJECT: 'newAgencyInProject',
	ACCEPT_TO_JOIN_PROJECT: 'acceptToJoinProject',
};

export function sendNotificationToCollaborators(
	createdBy,
	agencyId,
	projectId,
	source,
	parent,
	data,
	senderName,
	projectName,
	projectImgUrl
) {
	const send = httpsCallable(functions, 'sendNotificationToCollaborators');
	send({
		createdBy,
		agencyId,
		projectId,
		source,
		parent,
		data,
		senderName,
		projectName,
		projectImgUrl,
	}).then((r) => {});
}

export function sendNotificationToClients(
	createdBy,
	projectId,
	source,
	parent,
	data,
	senderName,
	projectName,
	projectImgUrl
) {
	const send = httpsCallable(functions, 'sendNotificationToClients');
	send({
		createdBy,
		projectId,
		source,
		parent,
		data,
		senderName,
		projectName,
		projectImgUrl,
	}).then((r) => {});
}

export function sendNotificationToAll(
	createdBy,
	projectId,
	source,
	parent,
	data,
	senderName,
	projectName,
	projectImgUrl
) {
	const send = httpsCallable(functions, 'sendNotificationToAll');
	send({
		createdBy,
		projectId,
		source,
		parent,
		data,
		senderName,
		projectName,
		projectImgUrl,
	}).then((r) => {});
}

export function getNotificationObject(notification) {
	const { source, data, parent } = notification;
	switch (source) {
		case NotificationSource.FILES:
			if (data[0]?.isFolder) {
				return NotificationObject.NEW_FOLDER_ADDED;
			} else {
				return NotificationObject.NEW_DOC_ADDED;
			}
		case NotificationSource.PHOTOS:
			if (data[0]?.type === 'album') {
				return NotificationObject.NEW_ALBUM_CREATED;
			} else if (data[0]?.type === 'visit') {
				return NotificationObject.NEW_VISIT_CREATED;
			} else if (parent?.type === 'visit') {
				return NotificationObject.PHOTOS_ADDED_TO_VISIT;
			} else {
				return NotificationObject.PHOTOS_ADDED_TO_ALBUM;
			}
		case NotificationSource.PROGRESSION:
			if (parent?.progression >= 0) {
				return NotificationObject.PROGRESSION_PERCENTAGE_MODIFIED;
			} else if (parent?.steps === parent?.stepsCompleted) {
				return NotificationObject.VALID_PHASE;
			} else {
				return NotificationObject.VALID_STEP;
			}
		case NotificationSource.INTERVENANTS:
			if (data[0]?.type === 'agencies') {
				return NotificationObject.NEW_AGENCY_IN_PROJECT;
			} else {
				return NotificationObject.NEW_COLLAB_IN_PROJECT;
			}
		case NotificationSource.JOIN_PROJECT:
			return NotificationObject.ACCEPT_TO_JOIN_PROJECT;
		default:
			return null;
	}
}

export function timeSince(t, dateIsoString) {
	const now = new Date();
	const pastDate = new Date(dateIsoString);
	const seconds = Math.floor((now - pastDate) / 1000);
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);
	const days = Math.floor(hours / 24);
	const weeks = Math.floor(days / 7);
	const months = Math.floor(days / 30);
	const years = Math.floor(days / 365);

	if (seconds < 60) {
		return t('time.just_now');
	} else if (minutes === 1) {
		return t('time.one_minute_ago');
	} else if (minutes < 60) {
		return t('time.minutes_ago', { count: minutes });
	} else if (hours === 1) {
		return t('time.one_hour_ago');
	} else if (hours < 24) {
		return t('time.hours_ago', { count: hours });
	} else if (days === 1) {
		return t('time.one_day_ago');
	} else if (days < 7) {
		return t('time.days_ago', { count: days });
	} else if (weeks === 1) {
		return t('time.one_week_ago');
	} else if (weeks < 5) {
		return t('time.weeks_ago', { count: weeks });
	} else if (months === 1) {
		return t('time.one_month_ago');
	} else if (months < 12) {
		return t('time.months_ago', { count: months });
	} else if (years === 1) {
		return t('time.one_year_ago');
	} else {
		return t('time.years_ago', { count: years });
	}
}

export function updateBadge(userId) {
	const send = httpsCallable(functions, 'updateBadge');
	send({ userId }).then((r) => {});
}
