import React, { useState } from 'react';
import crossIcon from '../assets/crossIcon.png';
import LockIcon from '../assets/LockIcon.png';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { auth } from '../firebase/config';
import {
	EmailAuthProvider,
	PhoneAuthProvider,
	PhoneMultiFactorGenerator,
	RecaptchaVerifier,
	reauthenticateWithCredential,
} from 'firebase/auth';

export default function ModalPassword({ modalPassword, setModalPassword, toExecute, removeFirst }) {
	const { t } = useTranslation();

	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);

	async function onSubmit(e) {
		e.preventDefault();
		setLoading(true);

		const user = auth.currentUser;
		const cred = EmailAuthProvider.credential(user.email, password);

		reauthenticateWithCredential(user, cred)
			.then(async (userCred) => {
				if (userCred) {
					if (removeFirst) {
						setModalPassword(false);
					}
					await toExecute(cred);
					setPassword('');
					setModalPassword(false);
					setLoading(false);
				}
			})
			.catch((error) => {
				if (error.code === 'auth/multi-factor-auth-required') {
					const recaptchaVerifier = new RecaptchaVerifier('valid-button', {
						size: 'invisible',
					});
					const resolver = error.resolver;
					// Ask user which second factor to use.
					if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
						const phoneInfoOptions = {
							multiFactorHint: resolver.hints[0],
							session: resolver.session,
						};
						const phoneAuthProvider = new PhoneAuthProvider();
						// Send SMS verification code
						return phoneAuthProvider
							.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
							.then(function (verificationId) {
								// Ask user for the SMS verification code.
								const verificationCode = window.prompt(
									'Please enter the verification ' + 'code that was sent to your mobile device.'
								);
								const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
								const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
								// Complete sign-in.
								return resolver.resolveSignIn(multiFactorAssertion);
							})
							.then(async function (cred) {
								// User successfully signed in with the second factor phone number.
								if (removeFirst) {
									setModalPassword(false);
								}
								await toExecute(cred);
								setPassword('');
								setModalPassword(false);
								setLoading(false);
							});
					} else {
						// Unsupported second factor.
					}
				} else {
					if (
						error.toString() ===
						'Error: [auth/network-request-failed] A network error has occurred, please try again.'
					) {
						window.alert(t('settings_safety.error_network'));
					} else if (
						error.toString() === 'Error: [auth/invalid-email] The email address is badly formatted.'
					) {
						window.alert(t('common.wrong_format'));
					} else if (
						error.toString() ===
						'Error: [auth/email-already-in-use] The email address is already in use by another account'
					) {
						window.alert(t('common.email_already_used'));
					} else {
						window.alert(error.toString());
					}
					setLoading(false);
					setPassword('');
				}
			});
	}

	return (
		<Modal
			isOpen={modalPassword}
			className={'modalMotDePasseCreation'}
			overlayClassName="OverlayModal"
			closeTimeoutMS={300}>
			{!loading && (
				<img
					onClick={() => {
						setModalPassword(false);
						setPassword('');
					}}
					className={'crossIcon'}
					src={crossIcon}
					alt="crossIcon"
				/>
			)}
			<h2>{t('login_page.placeholders_input.password')}</h2>

			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					textAlign: 'center',
				}}>
				<p
					style={{
						marginTop: 0,
						marginLeft: 30,
						marginRight: 30,
						fontSize: 15,
						fontWeight: 500,
						color: '#a2a2a2',
					}}>
					{t('common.to_secure_please_auth')}
				</p>
			</div>
			<img
				style={{
					marginTop: 5,
					width: 13,
					objectFit: 'contain',
					opacity: 0.4,
					marginBottom: 5,
				}}
				src={LockIcon}
				alt="LockIcon"
			/>
			<form onSubmit={onSubmit}>
				<input
					type="password"
					className={'inputModifNomProjet'}
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
				{!loading ? (
					<button
						id={'valid-button'}
						className={'enregistrerButton'}
						style={{ color: '#4B5FB0', backgroundColor: '#D2E7F8', fontWeight: 600, letterSpacing: 0 }}
						disabled={(loading && !password) || password.length < 6}>
						{t('common.confirm')}
					</button>
				) : (
					<button
						id={'valid-button'}
						className={'enregistrerButton'}
						style={{ color: '#4B5FB0', backgroundColor: '#D2E7F8', fontWeight: 600, letterSpacing: 0 }}>
						{t('common.loading')}
					</button>
				)}
			</form>
		</Modal>
	);
}
