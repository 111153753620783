import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import crossIcon from '../../../../../../assets/crossIcon.png';
import folderIcon from '../../../../../../assets/folderIcon.png';
import strongArrowIcon from '../../../../../../assets/strongArrowIcon.svg';
import whiteStrongArrowIcon from '../../../../../../assets/whiteStrongArrowIcon.svg';
import '../components/_modauxInfosDocuments.scss';
import { chooseIconFile } from '../../../utils';
import loadingUpload from '../../../../../../assets/loadingUpload.svg';
import checkVert from '../../../../../../assets/checkVert.svg';
import searchIcon from '../../../../../../assets/searchIcon.svg';
import multipleSelection from '../../../../../../assets/multipleSelection.svg';
import { auth, firestore } from '../../../../../../firebase/config';
import { FILE_COLLECTION, PROJECT_COLLECTION } from '../../../../../../firebase/paths';
import TypeContext from '../../../../../../contexts/typeContext';
import AgencyContext from '../../../../../../contexts/agencyContext';
import { useTranslation } from 'react-i18next';

export default function ModalMoveItem({
	modalMoveItem,
	setModalMoveItem,
	projectId,
	filesToMove,
	setPath,
	setSelectedFileIds,
}) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const type = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);

	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);

	const [dataLoading, setDataLoading] = useState(true);
	const [folders, setFolders] = useState([]);
	const [selectedFolderId, setSelectedFolderId] = useState(null);

	const [files, setFiles] = useState([]);

	useEffect(() => {
		if (filesToMove?.length > 0) {
			setFiles(filesToMove);
		}
	}, [filesToMove]);

	useEffect(() => {
		if (projectId && modalMoveItem && uid && type) {
			let ref = firestore
				.collection(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}`)
				.where('path', '==', '/')
				.where('isFolder', '==', true);
			if (type === 'collaborators') {
				ref = ref.where('permissions', 'array-contains-any', [agencyId, uid]);
			} else {
				ref = ref.where('clientPermission', '==', true);
			}
			ref.orderBy('normalizedName', 'asc')
				.get()
				.then((querySnapshot) => {
					if (querySnapshot) {
						const folders = [];
						querySnapshot.forEach((doc) => {
							if (doc.exists) {
								folders.push({ id: doc.id, ...doc.data() });
							}
						});
						setFolders((prev) => {
							const newFolders = [...prev];
							folders.forEach((folder) => {
								const index = newFolders.findIndex((it) => it.id === folder.id);
								if (index === -1) {
									newFolders.push(folder);
								} else {
									newFolders[index] = folder;
								}
							});
							return newFolders;
						});
						setDataLoading(false);
					}
				});
		}
	}, [projectId, modalMoveItem, uid, type, agencyId]);

	const handleMove = async () => {
		if (projectId && files && selectedFolderId && uid && type) {
			setLoading(true);
			setSelectedFileIds([]);
			const batch = firestore.batch();
			for (const file of files) {
				const subfiles = (
					await firestore
						.collection(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}`)
						.where('path', '>=', file.path + file.id + '/')
						.where('path', '<', file.path + file.id + '/' + '\uf8ff')
						.get()
				).docs.map((doc) => ({ ...doc.data(), id: doc.id }));
				for (const subfile of subfiles) {
					const ref = firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${subfile.id}`);
					batch.update(ref, {
						path: subfile.path.replace(
							file.path,
							folders.find((it) => it.id === selectedFolderId).path + selectedFolderId + '/'
						),
					});
				}

				const ref = firestore.doc(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}/${file.id}`);
				batch.update(ref, {
					path: folders.find((it) => it.id === selectedFolderId).path + selectedFolderId + '/',
				});
			}
			batch.commit().then(() => {
				setLoading(false);
				setSuccess(true);
			});
		}
	};

	const handleClose = () => {
		setModalMoveItem(false);
		setSelectedFolderId(null);
		setLoading(false);
		setSuccess(false);
	};

	const handleLocate = () => {
		const file = files[0];
		setPath([
			...folders
				.find((it) => it.id === selectedFolderId)
				.path.split('/')
				.filter((e) => e !== ''),
			selectedFolderId,
		]);
		setSelectedFileIds([file.id]);
		handleClose();
	};

	return (
		<Modal
			isOpen={modalMoveItem}
			className={'modalMoveItem'}
			overlayClassName="overlayModalMoveItem"
			closeTimeoutMS={300}>
			<img src={crossIcon} alt="" className={'crossIcon'} onClick={handleClose} />
			<p className={'title'}>{t('translation.selectAPlaceToSave')}</p>

			<div className={'bar'} />

			<div className={'arborescence'} style={{ opacity: loading ? 0.5 : null }}>
				{folders
					.filter((it) => it.path === '/')
					.map((data) => (
						<ElementArborescence
							key={data.id}
							data={data}
							levelArborescence={0}
							projectId={projectId}
							folders={folders}
							setFolders={setFolders}
							selectedFolderId={selectedFolderId}
							setSelectedFolderId={setSelectedFolderId}
						/>
					))}
			</div>

			<div className={'bar'} />

			<div className={'containerElement'}>
				<div className={'elements'}>
					<ElementToMove file={files[0]} multiple={files.length > 1} />
					<div style={{ display: 'flex' }}>
						<img src={strongArrowIcon} alt="" className={'strongArrowIcon'} />
						<img src={strongArrowIcon} alt="" className={'strongArrowIcon'} />
					</div>
					<DestinationElement folder={folders.find((it) => it.id === selectedFolderId)} />
				</div>
				{loading ? (
					<div className={'loadingContainer'}>
						<div
							className={'chargementContainer'}
							style={{ width: 35, margin: 0, height: 20, marginLeft: 0 }}>
							<img
								src={loadingUpload}
								alt=""
								className={'chargement'}
								style={{ opacity: 1, width: 14, margin: 0 }}
							/>
						</div>
						{t('translation.moveInProgress')}
					</div>
				) : success ? (
					<div className={'loadingContainer'} style={{ color: '#31a128' }} onClick={handleLocate}>
						<div style={{ width: 35, margin: 0, height: 20, marginLeft: -35 }}>
							<img src={checkVert} alt="" className={'checkVert'} />
						</div>
						{t('translation.element')}
						{files.length > 1 && 's'} déplacé{files.length > 1 && 's'} {t('translation.withSuccess')}
						<div className={'buttonLocate'}>
							<img src={searchIcon} alt="" className={'searchIconItem'} />
						</div>
					</div>
				) : (
					<p className={'buttonMove'} onClick={handleMove}>
						{t('translation.operateMove')}
					</p>
				)}
			</div>
		</Modal>
	);
}

function ElementArborescence({
	data,
	levelArborescence,
	projectId,
	folders,
	setFolders,
	selectedFolderId,
	setSelectedFolderId,
}) {
	const uid = auth.currentUser?.uid;
	const type = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);

	const [isOpen, setIsOpen] = useState(false);
	const isSelected = selectedFolderId === data.id;

	const [dataLoading, setDataLoading] = useState(true);

	const handleClick = () => {
		setSelectedFolderId(data.id);
		setIsOpen((prev) => !prev);
		if (projectId && data && uid && type) {
			let ref = firestore
				.collection(`${PROJECT_COLLECTION}/${projectId}/${FILE_COLLECTION}`)
				.where('path', '==', data.path + data.id + '/')
				.where('isFolder', '==', true);
			if (type === 'collaborators') {
				ref = ref.where('permissions', 'array-contains-any', [agencyId, uid]);
			} else {
				ref = ref.where('clientPermission', '==', true);
			}
			ref.orderBy('normalizedName', 'asc')
				.get()
				.then((querySnapshot) => {
					if (querySnapshot) {
						const folders = [];
						querySnapshot.forEach((doc) => {
							if (doc.exists) {
								folders.push({ id: doc.id, ...doc.data() });
							}
						});
						setFolders((prev) => {
							const newFolders = [...prev];
							folders.forEach((folder) => {
								const index = newFolders.findIndex((it) => it.id === folder.id);
								if (index === -1) {
									newFolders.push(folder);
								} else {
									newFolders[index] = folder;
								}
							});
							return newFolders;
						});
						setDataLoading(false);
					}
				});
		}
	};

	return (
		<div>
			<div
				className={isSelected ? 'folderArborescenceSelected' : 'folderArborescence'}
				style={{ marginLeft: levelArborescence * 30 }}
				onClick={handleClick}>
				<img
					src={isSelected ? whiteStrongArrowIcon : strongArrowIcon}
					alt=""
					className={'arrowIcon'}
					style={{ transform: isOpen ? 'rotate(90deg)' : null }}
				/>
				<img src={folderIcon} alt="" className={'folderIcon'} />
				<p className={'folderName'}>{data.name}</p>
			</div>

			{isOpen &&
				folders
					.filter((it) => it.path === data.path + data.id + '/')
					.map((data) => (
						<ElementArborescence
							key={data.id}
							data={data}
							levelArborescence={levelArborescence + 1}
							projectId={projectId}
							folders={folders}
							setFolders={setFolders}
							selectedFolderId={selectedFolderId}
							setSelectedFolderId={setSelectedFolderId}
						/>
					))}
		</div>
	);
}

function ElementToMove({ file, multiple }) {
	const { t } = useTranslation();

	return (
		<div className={'elementToMove'}>
			<img
				src={multiple ? multipleSelection : chooseIconFile(file?.isFolder ? 'folder' : file?.type)}
				alt=""
				className={'icon'}
			/>
			<p className={'elementName'}>{multiple ? t('translation.selection') : file?.name}</p>
		</div>
	);
}

function DestinationElement({ folder }) {
	const { t } = useTranslation();

	return (
		<div className={'elementToMove'}>
			<img src={folderIcon} alt="" className={'icon'} />
			<p className={'elementName'}>{folder?.name ?? t('translation.noFolderSelected')}</p>
		</div>
	);
}
