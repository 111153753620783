import React, { useState } from 'react';
import './_settingsProjets.scss';
import Informations from './Informations/Informations';
import { useTranslation } from 'react-i18next';

export default function SettingsProjet({ auth, projectId, projectData, setProjectData }) {
	const [settingDisplayed, setSettingDisplayed] = useState('contributors');
	const { t } = useTranslation();

	return (
		<div className={'settingsProjet'}>
			<p className={'titlePage'}>{t('common.settings')}</p>
			<p className={'subtitlePage'}>{t('translation.editSettings')}</p>
			<div className={'bar'} />
			<Informations uid={auth} projectId={projectId} projectData={projectData} setProjectData={setProjectData} />
		</div>
	);
}
