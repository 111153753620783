import React, { useContext, useState } from 'react';
import Step from '../Step/Step';
import { auth, firestore } from '../../../../firebase/config';
import ModalTyping from '../../../../components/ModalTyping';
import { generateUniqueFirestoreId } from '../../../../firebase/utils';
import AgencyContext from '../../../../contexts/agencyContext';
import bigEditIcon from '../../../../assets/bigEditIcon.svg';
import { useTranslation } from 'react-i18next';
import './Phase.scss';
import '../Progression.scss';
import { useProjectContext } from '../../../../contexts/projectContext';
import AuthDataContext from '../../../../contexts/authDataContext';
import bluePlus from '../../../../assets/bluePlus.svg';
import TypeContext from '../../../../contexts/typeContext';
import { ProgressionStatus } from '../utils';
import { getMonth, getYear } from '../../../../useful/utils';
import doubleArrows from '../../../../assets/doubleArrows.svg';
import trashIcon from '../../../../assets/trashIcon.svg';
import Colors from '../../../../useful/Colors';
import MonthYearPicker from './MonthYearPicker';
import checkBlanc from '../../../../assets/checkBlanc.png';
import bottomArrow from '../../../../assets/bottomArrow.svg';
import { wait } from '../../../../useful/UsefulFunctions';
import plusSquare from '../../../../assets/plusSquare.svg';
import StepReorganize from '../Step/StepReorganize';
import TagComponent from '../Tags/TagComponent';
import IconPlus from '../../../../assets/IconPlus.png';
import { arrayRemove, arrayUnion } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';

export default function Phase({ phaseData, setPhases, indexPhase, canModify, allTags, selectedTags, setSelectedTags }) {
	const { t } = useTranslation();

	const uid = auth.currentUser?.uid;
	const type = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);
	const authData = useContext(AuthDataContext);

	const [project] = useProjectContext();
	const projectId = project?.id;
	const steps = phaseData.stepList;

	const [isExpanded, setIsExpanded] = useState(phaseData.status !== ProgressionStatus.COMPLETED);
	const [isEdition, setIsEdition] = useState(false);
	const [isReorganize, setIsReorganize] = useState(false);
	const [modalNewStep, setModalNewStep] = useState(false);
	const [indexCreation, setIndexCreation] = useState(-1);
	const [newTag, setNewTag] = useState('');

	async function newStep(text) {
		const batch = firestore.batch();

		const id = generateUniqueFirestoreId();
		const data = {
			id,
			name: text ? text : t('progression.new_step'),
			status: ProgressionStatus.NOT_STARTED,
			date: new Date().toISOString(),
			agency: agencyId ? agencyId : 'clients',
			uid,
			phaseId: phaseData.id,
			type: 'step',
		};

		setPhases((prevState) => {
			const newPhases = [...prevState];
			const phase = newPhases.find((phase) => phase.id === phaseData.id);
			phase.stepList = phase.stepList ?? [];
			phase.stepList.splice(indexCreation, 0, data);

			phase.stepList.forEach((step, index) => {
				if (index > indexCreation) {
					const date = new Date(new Date().getTime() + index).toISOString();
					step.date = date;

					const stepRef = firestore.doc(`projects/${projectId}/progression/${step.id}`);
					batch.update(stepRef, { date });
				}
			});

			const phaseRef = firestore.doc(`projects/${projectId}/progression/${phase.id}`);
			if (
				phase.stepList?.some(
					(step) =>
						step.status === ProgressionStatus.IN_PROGRESS || step.status === ProgressionStatus.COMPLETED
				)
			) {
				phase.status = ProgressionStatus.IN_PROGRESS;
				batch.update(phaseRef, { status: ProgressionStatus.IN_PROGRESS });
			} else {
				phase.status = ProgressionStatus.NOT_STARTED;
				batch.update(phaseRef, { status: ProgressionStatus.NOT_STARTED });
			}

			return newPhases;
		});

		await wait(500);

		batch.set(firestore.doc(`projects/${projectId}/progression/${id}`), data);

		await batch.commit();

		mixpanel.track('Step Created', {
			'Project ID': projectId,
			Page: 'progression',
		});
	}

	const changeName = (name) => {
		setPhases((prevState) => {
			const newPhases = [...prevState];
			const phase = newPhases.find((phase) => phase.id === phaseData.id);
			phase.name = name;
			return newPhases;
		});
	};

	const changeDescription = (description) => {
		setPhases((prevState) => {
			const newPhases = [...prevState];
			const phase = newPhases.find((phase) => phase.id === phaseData.id);
			phase.notes = description;
			return newPhases;
		});
	};

	const endChangeName = async () => {
		await firestore.doc(`projects/${projectId}/progression/${phaseData.id}`).update({ name: phaseData.name });
	};

	const endChangeStartDate = async (date) => {
		setPhases((prevState) => {
			const newPhases = [...prevState];
			const phase = newPhases.find((phase) => phase.id === phaseData.id);
			phase.startAt = date?.toISOString() ?? null;
			return newPhases;
		});
		await firestore
			.doc(`projects/${projectId}/progression/${phaseData.id}`)
			.update({ startAt: date?.toISOString() ?? null });
	};

	const endChangeEndDate = async (date) => {
		setPhases((prevState) => {
			const newPhases = [...prevState];
			const phase = newPhases.find((phase) => phase.id === phaseData.id);
			phase.endAt = date?.toISOString() ?? null;
			return newPhases;
		});
		await firestore
			.doc(`projects/${projectId}/progression/${phaseData.id}`)
			.update({ endAt: date?.toISOString() ?? null });
	};

	const endChangeDescription = async () => {
		await firestore.doc(`projects/${projectId}/progression/${phaseData.id}`).update({ notes: phaseData.notes });
	};

	const deletePhase = async () => {
		if (confirm(t('progression_page.sure_to_delete_phase'))) {
			const batch = firestore.batch();
			const phase = phaseData;

			setPhases((prevState) => {
				const newPhases = [...prevState];
				const index = newPhases.findIndex((phase) => phase.id === phaseData.id);
				newPhases.splice(index, 1);
				return newPhases;
			});

			batch.delete(firestore.doc(`projects/${projectId}/progression/${phase.id}`));
			phase.stepList?.forEach((step) => {
				batch.delete(firestore.doc(`projects/${projectId}/progression/${step.id}`));
				step.subStepList?.forEach((subStep) => {
					batch.delete(firestore.doc(`projects/${projectId}/progression/${subStep.id}`));
				});
			});

			await batch.commit();
		}
	};

	const onChangeNewTag = (event) => {
		setNewTag(event.target.value);
	};

	const addNewTag = async () => {
		if (newTag) {
			setPhases((prevState) => {
				const newPhases = [...prevState];
				const phase = newPhases.find((phase) => phase.id === phaseData.id);
				phase.tags = phase.tags ?? [];
				phase.tags = [newTag, ...phase.tags];
				return newPhases;
			});
			await firestore.doc(`projects/${projectId}/progression/${phaseData.id}`).update({
				tags: arrayUnion(newTag),
			});
			setNewTag('');
		}
	};

	const onClickTag = async (name) => {
		if (isEdition) {
			setPhases((prevState) => {
				const newPhases = [...prevState];
				const phase = newPhases.find((phase) => phase.id === phaseData.id);
				phase.tags = phase.tags ?? [];
				if (phase.tags.includes(name)) {
					phase.tags = phase.tags.filter((item) => item !== name);
				} else {
					phase.tags.push(name);
				}
				return newPhases;
			});

			await firestore.doc(`projects/${projectId}/progression/${phaseData.id}`).update({
				tags: arrayRemove(name),
			});
		} else {
			setSelectedTags((oldValue) => {
				if (oldValue?.includes(name)) {
					return oldValue.filter((item) => item !== name);
				} else {
					return [...oldValue, name];
				}
			});
		}
	};

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.target.blur();
		}
	};

	const saveReorganizationSteps = async () => {
		if (isReorganize) {
			setIsReorganize(false);
			if (phaseData.stepList && phaseData.stepList.length > 0) {
				const batch = firestore.batch();
				phaseData.stepList.forEach((step, index) => {
					batch.update(firestore.doc(`projects/${projectId}/progression/${step.id}`), {
						date: new Date(new Date().getTime() + index).toISOString(),
					});
				});
				await batch.commit();
			}
		} else {
			setIsReorganize(true);
		}
	};

	return (
		<div className={'phase'}>
			<div className={'phaseStickyContainer'}>
				<div
					className={'phaseDetail'}
					onClick={() => setIsExpanded(true)}
					style={isExpanded ? {} : { cursor: 'pointer' }}>
					{isEdition ? (
						<input
							type={'text'}
							className={'phaseName progressionInput'}
							placeholder={t('progression_page.phase_name')}
							value={phaseData.name}
							onChange={(e) => changeName(e.target.value)}
							onBlur={endChangeName}
							onKeyDown={handleKeyDown}
						/>
					) : (
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<p className={'phaseName'}>
								{t('progression_page.phase')} {indexPhase + 1} :{' '}
								{phaseData.name ? phaseData.name : t('progression_page.no_name')}
							</p>
							<div
								className={'phaseStatus'}
								style={{
									backgroundColor:
										phaseData.status === ProgressionStatus.COMPLETED
											? Colors.PROGRESSION_VERY_LIGHT_GREEN
											: phaseData.status === ProgressionStatus.IN_PROGRESS
												? Colors.PROGRESSION_VERY_LIGHT_BLUE
												: '#eeeeee',
								}}>
								<p
									style={{
										color:
											phaseData.status === ProgressionStatus.COMPLETED
												? Colors.PROGRESSION_GREEN
												: phaseData.status === ProgressionStatus.IN_PROGRESS
													? Colors.PROGRESSION_BLUE
													: '#000000',
									}}>
									{phaseData.status === ProgressionStatus.COMPLETED
										? t('progression_page.completed')
										: phaseData.status === ProgressionStatus.IN_PROGRESS
											? t('progression_page.in_progress')
											: t('progression_page.not_started')}
								</p>
							</div>
						</div>
					)}
					{(phaseData.startAt || phaseData.endAt || isEdition) && (
						<div className={'phaseDatesContainer'}>
							{isEdition ? (
								<MonthYearPicker
									date={phaseData.startAt ? new Date(phaseData.startAt) : null}
									setDate={endChangeStartDate}
								/>
							) : (
								phaseData.startAt && (
									<>
										<p>{getMonth(t, phaseData.startAt)}</p>
										{getYear(phaseData.startAt) > 1999 && <p>{getYear(phaseData.startAt)}</p>}
									</>
								)
							)}
							{((phaseData.startAt && phaseData.endAt) || isEdition) && <p>-</p>}
							{isEdition ? (
								<MonthYearPicker
									date={phaseData.endAt ? new Date(phaseData.endAt) : null}
									setDate={endChangeEndDate}
								/>
							) : (
								phaseData.endAt && (
									<>
										<p>{getMonth(t, phaseData.endAt)}</p>
										{getYear(phaseData.endAt) > 1999 && <p>{getYear(phaseData.endAt)}</p>}
									</>
								)
							)}
						</div>
					)}
					{(phaseData.notes || isEdition) &&
						(isEdition ? (
							<textarea
								className={'phaseDescription progressionInput'}
								placeholder={t('progression_page.phase_description')}
								value={phaseData.notes}
								onChange={(e) => changeDescription(e.target.value)}
								onBlur={endChangeDescription}
								onKeyDown={handleKeyDown}
								rows={10}
							/>
						) : (
							<p
								className={'phaseDescription'}
								style={
									isExpanded
										? {}
										: {
												overflowY: 'hidden',
												whiteSpace: 'nowrap',
												textOverflow: 'ellipsis',
											}
								}>
								{phaseData.notes}
							</p>
						))}

					{(isEdition || phaseData.tags?.length > 0) && (
						<div className={'phaseTagsList'}>
							{isEdition && (
								<div className={'phaseTagInputContainer'}>
									<img src={IconPlus} alt={''} />
									<input
										placeholder={t('progression_page.new_tag...')}
										value={newTag}
										onChange={onChangeNewTag}
										onBlur={addNewTag}
										onKeyDown={handleKeyDown}
									/>
								</div>
							)}
							{(isEdition ? allTags : phaseData.tags)?.map((item, index) => (
								<TagComponent
									key={index}
									name={item}
									onClick={onClickTag}
									isSelected={phaseData.tags?.includes(item)}
								/>
							))}
						</div>
					)}
				</div>

				{canModify && isExpanded && (
					<div className={'phaseButtonsContainer'}>
						<div
							onClick={() => setIsEdition((p) => !p)}
							className={'progressionButton hover'}
							style={isEdition ? { backgroundColor: '#b8f3c0' } : {}}>
							<img src={bigEditIcon} alt="iconPlus" />
							<p>{isEdition ? t('common.save') : t('common.edit')}</p>
						</div>
						<div
							onClick={saveReorganizationSteps}
							className={'progressionButton hover'}
							style={isReorganize ? { backgroundColor: '#b8f3c0' } : {}}>
							<img src={doubleArrows} alt="iconPlus" />
							<p>
								{isReorganize
									? t('progression_page.valid_the_order')
									: t('progression_page.reorganize_steps')}
							</p>
						</div>
						<div onClick={deletePhase} className={'progressionButton hover'}>
							<img src={trashIcon} alt="iconPlus" />
							<p>{t('common.delete')}</p>
						</div>
					</div>
				)}
			</div>

			<div className={isExpanded ? 'phaseStepsContainer' : 'phaseStepsContainerCollapsed'}>
				{steps && steps.length > 0 ? (
					<>
						{!isExpanded && (
							<>
								<div className={'phaseCompleted'} onClick={() => setIsExpanded((p) => !p)}>
									<div className={'phaseCircle'}>
										<img src={checkBlanc} alt="" />
									</div>
									<p>{t('progression_page.phase_completed')}</p>
								</div>
								<div className={'phaseShowMore'}>
									<p onClick={() => setIsExpanded((p) => !p)}>{t('progression_page.see_more')}</p>
									<img src={bottomArrow} alt={''} />
								</div>
							</>
						)}
						{canModify && !isEdition ? (
							<AddStep
								onClick={() => {
									setIndexCreation(0);
									setModalNewStep(true);
								}}
							/>
						) : (
							<div style={{ height: 27 }} />
						)}
						{steps.map((value, index) => (
							<div key={index}>
								{isReorganize ? (
									<StepReorganize setPhases={setPhases} stepData={value} />
								) : (
									<Step
										setPhases={setPhases}
										stepData={value}
										isEdition={isEdition}
										canModify={canModify}
									/>
								)}
								{canModify && !isEdition ? (
									<AddStep
										onClick={() => {
											setIndexCreation(index + 1);
											setModalNewStep(true);
										}}
									/>
								) : (
									<div style={{ height: 27 }} />
								)}
							</div>
						))}
					</>
				) : (
					<div className={'phasePlaceholder'}>
						<p className={'phasePlaceholderText'}>{t('progression_page.no_step_created')}</p>
						{canModify && (
							<div
								onClick={() => {
									setIndexCreation(0);
									setModalNewStep(true);
								}}
								className={'progressionButton hover'}
								style={{ marginLeft: 20 }}>
								<img src={plusSquare} alt="iconPlus" />
								<p>{t('progression_page.create_a_first_step')}</p>
							</div>
						)}
					</div>
				)}
			</div>

			<ModalTyping
				modalTyping={modalNewStep}
				setModalTyping={setModalNewStep}
				title={t('progression_page.step_name')}
				func={newStep}
				placeholder={t('progression_page.step_name')}
			/>
		</div>
	);
}

function AddStep({ onClick }) {
	const { t } = useTranslation();

	return (
		<div className={'addStep hover'} style={{ zIndex: 1 }} onClick={onClick}>
			<div className={'barAddStep'} />
			<img src={bluePlus} alt="" />
			<p>{t('progression_page.add_a_step')}</p>
		</div>
	);
}
