import React, { useContext } from 'react';
import returnIcon from '../../../../assets/retourIcon.png';
import pen from '../../../../assets/pen.png';
import { useTranslation } from 'react-i18next';
import { useProjectContext } from '../../../../contexts/projectContext';
import TypeContext from '../../../../contexts/typeContext';
import AgencyContext from '../../../../contexts/agencyContext';
import { dateString } from '../../../../useful/UsefulFunctions';

export default function HeaderAlbum({
	setDisplayAlbum,
	setAlbumSelected,
	setNameAlbumSelected,
	albumSelected,
	setModalModifAlbum,
	setModalNotYourAlbum,
	agencyData,
	partnerAgencies,
	setDataLoading,
	setCountPhoto,
	isModeLecture,
}) {
	const { t } = useTranslation();
	const [project] = useProjectContext();
	const type = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);

	return (
		<div style={{ display: 'flex', marginTop: 13, marginLeft: null }}>
			<div
				onClick={() => {
					setDisplayAlbum(true);
					setAlbumSelected({});
					setNameAlbumSelected('');
					setDataLoading(true);
					setCountPhoto(false);
				}}
				style={{ marginLeft: 30 }}
				className={'goBackButton hover'}>
				<img className={'iconGoBack'} src={returnIcon} alt="iconPlus" />
				<p className={'textGoBack'}>{t('common.back')}</p>
			</div>

			<div
				style={{ display: 'flex' }}
				className={albumSelected ? 'hover' : ''}
				onClick={() => {
					if (albumSelected && !isModeLecture) {
						if (type === 'clients' && albumSelected.createdBy?.userType === 'client') {
							setModalModifAlbum(true);
						} else if (type !== 'clients' && albumSelected.createdBy?.userType === 'client') {
							setModalNotYourAlbum(true);
						} else if (type === 'clients' && albumSelected.createdBy?.userType !== 'client') {
							setModalNotYourAlbum(true);
						} else if (albumSelected.createdBy?.agencyId !== agencyId) {
							setModalNotYourAlbum(true);
						} else {
							setModalModifAlbum(true);
						}
					}
				}}>
				<p className={'title'} style={{ fontWeight: 700 }}>
					{albumSelected.type === 'visit' ? dateString(t, albumSelected.orderDate, true) : albumSelected.name}
				</p>
				{albumSelected && !isModeLecture && (
					<img src={pen} alt="parametresAlbum" className={'parametresAlbum'} />
				)}
			</div>

			{albumSelected && (
				<>
					{
						<>
							{type === 'clients' ? (
								albumSelected?.createdBy?.userType === 'client' ? null : (
									<div style={{ display: 'flex' }}>
										<div
											className={'diminutifAgence'}
											style={{
												backgroundColor:
													project?.creator === albumSelected?.createdBy?.agencyId
														? '#3f5cf6'
														: '#D77534',
											}}>
											{partnerAgencies.get(albumSelected?.createdBy?.agencyId)?.diminutifAgency}
										</div>
									</div>
								)
							) : albumSelected?.createdBy?.userType === 'client' ? (
								<div style={{ display: 'flex' }}>
									<div className={'diminutifAgence'} style={{ backgroundColor: '#328536' }}>
										Client
									</div>
								</div>
							) : albumSelected?.createdBy?.agencyId === agencyData?.uid ? null : (
								<div style={{ display: 'flex' }}>
									<div className={'diminutifAgence'} style={{ backgroundColor: '#3f5cf6' }}>
										{partnerAgencies.get(albumSelected?.createdBy?.agencyId)?.diminutifAgency}
									</div>
								</div>
							)}
						</>
					}
				</>
			)}
		</div>
	);
}
