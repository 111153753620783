import { storage } from './config';

/**
 * Generate a unique id for firestore
 * @returns {string}
 */
export function generateUniqueFirestoreId() {
	const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let autoId = '';
	for (let i = 0; i < 20; i++) {
		autoId += chars.charAt(Math.floor(Math.random() * chars.length));
	}
	return autoId;
}

/**
 * Get the path of a file in firebase storage from an url
 * @param url
 * @returns {string|null}
 */
export function getPathStorageFromUrl(url) {
	if (url) {
		const baseUrl = 'https://firebasestorage.googleapis.com:443/v0/b/opus-f2c9b.appspot.com/o/';
		const baseUrl1 = 'https://firebasestorage.googleapis.com/v0/b/opus-f2c9b.appspot.com/o/';
		const imagePath = url.replace(baseUrl, '');
		let imagePath1 = imagePath.replace(baseUrl1, '');
		const indexOfEndPath = imagePath1.indexOf('?');
		imagePath1 = imagePath1.substring(0, indexOfEndPath);
		imagePath1 = imagePath1.replaceAll('%2F', '/');
		return imagePath1;
	} else {
		return null;
	}
}

/**
 * Delete a file from firebase storage from an url
 * @param url
 */
export function deleteStorageFromUrl(url) {
	if (url) {
		storage
			.ref(getPathStorageFromUrl(url))
			.getDownloadURL()
			.then(() => {
				storage
					.ref(getPathStorageFromUrl(url))
					.delete()
					.then((r) => null);
			})
			.catch(() => null);
	}
}

/**
 * Upload a file on firebase storage, set the upload progress for the global widget and return the download url
 * @param id
 * @param file
 * @param data
 * @param path
 * @param setUploadProgress
 * @param containerName
 * @returns {Promise<string>}
 */
export async function uploadOnStorage(id, file, data, path, setUploadProgress, containerName) {
	const storageRef = storage.ref(`${path}/${id}`);
	const uploadTask = storageRef.put(file);
	uploadTask.on(
		'state_changed',
		(snapshot) => {
			setUploadProgress((prevState) => {
				return {
					...prevState,
					[id]: {
						...data,
						containerName,
						bytesTransferred: snapshot.bytesTransferred,
						totalBytes: snapshot.totalBytes,
					},
				};
			});
		},
		(error) => {
			console.log(error);
			setUploadProgress((prevState) => {
				return {
					...prevState,
					[id]: {
						...data,
						containerName,
						error: true,
					},
				};
			});
		}
	);
	await uploadTask;
	return await uploadTask.snapshot.ref.getDownloadURL();
}

/**
 * Construct a path from an array of strings using '/' as separator
 * @param paths
 * @returns {string}
 */
export function joinPaths(...paths) {
	return paths.join('/');
}
