import React, { useState, useEffect, useContext } from 'react';
import Matterport from '../../../../../assets/Matterport.png';
import '../../_photos.scss';
import './styles.css';
import { adjustText, getFirstLetters } from '../../../../../useful/UsefulFunctions';
import PartnersContext from '../../../../../contexts/partnersContext';
import AgencyContext from '../../../../../contexts/agencyContext';
import typeContext from '../../../../../contexts/typeContext';
import { useTranslation } from 'react-i18next';
import chevronLeft from '../../../../../assets/chevronLeft.svg';
import chevronRight from '../../../../../assets/chevronRight.svg';

export default function AlbumCard({
	value,
	handleSelectAlbum,
	opacity2,
	projectImgUrl,
	isReorganizeMode = false,
	handleMoveLeft,
	handleMoveRight,
	slideDirection,
	endAnimation = false,
}) {
	const { t } = useTranslation();

	const type = useContext(typeContext);
	const [partners] = useContext(PartnersContext);
	const agencyId = useContext(AgencyContext);

	const cardClassName = isReorganizeMode ? "album-card-reorganize" : "cardAlbum";

	return (
		<div className={`album-card ${isReorganizeMode && !endAnimation ? "animation" : ""} ${isReorganizeMode ? slideDirection : ""}`}>
			<div
				className={cardClassName}
				style={{ opacity: opacity2 }}
				onClick={() => !isReorganizeMode && handleSelectAlbum(value)}
			>
				<div className={isReorganizeMode ? "album-card-reorganize-image" : "albumImage"}>
					<img
						src={value.url ? value.url : projectImgUrl}
					/>

					{!isReorganizeMode && value.type === 'albumMatterport' && (
						<div className={'containerMatterportIcon'}>
							<img src={Matterport} alt="" className={'iconMatterport'} />
						</div>
					)}

					{isReorganizeMode && (
						<div className="album-card-reorganize-chevrons">
							<img src={chevronLeft} alt="" onClick={() => handleMoveLeft(value)} />
							<img src={chevronRight} alt="" onClick={() => handleMoveRight(value)} />
						</div>
					)}
				</div>

				{!isReorganizeMode && value.createdBy?.agencyId !== agencyId && (
					value.createdBy?.userType === 'client' ? (
						type === 'clients' ? null : (
							<div
								className={'diminutifAgence'}
								style={{
									backgroundColor: value.createdBy?.userType === 'client' ? '#328536' : '#3f5cf6',
								}}
							>
								{t('common.client')}
							</div>
						)
					) : (
						<div className={'diminutifAgence'}>
							{partners.find((partner) => partner.id === value.createdBy?.agencyId)?.diminutifAgency ??
								getFirstLetters(
									partners.find((partner) => partner.id === value.createdBy?.agencyId)?.name
								)}
						</div>
					)
				)}

				<div className={isReorganizeMode ? "album-card-reorganize-name" : "text"} style={{ justifyContent: 'space-between' }}>
					<p className={isReorganizeMode ? "" : "title"}>{adjustText(value.name, 28)}</p>
				</div>
			</div>
		</div>
	);
}
