import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Login from './pages/Login/Login';
import FicheProjet from './pages/Fiche Projet/FicheProjet';
import PageProjets from './pages/Projets/PageProjets';
import NotFound from './pages/NotFound';
import LoadingPage from './pages/Login/LoadingPage';
import OnBoardingAdministrator from './pages/Creations Compte/OnBoarding';
import AgencyContext from './contexts/agencyContext';
import TypeContext from './contexts/typeContext';
import AgencyDataContext from './contexts/agencyDataContext';
import AuthDataContext from './contexts/authDataContext';
import { auth, firestore } from './firebase/config';
import Parametres from './pages/Paramètres/Parametres';
import LanguageContext from './contexts/languageContext';
import DashboardAgence from './pages/Agence/DashboardAgence';
import ChoosePlan from './pages/Agence/Facturation/Choose Plan Page/ChoosePlan';
import UploadContext from './contexts/uploadContext';
import WidgetUpload from './components/WidgetUpload/WidgetUpload';
import { onAuthStateChanged } from 'firebase/auth';
import { ProjectContextProvider } from './contexts/projectContext';
import { UsersContextProvider } from './contexts/usersContext';
import JoinProject from './pages/Join Project/JoinProject';
import { ADMIN_COMPANIES_COLLECTION, COMPANIES_COLLECTION, SUBSCRIPTION_COLLECTION } from './firebase/paths';
import StripeContext from './contexts/stripeContext';
import { useIntercom } from 'react-use-intercom';
import Messaging from './pages/Messaging/Messaging';
import { joinPaths } from './firebase/utils';
import { mergeAndOrder } from './useful/utils';
import { ProjectsContext } from './contexts/projectsContext';
import { OnlyAgencyProjectsContext } from './contexts/onlyAgencyProjectsContext';
import { useTranslation } from 'react-i18next';
import mixpanel from 'mixpanel-browser';

const App = () => {
	const { i18n } = useTranslation();

	const [userId, setUserId] = useState();
	const [userSession, setUserSession] = useState(null);
	const [type, setType] = useState();
	const [agency, setAgency] = useState();
	const [agencyData, setAgencyData] = useState();
	const [authData, setAuthData] = useState();
	const [language, setLanguage] = useState('fr');
	const [uploadProgress, setUploadProgress] = useState({});
	const [stripeData, setStripeData] = useState({});
	const [projects, setProjects] = useState([]);
	const [projectsLoaded, setProjectsLoaded] = useState(false);
	const [onlyAgencyProjects, setOnlyAgencyProjects] = useState([]);

	const { boot, shutdown, update } = useIntercom();
	const location = useLocation();

	useEffect(() => {
		const listener = onAuthStateChanged(auth, (user) => {
			if (user) {
				setUserSession(user);
				setUserId(user.uid);
				mixpanel.identify(user.uid);
				mixpanel.people.set_once({
					$email: user.email,
					$created: new Date(user.metadata.creationTime).toISOString().slice(0, -5),
				});
			} else {
				setUserSession(null);
				setUserId(null);
			}
		});
		return () => {
			listener();
		};
	}, [userSession, agency, type]);

	useEffect(() => {
		i18n.changeLanguage(localStorage.getItem('language'));
		if (userId) {
			firestore
				.doc(`accounts/${userId}`)
				.get()
				.then((documentSnaphsot) => {
					if (documentSnaphsot && documentSnaphsot.exists) {
						setType(documentSnaphsot.data().type);
						if (documentSnaphsot.data().type === 'collaborators') {
							setAgency(documentSnaphsot.data().agency);
						} else if (documentSnaphsot.data().type === 'agencies') {
							setAgency(userId);
						} else {
							setAgency(null);
						}
						if (documentSnaphsot.data().language) {
							localStorage.setItem('language', documentSnaphsot.data().language);
							i18n.changeLanguage(documentSnaphsot.data().language);
						}
					}
				});
		}
	}, [userId]);

	useEffect(() => {
		const subscriber = firestore
			.doc(type === 'collaborators' ? `agencies/${agency}/${type}/${userId}` : `${type}/${userId}`)
			.onSnapshot((documentSnapshot) => {
				if (documentSnapshot && documentSnapshot.exists) {
					setAuthData({ ...documentSnapshot.data(), uid: userId, type, agency, id: userId });
					mixpanel.people.set_once({
						$first_name: documentSnapshot.data().surname,
						$last_name: documentSnapshot.data().name,
						'Contact email': documentSnapshot.data().email,
						$phone: documentSnapshot.data().phone,
						'Company ID': agency,
					});
					mixpanel.register_once({
						'Company ID': agency,
					});
				}
			});
		if (type === 'collaborators' && agency && userId) {
			firestore
				.doc(`agencies/${agency}/collaborators/${userId}`)
				.update({ lastConnexion: new Date().toISOString() })
				.then(() => { });
		} else if (type === 'clients' && userId) {
			firestore
				.doc(`clients/${userId}`)
				.update({ lastConnexion: new Date().toISOString() })
				.then(() => { });
		}
		return () => subscriber();
	}, [agency, type, userId]);

	useEffect(() => {
		if (agency) {
			const subscriber1 = firestore.doc(`agencies/${agency}`).onSnapshot((documentSnapshot) => {
				if (documentSnapshot && documentSnapshot.exists) {
					setAgencyData((prevState) => ({
						...prevState,
						...documentSnapshot.data(),
						id: documentSnapshot.id,
					}));
				}
			});
			const subscriber2 = firestore
				.collection(COMPANIES_COLLECTION)
				.doc(agency)
				.onSnapshot((documentSnapshot) => {
					if (documentSnapshot && documentSnapshot.exists) {
						setStripeData((prevState) => ({ ...prevState, ...documentSnapshot.data() }));
					}
				});
			const subscriber3 = firestore
				.collection(COMPANIES_COLLECTION)
				.doc(agency)
				.collection(SUBSCRIPTION_COLLECTION)
				.orderBy('created', 'desc')
				.limit(1)
				.onSnapshot((querySnapshot) => {
					if (querySnapshot.empty) {
						setStripeData((prevState) => ({
							...prevState,
							isFreeTrial: prevState.isFreeTrial !== false,
						}));
					} else {
						querySnapshot.forEach((doc) => {
							setStripeData((prevState) => ({
								...prevState,
								...doc.data(),
								priceId: doc.data().items[0].price.id,
							}));
						});
					}
				});
			const subscriber4 = firestore
				.collection(ADMIN_COMPANIES_COLLECTION)
				.doc(agency)
				.onSnapshot((doc) => {
					if (doc && doc.exists) {
						setAgencyData((prevState) => ({ ...prevState, ...doc.data() }));
					}
				});
			return () => {
				subscriber1();
				subscriber2();
				subscriber3();
				subscriber4();
			};
		}
	}, [agency]);

	useEffect(() => {
		if (userId && type && (agency || type === 'clients')) {
			const subscriber = firestore
				.collection(
					type === 'collaborators'
						? joinPaths('agencies', agency, 'collaborators', userId, 'projects')
						: joinPaths('clients', userId, 'projects')
				)
				.onSnapshot(async (querySnapshot) => {
					if (querySnapshot) {
						const projects = [];

						const promises = querySnapshot.docs.map(async (doc) => {
							await firestore
								.doc(joinPaths('projects', doc.id))
								.get()
								.then((snapshot) => {
									if (snapshot.exists) {
										projects.push({ ...doc.data(), ...snapshot.data(), id: snapshot.id });
									}
								});
						});

						await Promise.all(promises);

						setProjects(
							mergeAndOrder(
								(a, b) => {
									const aName = (a?.tag ?? '') + ' ' + (a?.name ?? '');
									const bName = (b?.tag ?? '') + ' ' + (b?.name ?? '');
									return aName.localeCompare(bName);
								},
								'id',
								[],
								projects
							)
						);
						setProjectsLoaded(true);
					}
				});

			return () => {
				subscriber();
			};
		}
	}, [userId, type, agency]);

	useEffect(() => {
		if (projectsLoaded && type === 'collaborators' && agency && projects) {
			const subscriber = firestore
				.collection(joinPaths('agencies', agency, 'projects'))
				.onSnapshot(async (querySnapshot) => {
					if (querySnapshot) {
						if (querySnapshot.empty) {
							setOnlyAgencyProjects([]);
						} else {
							const agencyProjects = [];

							const promises = querySnapshot.docs.map(async (doc) => {
								if (doc && doc.exists && !projects.find((project) => project.id === doc.id)) {
									await firestore
										.doc(joinPaths('projects', doc.id))
										.get()
										.then((snapshot) => {
											if (snapshot.exists) {
												agencyProjects.push({
													...doc.data(),
													...snapshot.data(),
													id: snapshot.id,
												});
											}
										});
								}
							});

							await Promise.all(promises);

							setOnlyAgencyProjects(
								mergeAndOrder(
									(a, b) => {
										const aName = (a?.tag ?? '') + ' ' + (a?.name ?? '');
										const bName = (b?.tag ?? '') + ' ' + (b?.name ?? '');
										return aName.localeCompare(bName);
									},
									'id',
									[],
									agencyProjects
								)
							);
						}
					}
				});
			return () => {
				subscriber();
			};
		}
	}, [type, projectsLoaded, agency, projects]);

	useEffect(() => {
		const pathsToShowIntercom = [
			'/',
			'/agence',
			'/choose-plan',
			'/projets',
			'/parametres-profil',
			'/join-project',
			'/projets-tutorial',
		];

		if (pathsToShowIntercom.includes(location.pathname)) {
			boot();
		} else {
			shutdown();
		}
	}, [location, boot, shutdown]);

	useEffect(() => {
		if (authData && (agencyData || authData.type === 'clients')) {
			update({
				email: authData?.email,
				userId: authData?.id,
				name: authData?.surname + ' ' + authData?.name,
				createdAt: new Date(authData?.date).getTime() / 1000,
				company: {
					companyId: agencyData?.id,
					name: agencyData?.name,
					createdAt: new Date(agencyData?.date).getTime() / 1000,
				},
				customAttributes: {
					stripe_id: stripeData?.stripeId,
				},
			});
		}
	}, [update, authData, agencyData, location, stripeData]);

	return (
		<TypeContext.Provider value={type}>
			<AgencyContext.Provider value={agency}>
				<AuthDataContext.Provider value={authData}>
					<AgencyDataContext.Provider value={agencyData}>
						<LanguageContext.Provider value={[language, setLanguage]}>
							<UploadContext.Provider value={[uploadProgress, setUploadProgress]}>
								<ProjectsContext.Provider value={[projects, setProjects]}>
									<OnlyAgencyProjectsContext.Provider
										value={[onlyAgencyProjects, setOnlyAgencyProjects]}>
										<ProjectContextProvider>
											<UsersContextProvider>
												<StripeContext.Provider value={stripeData}>
													<WidgetUpload
														progressFiles={uploadProgress}
														setProgressFiles={setUploadProgress}
													/>
													<Routes>
														<Route path="/" element={<Login />} />
														<Route path="/Login" element={<Login />} />
														<Route
															path="/OnBoarding"
															element={<OnBoardingAdministrator />}
														/>
														<Route path="/join-project" element={<JoinProject />} />
														<Route path="/loading" element={<LoadingPage />} />
														<Route path="/agence" element={<DashboardAgence />} />
														<Route path="/choose-plan" element={<ChoosePlan />} />
														<Route path="/projets" element={<PageProjets />} />
														<Route path="/projets-tutorial" element={<PageProjets />} />
														<Route path="/fiche-projet" element={<FicheProjet />} />
														<Route path="/global-messagerie" element={<Messaging />} />
														<Route path="/parametres-profil" element={<Parametres />} />
														<Route element={<NotFound />} />
													</Routes>
												</StripeContext.Provider>
											</UsersContextProvider>
										</ProjectContextProvider>
									</OnlyAgencyProjectsContext.Provider>
								</ProjectsContext.Provider>
							</UploadContext.Provider>
						</LanguageContext.Provider>
					</AgencyDataContext.Provider>
				</AuthDataContext.Provider>
			</AgencyContext.Provider>
		</TypeContext.Provider>
	);
};

export default App;
