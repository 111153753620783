import React, { createContext, useContext, useState } from 'react';

const UsersContext = createContext();

export function UsersContextProvider({ children }) {
	const [users, setUsers] = useState([]);

	return <UsersContext.Provider value={[users, setUsers]}>{children}</UsersContext.Provider>;
}

export function useUsersContext() {
	return useContext(UsersContext);
}
