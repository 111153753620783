import React, { useEffect } from 'react';
import Modal from 'react-modal';
import whiteLeft from '../assets/whiteLeft.svg';
import { ProgressBar, Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import leftIcon from '../assets/leftIcon.png';
import { useTranslation } from 'react-i18next';

export default function ModalDocument({
										  isOpen,
										  setIsOpen,
										  uri,
										  name,
										  type,
										  handleNext,
										  handlePrevious,
										  noNext,
										  noPrevious,
									  }) {
	const { t } = useTranslation();

	const newplugin = defaultLayoutPlugin();

	useEffect(() => {
		if (isOpen) {
			const handleKeyPress = (event) => {
				switch (event.key) {
					case 'ArrowRight':
						handleNext();
						break;
					case 'ArrowLeft':
						handlePrevious();
						break;
					default:
						break;
				}
			};
			document.addEventListener('keydown', handleKeyPress);
			return () => {
				document.removeEventListener('keydown', handleKeyPress);
			};
		}
	}, [isOpen]);

	return (
		<Modal isOpen={isOpen} className={'modalPhoto'} overlayClassName='overlayModalPhoto'>
			<div className={'containerBackMenu'}>
				<div style={{ width: 200, display: 'flex', alignItems: 'center', cursor: 'pointer' }}
					 className={'goBackButton'}
					 onClick={(e) => {
						 setIsOpen(false);
					 }}>
					<img src={whiteLeft} alt='' className={'icon'} style={{ marginRight: 8, width: 9, marginTop: 1 }} />
					<p style={{ color: '#FFF', fontSize: 15, margin: 0 }}>{t('common.back')}</p>
				</div>

				<div style={{ display: 'flex', alignItems: 'center', gap: 30 }}>
					<div
						className={'previousNext'} style={{ opacity: noPrevious ? 0.5 : 1 }} onClick={handlePrevious}>
						<img src={leftIcon} alt='' className={'leftIcon'} />
					</div>
					<p style={{ color: '#FFF', margin: 0, fontSize: 14, whiteSpace: 'nowrap' }}>{name}</p>
					<div
						className={'previousNext'} style={{ opacity: noNext ? 0.5 : 1 }} onClick={handleNext}>
						<img src={leftIcon} alt='' className={'rightIcon'} />
					</div>
				</div>

				<a href={uri}>
					Download
				</a>
			</div>


			{type?.includes('image') || type?.includes('heic') || type?.includes('heif') ? (
				<div style={{
					display: 'flex',
					width: '100vw',
					height: 'calc(100vh - 60px)',
					marginTop: 60,
					alignItems: 'center',
					justifyContent: 'center',
				}}>
					<div style={{ cursor: 'pointer', display: 'flex' }}>
						<img src={uri} className={'photoOuvert'} />
					</div>
				</div>
			) : uri && (
				<Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
					<div className={'pdfViewer'}>
						<Viewer theme={'dark'} defaultScale={1} fileUrl={uri} plugins={[newplugin]}
								renderLoader={(percentages: number) => (
									<div style={{ width: '240px' }}>
										<ProgressBar progress={Math.round(percentages)} />
									</div>
								)} />
					</div>
				</Worker>
			)}

		</Modal>
	);
}
