export const USER_COLLECTION = 'accounts';
export const NEW_USER_COLLECTION = 'users';
export const AGENCY_COLLECTION = 'agencies';
export const COLLABORATOR_COLLECTION = 'collaborators';
export const CLIENT_COLLECTION = 'clients';
export const PROJECT_COLLECTION = 'projects';
export const FILE_COLLECTION = 'documents';
export const MEDIA_COLLECTION = 'medias';
export const NOTIFICATION_COLLECTION = 'notifications';
export const COMPANIES_COLLECTION = 'companies';
export const SUBSCRIPTION_COLLECTION = 'subscriptions';
export const GROUP_COLLECTION = 'groups';
export const ADMIN_COMPANIES_COLLECTION = 'admin_companies';
export const MESSAGING_COLLECTION = 'messaging';

export const Paths = {
	PROJECTS: 'projects',
	PROGRESSION: 'progression',
	USERS: 'users',
};
