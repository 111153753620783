import React, { useEffect, useState } from 'react';
import './_componentsForm.scss';
import companyLogo from '../../../assets/companyLogo.svg';
import rightIcon from '../../../assets/rightIcon.svg';
import leftIcon from '../../../assets/leftIcon.svg';
import { auth, firestore } from '../../../firebase/config';
import { AGENCY_COLLECTION } from '../../../firebase/paths';
import grey from '../../../assets/grey.jpg';
import { useTranslation } from 'react-i18next';
import { roles } from '../../Fiche Projet/utils';
import leftArrow from '../../../assets/leftArrow.svg';
import PhoneInput from 'react-phone-number-input';

export function HeaderStep({ title, subtitle }) {
	return (
		<div className={'headerStep'}>
			<p className={'titleHeaderStep'}>{title}</p>
			<p className={'subtitleHeaderStep'}>{subtitle}</p>
		</div>
	);
}

export function GoBack({ setStep, lastStep }) {
	return (
		<div>
			<div className={'buttonBack'} onClick={() => setStep(lastStep)}>
				<img src={leftIcon} className={'leftIcon'} alt="" /> Retour à l'étape précédente
			</div>
		</div>
	);
}

export function BlueButton({ text, subtitle, handleClick, isLoading, disabled, isPhone }) {
	const { t } = useTranslation();

	return (
		<button
			className={'containerButton'}
			style={{ opacity: disabled ? 0.5 : 1, pointerEvents: disabled ? 'none' : 'auto' }}
			onClick={() => {
				if (!disabled && handleClick) {
					handleClick();
				}
			}}>
			<div className={'blueButton'}>{isLoading ? t('common.loading') : text}</div>
			{subtitle && <p className={'subtitleButton'}>{subtitle}</p>}
		</button>
	);
}

export function ItemChoice({ choiceName, choiceTag, setChoice, icon, activeElement }) {
	return (
		<div
			className={'itemChoice'}
			style={{
				borderColor: activeElement ? '#1C57DD' : '#EAEAE9',
				backgroundColor: activeElement ? 'rgba(28,87,221,0.05)' : '#FFF',
			}}
			onClick={() => setChoice(choiceTag)}>
			<img src={icon} alt="" style={{ width: 17 }} className={'choiceIcon'} />
			<p className={'choiceName'}>{choiceName}</p>
		</div>
	);
}

export function DoubleInput({
	placehodlerFirstInput,
	placehodlerSecondInput,
	titleFirstInput,
	titleSecondInput,
	firstInput,
	secondInput,
	setFirstInput,
	setSecondInput,
}) {
	return (
		<div className={'doubleInput'}>
			<div className={'containerLittleInput'}>
				<p className={'titleInput'}>
					{titleFirstInput} <span style={{ color: '#EA3322' }}>*</span>
				</p>
				<input
					placeholder={placehodlerFirstInput}
					type="text"
					value={firstInput}
					onChange={(e) => setFirstInput(e.target.value)}
					className={'littleInput'}
					required={true}
				/>
			</div>

			<div className={'containerLittleInput'}>
				<p className={'titleInput'}>
					{titleSecondInput} <span style={{ color: '#EA3322' }}>*</span>
				</p>
				<input
					placeholder={placehodlerSecondInput}
					type="text"
					value={secondInput}
					onChange={(e) => setSecondInput(e.target.value)}
					className={'littleInput'}
					required={true}
				/>
			</div>
		</div>
	);
}

export function SingleInput({ placeholder, title, value, setValue, type, select, roleIds, setRoleIds, phone }) {
	const { t } = useTranslation();

	return (
		<div className={phone ? 'phoneInput' : 'singleInput'}>
			<p className={'titleInput'}>
				{title} <span style={{ color: '#EA3322' }}>*</span>
			</p>
			{phone ? (
				<PhoneInput international defaultCountry="FR" value={value} onChange={setValue} />
			) : select ? (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<select
						required={true}
						className={'select'}
						value={roleIds[0] || ''}
						onChange={(e) => {
							const value = e.target.value;
							let newRoles;
							if (value === '') {
								newRoles = [];
							} else {
								newRoles = [value];
							}
							setRoleIds(newRoles);
						}}>
						<option value="" disabled>
							Sélectionnez une expertise
						</option>
						{
							// Supposer que roles est déjà appelé avec t et est disponible ici sous le nom rolesList
							[...new Set(roles(t).map((role) => role.category))].map((category) => (
								<optgroup label={category} key={category}>
									{roles(t)
										.filter((role) => role.category === category)
										.map((role) => (
											<option key={role.id} value={role.id}>
												{role.name}
											</option>
										))}
								</optgroup>
							))
						}
					</select>
					<img src={leftArrow} alt="" className={'leftArrow'} />
				</div>
			) : (
				<input
					placeholder={placeholder}
					type={type ? type : 'text'}
					value={value}
					onChange={(e) => setValue(e.target.value)}
					className={'input'}
					required={true}
				/>
			)}
		</div>
	);
}

export function ButtonCreateCompany({ setValue, value, setSelectedCid, setCompanyName }) {
	return (
		<div
			className={value ? 'buttonCreateCompanySelected' : 'buttonCreateCompany'}
			onClick={() => {
				setSelectedCid(null);
				setCompanyName('');
				setValue(true);
			}}>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<div className={'containerCompanyLogo'}>
					<img src={companyLogo} alt="" className={'companyLogo'} />
				</div>
				<div>
					<p className={'title'}>Votre entreprise n’apparait pas ?</p>
					<p className={'subtitle'}>Ajoutez la en cliquant ici</p>
				</div>
			</div>

			<img src={rightIcon} alt="" className={'rightIcon'} />
		</div>
	);
}

export function EntrepriseItemChoice({
	cid,
	selectedCid,
	setSelectedCid,
	setCreateCompany,
	agencyProject,
	setCompanyName,
}) {
	const [name, setName] = useState('');
	const [diminutif, setDiminutif] = useState('');

	useEffect(() => {
		if (cid) {
			firestore
				.collection(AGENCY_COLLECTION)
				.doc(cid)
				.get()
				.then((doc) => {
					if (doc.exists) {
						setName(doc.data().name);
						setDiminutif(doc.data().diminutifAgency);
					}
				});
		}
	}, [cid]);

	return (
		<div
			style={{ display: name === agencyProject && 'none' }}
			onClick={() => {
				setCreateCompany(false);
				setSelectedCid(cid);
				setCompanyName(name);
			}}
			className={selectedCid === cid ? 'entrepriseItemChoiceSelected' : 'entrepriseItemChoice'}>
			<div className={'containerName'}>
				{name} <span>• {diminutif}</span>
			</div>

			<div className={'containerProfiles'}>
				<img src={rightIcon} alt="" className={'rightIcon'} />
			</div>
		</div>
	);
}

export function CardAgencyProject({ agencyProject, diminutifAgencyProject }) {
	return (
		<div className={'cardAgencyProject'}>
			<div className={'textPart'}>
				{agencyProject} <span>• {diminutifAgencyProject}</span>
			</div>
			<div className={'profiles'}></div>
		</div>
	);
}

export function ProjectCardOnboarding({ projectImg, projectName, projectPercentage, isMobile }) {
	const [realBar1, setRealBar1] = useState(0);
	const [realBar2, setRealBar2] = useState(0);
	const [realBar3, setRealBar3] = useState(0);
	const [realBar4, setRealBar4] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			if (isNaN(projectPercentage)) {
				null;
			} else {
				setRealBar1(
					projectPercentage < 100
						? (projectPercentage - 5 > 0 ? projectPercentage - 5 : projectPercentage) / 100
						: 100
				);
				setRealBar2(
					projectPercentage < 100
						? (projectPercentage > 0 ? parseInt(projectPercentage) + 5 : projectPercentage) / 100
						: 100
				);
				setRealBar4(
					projectPercentage < 100
						? (projectPercentage - 2 > 0 ? projectPercentage - 2 : projectPercentage) / 100
						: 100
				);
				setRealBar3(
					projectPercentage < 100
						? (projectPercentage > 0 ? parseInt(projectPercentage) + 2 : projectPercentage) / 100
						: 100
				);
			}
		}, 400);
	}, [projectPercentage]);

	return (
		<div
			className="projectCard"
			style={{ height: isMobile && 110, marginBottom: isMobile && 0, marginTop: isMobile && 0 }}>
			<img src={projectImg} style={{ height: isMobile && 110, width: isMobile && 120 }} />

			<div className="content">
				<div className="head">
					<div style={{ display: 'flex' }}>
						<p className="name">{projectName}</p>
					</div>

					<div className={'row'}>
						<div className="bar" />

						<p className="pourcentage">{projectPercentage} %</p>
					</div>
				</div>

				<div className="progressBars">
					<div className="firstBar" style={{ marginTop: isMobile && 10 }}>
						<div
							className="secondBar"
							style={{
								width: `calc(100% * ${realBar1}`,
								maxWidth: '100%',
								backgroundColor: '#4867EC',
							}}
						/>
					</div>

					<div className="firstBar" style={{ marginTop: isMobile && 10 }}>
						<div
							className="secondBar"
							style={{
								width: `calc(100% * ${realBar2}`,
								maxWidth: '100%',
								backgroundColor: '#4867EC',
							}}
						/>
					</div>

					<div className="firstBar" style={{ marginTop: isMobile && 10 }}>
						<div
							className="secondBar"
							style={{
								width: `calc(100% * ${realBar3}`,
								maxWidth: '100%',
								backgroundColor: '#64a64a',
							}}
						/>
					</div>

					<div className="firstBar" style={{ marginTop: isMobile && 10 }}>
						<div
							className="secondBar"
							style={{
								width: `calc(100% * ${realBar4}`,
								maxWidth: '100%',
								backgroundColor: '#64a64a',
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
