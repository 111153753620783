import React, { useContext, useEffect, useRef, useState } from 'react';
import searchIcon from '../../assets/searchIcon.png';
import CardOnBoardingProjet from './Cards/CardOnBoardingProjet';
import ProjetCard from './Cards/ProjetCard';
import BlankCardProjet from './Cards/BlankCardProjet';
import gerySearchIcon from '../../assets/gerySearchIcon.png';
import WidgetExpirationCarte from './Widgets/WidgetExpirationCarte';
import WidgetEchecPaiement from './Widgets/WidgetEchecPaiement';
import ModauxAddProject from './Modaux/ModauxAddProject';
import plusSquare from '../../assets/plusSquare.svg';
import integrerProjetVisuel from '../../assets/integrerProjetVisuel.png';
import AgencyContext from '../../contexts/agencyContext';
import { useTranslation } from 'react-i18next';
import TypeContext from '../../contexts/typeContext';
import ModalAddProjectClient from './Modaux/ModalAddProjectClient';
import ModalAgencePartenaire from './Modaux/ModalAgencePartenaire';
import { firestore, auth } from '../../firebase/config';
import { NEW_USER_COLLECTION, NOTIFICATION_COLLECTION } from '../../firebase/paths';
import filterIcon from '../../assets/filter.svg';
import { useProjectsContext } from '../../contexts/projectsContext';
import { useOnlyAgencyProjectsContext } from '../../contexts/onlyAgencyProjectsContext';
import AuthDataContext from '../../contexts/authDataContext';

export default function ProjectList({
	auth,
	baniereExpirationCarte,
	setBaniereExpirationCarte,
	baniereEchecPaiement,
	logoUrl,
	modalAddProject,
	setModalAddProject,
	setModalWaitForApproval,
	setModalAccessDenied,
}) {
	const { t } = useTranslation();

	const agency = useContext(AgencyContext);
	const type = useContext(TypeContext);
	const authData = useContext(AuthDataContext);
	const [projects] = useProjectsContext();
	const [allProjects] = useOnlyAgencyProjectsContext();

	const [modalAgencePartenaire, setModalAgencePartenaire] = useState(false);
	const [modalAddProjectClient, setModalAddProjectClient] = useState(false);

	// Sous-menu

	const [enCours, setEnCours] = useState(true);
	const [passes, setPasses] = useState(false);

	// Research
	const [research, setResearch] = useState('');

	// List Height

	const [isThereCurrentNotification, setIsThereNotification] = useState(false);
	const [showNotifications, setShowNotifications] = useState(false);

	useEffect(() => {
		if (auth) {
			const subscriber = firestore.collection(`${NEW_USER_COLLECTION}/${auth}/${NOTIFICATION_COLLECTION}`).where('isUnread', '==', true).limit(1).onSnapshot((querySnapshot) => {
				if (querySnapshot && querySnapshot.size > 0) {
					setIsThereNotification(true);
				} else {
					setIsThereNotification(false);
				}
			});

			return () => subscriber();
		}
	}, [auth]);


	function handleClickNewProject() {
		if (type === 'clients') {
			setModalAddProjectClient(true);
		} else {
			setModalAddProject(true);
		}
	}

	const handleReadAllNotifications = () => {
		firestore
			.collection(`${NEW_USER_COLLECTION}/${auth}/${NOTIFICATION_COLLECTION}`)
			.where('isUnread', '==', true)
			.get()
			.then((querySnapshot) => {
				if (querySnapshot) {
					const batch = firestore.batch();
					querySnapshot.forEach((doc) => {
						batch.update(doc.ref, { isUnread: false });
					});
					batch.commit();
					setShowNotifications(false);
				}
			});
	};

	function ButtonTab({ active, title, onClick }) {
		return (
			<div
				onClick={onClick}
				className={'buttonListProjets'}
				style={{
					fontWeight: active ? 600 : 500,
					color: active ? '#4867EC' : '#3d3d3d',
					display: 'flex',
					alignItems: 'center',
				}}>
				{title}
				<div className={'blueBar'} style={{ zIndex: modalAddProject ? 0 : 10, opacity: active ? 1 : 0 }} />
			</div>
		);
	}

	return (
		<div className="liste">
			<div className={'inner'}>
				{baniereExpirationCarte && (
					<WidgetExpirationCarte setBaniereExpirationCarte={setBaniereExpirationCarte} />
				)}
				{baniereEchecPaiement && <WidgetEchecPaiement />}

				<div className={'headerProjectList'}>
					<div className={'projectWrapper'}>
						<div style={{ display: 'flex' }}>
							<input
								autoComplete={'off'}
								type={'text'}
								placeholder={t('projects_page.research_a_project')}
								className={'rechercheProjetEnCour'}
								value={research}
								onChange={(e) => setResearch(e.target.value)}
							/>
							<img src={searchIcon} alt="" className={'searchIconRechercheProjet'} />

							<ButtonTab
								active={enCours}
								title={t('projects_page.ongoing')}
								onClick={() => {
									setEnCours(true);
									setPasses(false);
								}}
							/>
							<ButtonTab
								active={passes}
								title={t('projects_page.completed')}
								onClick={() => {
									setEnCours(false);
									setPasses(true);
								}}
							/>
						</div>
					</div>

					<div className={'containerNewProjectButtonAndBar'}>
						<div className={'horizontalBar'} />
						{type !== 'clients' && (
							<div className={'containerNewProjectButton'}>
								<div onClick={handleClickNewProject} className={'hover newProjectButton'}>
									<img className={'plusSquare'} src={plusSquare} alt="iconPlus" />
									<p>{t('projects_page.add_a_project')}</p>
								</div>
							</div>
						)}
					</div>

					<div className={'titleProjectsContainer'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<p className={'titleProjects'}>
							{t('translation.myProject')}
						</p>
						<div style={{
							display: 'flex', alignItems: 'center', alignContent: 'center', marginTop: '15px',
						}}>
							{showNotifications && (
								<p
									className={'hover'}
									style={{ fontSize: 12, fontWeight: 500, color: '#3d3d3d', margin: '0 15px', cursor: 'pointer' }}
									onClick={handleReadAllNotifications}
								>
									{t('projects_page.read_all')}
								</p>
							)}
							<div style={{ position: 'relative' }}>
								<div
									onClick={() => setShowNotifications(prev => !prev)}
									className={'hover containerNewProjectButton'}
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										height: 30,
										backgroundColor: '#f0f0f0',
										marginRight: '5px',
										borderRadius: '4px',
										cursor: 'pointer',
										padding: '0 10px',
										overflow: 'hidden',
										whiteSpace: 'nowrap',
									}}
								>
									<img src={filterIcon} alt="filterIcon" style={{ width: 15, height: 15, marginRight: 7 }} />
									<p style={{
										fontWeight: 600,
										fontSize: 12,
										margin: 0,
										color: '#000000',
									}}>{showNotifications ? t('projects_page.show_all_projects') : t('projects_page.show_notified_projects')}</p>
								</div>
								{isThereCurrentNotification && (
									<div
										style={{
											position: 'absolute',
											top: -4,
											right: 0,
											width: 11,
											height: 11,
											borderRadius: '50%',
											backgroundColor: 'red',
											border: '1px solid #fff',
										}}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
				{!enCours && projects?.filter((item) => !item.active).length === 0 && (
					<div className={'placeholderProjetAcheve'}>
						<img src={integrerProjetVisuel} alt="" className={'integrerProjetVisuel'} />
						{t('translation.noPastProjects')} <br /> <br /> {t('translation.changeProjectStatus')}
					</div>
				)}
				<div style={{ display: 'flex', width: '100vw', flexWrap: 'wrap' }}>
					{projects
						? enCours && projects.length === 0
							? authData && (
								<>
									<CardOnBoardingProjet nom={'Cap-Ferrat'} number={1} />
									<CardOnBoardingProjet nom={'Provence'} number={2} />
									<CardOnBoardingProjet nom={'Paris'} number={3} />
									<CardOnBoardingProjet nom={'Londres'} number={4} />
								</>
							)
							: enCours
								? projects
									.filter((item) => showNotifications || item.active !== false)
									.map((item, index) => (
										<ProjetCard
											key={index}
											auth={auth}
											id={item.id}
											seen={item.seen}
											research={research}
											tag={item.tag}
											waitingForApproval={item.waitingStatus === 'pending'}
											setModalWaitForApproval={setModalWaitForApproval}
											accessDenied={item.waitingStatus === 'denied'}
											setModalAccessDenied={setModalAccessDenied}
											projectData={item}
											isMandataire={item.creator === agency}
											isModeLecture={false}
											showNotifications={showNotifications}
										/>
									))
								: projects
									.filter((item) => item.active === false)
									.map((item, index) => (
										<ProjetCard
											key={index}
											auth={auth}
											id={item.id}
											seen={item.seen}
											research={research}
											tag={item.tag}
											waitingForApproval={item.waitingStatus === 'pending'}
											setModalWaitForApproval={setModalWaitForApproval}
											accessDenied={item.waitingStatus === 'denied'}
											setModalAccessDenied={setModalAccessDenied}
											projectData={item}
											isMandataire={item.creator === agency}
											isModeLecture={false}
											showNotifications={showNotifications}
										/>
									))
						: null}
				</div>

				<BlankCardProjet />
				<BlankCardProjet />

				{allProjects?.length > 0 && (
					<div className={'headerProjectList'}>
						<p className={'titleProjects'}>
							{t('translation.allProjects')}
						</p>
					</div>
				)}
				<div style={{ display: 'flex', width: '100vw', flexWrap: 'wrap' }}>
					{enCours
						? allProjects
							?.filter((item) => item.active !== false)
							?.map((item, index) => (
								<ProjetCard
									key={index}
									allProjects={true}
									auth={auth}
									id={item.id}
									seen={item.seen}
									research={research}
									tag={item.tag}
									waitingForApproval={item.waitingStatus === 'pending'}
									setModalWaitForApproval={setModalWaitForApproval}
									accessDenied={item.waitingStatus === 'denied'}
									setModalAccessDenied={setModalAccessDenied}
									projectData={item}
									isMandataire={item.creator === agency}
									isModeLecture={true}
									showNotifications={showNotifications}
								/>
							))
						: allProjects
							?.filter((item) => item.active === false)
							?.map((item, index) => (
								<ProjetCard
									key={index}
									allProjects={true}
									onlyPastProjects={true}
									auth={auth}
									id={item.id}
									seen={item.seen}
									research={research}
									tag={item.tag}
									waitingForApproval={item.waitingStatus === 'pending'}
									setModalWaitForApproval={setModalWaitForApproval}
									accessDenied={item.waitingStatus === 'denied'}
									setModalAccessDenied={setModalAccessDenied}
									projectData={item}
									isMandataire={item.creator === agency}
									isModeLecture={true}
									showNotifications={showNotifications}
								/>
							))}
				</div>

				<BlankCardProjet />
				<BlankCardProjet />
				{research && (
					<div className="noResultResearchComponent">
						<img src={gerySearchIcon} alt="Search Icon" />
						<div>
							<p>{research}</p>
						</div>
						<p className="primaryText">{t('research_project.no_result_1')}</p>
						<p className="secondaryText">{t('research_project.no_result_2')}</p>
					</div>
				)}
			</div>

			<ModauxAddProject auth={auth} modalAddProject={modalAddProject} setModalAddProject={setModalAddProject} />

			<div style={{ zIndex: 1 }}>
				<ModalAddProjectClient
					modalAddProjectClient={modalAddProjectClient}
					setModalAddProjectClient={setModalAddProjectClient}
				/>
			</div>

			<ModalAgencePartenaire
				modalAgencePartenaire={modalAgencePartenaire}
				setModalAgencePartenaire={setModalAgencePartenaire}
			/>
		</div >
	);
}
