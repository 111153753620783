import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import multipleSelection from '../../../../../assets/multipleSelection.svg';
import Modal from 'react-modal';
import crossIcon from '../../../../../assets/crossIcon.png';
import { useProjectContext } from '../../../../../contexts/projectContext';
import ItemAccessibility from '../Document Infos/components/ItemAccessibility';
import { usePartnersContext } from '../../../../../contexts/partnersContext';
import { chooseIconFile, modifyPermissionsFile } from '../../utils';
import { auth } from '../../../../../firebase/config';
import TypeContext from '../../../../../contexts/typeContext';
import AgencyContext from '../../../../../contexts/agencyContext';
import { createdByConstructor } from '../../../utils';
import {
	NotificationSource,
	sendNotificationToClients,
	sendNotificationToCollaborators,
} from '../../../Notifications/utils';
import AuthDataContext from '../../../../../contexts/authDataContext';
import { useClientsContext } from '../../../../../contexts/clientsContext';

export default function ModalPermissions({
	modal,
	setModal,
	uploadedFileIds,
	setUploadedFileIds,
	files,
	setFiles,
	parentFolder,
}) {
	const { t } = useTranslation();
	const uid = auth.currentUser?.uid;
	const userType = useContext(TypeContext);
	const agencyId = useContext(AgencyContext);
	const [project] = useProjectContext();
	const [partners] = usePartnersContext();
	const [clients] = useClientsContext();
	const authData = useContext(AuthDataContext);

	const [clientPermission, setClientPermission] = useState(parentFolder?.clientPermission ?? false);
	const [permissions, setPermissions] = useState(parentFolder?.permissions ?? [agencyId]);

	const [permissionsLoading, setPermissionsLoading] = useState(false);

	const uploadedFiles = files.filter((f) => uploadedFileIds.includes(f.id));
	const filesLoading = uploadedFiles.some((f) => f.isLoading);
	const baseFiles = uploadedFiles.filter(
		(file) => file.path.length === Math.min(...uploadedFiles.map((f) => f.path.length))
	);

	const onValidate = async () => {
		if (!filesLoading) {
			setPermissionsLoading(true);
			for (const file of uploadedFiles) {
				await modifyPermissionsFile(
					uid,
					userType,
					agencyId,
					project.id,
					setFiles,
					file,
					permissions,
					clientPermission,
					true,
					false
				).then();
			}

			for (const id of permissions) {
				sendNotificationToCollaborators(
					createdByConstructor(uid, userType, agencyId),
					id,
					project.id,
					NotificationSource.FILES,
					parentFolder,
					baseFiles,
					authData?.surname + ' ' + authData?.name,
					project?.name,
					project?.imgUrl
				);
			}
			if (clientPermission) {
				sendNotificationToClients(
					createdByConstructor(uid, userType, agencyId),
					project.id,
					NotificationSource.FILES,
					parentFolder,
					baseFiles,
					authData?.surname + ' ' + authData?.name,
					project?.name,
					project?.imgUrl
				);
			}

			setPermissionsLoading(false);
			setModal(false);
			setUploadedFileIds([]);
		}
	};

	return (
		<Modal
			isOpen={modal}
			className={'modalSetAccessDuringImport'}
			overlayClassName="OverlayModal"
			closeTimeoutMS={300}>
			<img
				src={crossIcon}
				alt="crossIcon"
				className={'crossIcon'}
				onClick={() => {
					setModal(false);
					setUploadedFileIds([]);
				}}
			/>

			<p className={'titleModal'}>{t('modalSetAccessDuringImport.newImport')}</p>
			<div className={'containerFirstPart'}>
				<div className={'folderSelected'}>
					<img
						src={
							baseFiles?.length > 1
								? multipleSelection
								: chooseIconFile(baseFiles[0]?.isFolder ? 'folder' : baseFiles[0]?.type)
						}
						alt="crossIcon"
						className={'folderIcon'}
					/>
					<div>
						<p className={'folderName'}>
							{baseFiles?.length > 1 ? t('translation.multipleImport') : baseFiles[0]?.name}
						</p>
					</div>
				</div>
			</div>
			<div className={'bar'} />

			<div className={'choiceActorsComponent'}>
				<p className={'question'}>{t('modalSetAccessDuringImport.selectActorsQuestion')}</p>
				<p className={'subtitle'}>{t('modalSetAccessDuringImport.selectActors')}</p>

				<div className={'listActors'}>
					{!clients?.map((item) => item.id).includes(agencyId) && (
						<ItemAccessibility
							name={t('common.mo')}
							isActivated={clientPermission}
							onClick={() => setClientPermission((prevState) => !prevState)}
							disabled={clients?.length === 0}
						/>
					)}
					{partners.map((it, index) => (
						<ItemAccessibility
							key={index}
							name={it.name}
							isActivated={!!permissions?.find((id) => id === it.id)}
							onClick={() =>
								setPermissions((prev) =>
									prev.includes(it.id) ? prev.filter((id) => id !== it.id) : [...prev, it.id]
								)
							}
						/>
					))}
				</div>
			</div>

			<div className={filesLoading ? 'largeButtonUpload' : 'largeButton'} onClick={onValidate}>
				{filesLoading
					? t('photos.importation')
					: permissionsLoading
						? t('common.loading')
						: t('modalAccessibilityFolder.saveMyChoices')}
			</div>
		</Modal>
	);
}
