import React, { useContext, useEffect, useState } from 'react';
import Menu from '../../components/Main/Menu';
import placeholderLogoAgence from '../../assets/placeholderLogoAgence.png';
import ModalComptesLies from './Comptes Liés/Modaux/ModalComptesLies';
import { auth, firestore } from '../../firebase/config';
import AgencyContext from '../../contexts/agencyContext';
import Widget from './Widget';
import AgencyDataContext from '../../contexts/agencyDataContext';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { useStripeContext } from '../../contexts/stripeContext';

export default function DashboardAgence() {
	const { t } = useTranslation();
	const location = useLocation();

	const agency = useContext(AgencyContext);
	const agencyData = useContext(AgencyDataContext);
	const stripe = useStripeContext();

	const [uid, setUid] = useState(null);
	const [userSession, setUserSession] = useState(null);

	useEffect(() => {
		let listener = onAuthStateChanged(auth, (user) => {
			if (user) {
				setUserSession(user);
				setUid(user.uid);
				if (location.state && location.state.modalToOpen === 'collaborateurs') {
					setIsCollaborators(true);
					setIsClients(false);
					setIsPartners(false);
					setModalComptesLies(true);
				}
			}
		});
		return () => {
			listener();
		};
	}, [userSession]);

	// Menu

	const [lastProject, setLastProject] = useState();

	useEffect(() => {
		const subscriber = firestore
			.collection(`agencies/${agency}/collaborators/${uid}/projects`)
			.orderBy('lastClick', 'desc')
			.limit(1)
			.onSnapshot((querySnapshot) => {
				if (querySnapshot) {
					querySnapshot.forEach((documentSnapshot) => {
						if (documentSnapshot && documentSnapshot.exists) {
							setLastProject(documentSnapshot.id);
						}
					});
				}
			});
		return () => subscriber();
	}, [uid, agency]);

	// Formule d'abonnement

	const [isVIP, setIsVIP] = useState(false);
	const [isCopied, setIsCopied] = useState(false);

	useEffect(() => {
		firestore
			.doc(`agencies/${agency}`)
			.get()
			.then((documentSnapshot) => {
				if (documentSnapshot && documentSnapshot.exists) {
					setIsVIP(documentSnapshot.data().vip);
				}
			});
	}, [agency, uid]);

	// Stripe

	const [quantity, setQuantity] = useState();

	// Modal Comptes liés

	const [modalComptesLies, setModalComptesLies] = useState(false);
	const [isCollaborators, setIsCollaborators] = useState(false);
	const [isClients, setIsClients] = useState(false);
	const [isPartners, setIsPartners] = useState(false);

	// Widgets Comptes Liés
	const [collaborators, setCollaborators] = useState([]);
	const [clients, setClients] = useState([]);
	const [partnerAgencies, setPartnerAgencies] = useState([]);

	// Loading trois types de comptes
	const [loadingCollaborators, setLoadingCollaborators] = useState(true);
	const [loadingClients, setLoadingClients] = useState(true);
	const [loadingPartnerAgencies, setLoadingPartnerAgencies] = useState(true);

	const [agencyProjects, setAgencyProjects] = useState([]);

	useEffect(() => {
		firestore
			.collection(`agencies/${agency}/collaborators`)
			.orderBy('date', 'desc')
			.get()
			.then((querySnapshot) => {
				if (querySnapshot) {
					const accounts = [];
					querySnapshot.forEach((doc) => {
						if (doc && doc.exists && doc.id !== uid && !doc.data().isDeleted) {
							accounts.push({ ...doc.data(), id: doc.id });
						}
					});
					setCollaborators(accounts);
					//time out loading
					setTimeout(() => {
						setLoadingCollaborators(false);
					}, 500);
				}
			});
		firestore
			.collection(`agencies/${agency}/clients`)
			.orderBy('date', 'desc')
			.get()
			.then((querySnapshot) => {
				if (querySnapshot) {
					const accounts = [];
					querySnapshot.forEach((doc) => {
						if (doc && doc.exists && !doc.data().isDeleted) {
							accounts.push({ ...doc.data(), id: doc.id });
						}
					});
					setClients(accounts);
					//time out loading
					setTimeout(() => {
						setLoadingClients(false);
					}, 500);
				}
			});
		firestore
			.collection(`agencies/${agency}/partnerAgencies`)
			.orderBy('date', 'desc')
			.get()
			.then((querySnapshot) => {
				if (querySnapshot) {
					const accounts = [];
					querySnapshot.forEach((doc) => {
						if (doc && doc.exists) {
							accounts.push({ ...doc.data(), id: doc.id });
						}
					});
					setPartnerAgencies(accounts);
					//time out loading
					setTimeout(() => {
						setLoadingPartnerAgencies(false);
					}, 500);
				}
			});
		firestore
			.collection(`agencies/${agency}/projects`)
			.where('own', '==', true)
			.get()
			.then((querySnapshot) => {
				if (querySnapshot) {
					const projectsAgency = [];
					querySnapshot.forEach((documentSnapshot) => {
						if (documentSnapshot && documentSnapshot.exists) {
							projectsAgency.push(documentSnapshot.id);
						}
					});
					setAgencyProjects(projectsAgency);
				}
			});
	}, [uid, agency]);

	const [currency, setCurrency] = useState('eur');

	useEffect(() => {
		fetch('https://ipapi.co/json/')
			.then((response) => response.json())
			.then((data) => {
				setCurrency(data.currency.toLowerCase());
			});
	}, []);

	return (
		<div>
			<div className="leftPart">
				<Menu margin={true} grasComptes={true} lastProject={lastProject} />
			</div>

			<div className="rightPart" style={{ display: 'flex' }}>
				<div className={'dashboardAgence'}>
					<div style={{ display: 'flex' }}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								flexDirection: 'column',
							}}>
							<img
								src={agencyData?.logoUrl ? agencyData?.logoUrl : placeholderLogoAgence}
								alt=""
								className={'logoAgence'}
							/>
						</div>
					</div>

					<div
						onClick={() => {
							navigator.clipboard.writeText(agencyData?.uid);
							setIsCopied(true);
						}}
						className={'hover'}
						style={{
							backgroundColor: '#D6E6F6',
							padding: '10px 20px',
							borderRadius: 8,
							color: '#4B5FB0',
							fontWeight: '600',
							marginTop: 15,
							opacity: isCopied ? 0.6 : 1,
						}}>
						ID : {agencyData ? agencyData.uid : t('common.loading')}
					</div>

					<p
						style={{
							opacity: isCopied ? 1 : 0,
							color: '#4B5FB0',
							fontWeight: '600',
							marginBottom: 0,
						}}>
						{t('translation.copied')}
					</p>

					<div className={'listeLiens'} style={{ paddingTop: 10 }}>
						{!isVIP && (
							<>
								<DesignantPartie title={t('plan_choice.your_plan')} />

								<div style={{ marginLeft: 55, display: 'flex' }}>
									<Widget
										title={
											!stripe.isFreeTrial
												? t('translation.myOpusLicence')
												: t('translation.free_trial')
										}
										facturation={stripe.priceId}
										essaiGratuit={stripe.isFreeTrial}
										currency={currency}
									/>
								</div>
							</>
						)}
						<DesignantPartie title={t('common.linked_accounts')} />
						<div
							style={{
								display: 'flex',
								marginLeft: 55,
							}}>
							<div>
								<Widget
									title={t('common.collabs')}
									accounts={true}
									collaborateurWidget={true}
									setModalComptesLies={setModalComptesLies}
									setIsClients={setIsClients}
									setIsPartners={setIsPartners}
									setIsCollaborators={setIsCollaborators}
									list={collaborators.slice(0, 4)}
									loading={loadingCollaborators}
									agencyProjects={agencyProjects}
								/>
							</div>
							<div>
								<Widget
									title={t('common.clients')}
									accounts={true}
									clientWidget={true}
									setModalComptesLies={setModalComptesLies}
									setIsClients={setIsClients}
									setIsPartners={setIsPartners}
									setIsCollaborators={setIsCollaborators}
									list={clients.slice(0, 4)}
									loading={loadingClients}
									agencyProjects={agencyProjects}
								/>
							</div>
							<div>
								<Widget
									title={t('common.partner_entreprises')}
									accounts={true}
									agencesPartenairesWidget={true}
									setModalComptesLies={setModalComptesLies}
									setIsClients={setIsClients}
									setIsPartners={setIsPartners}
									setIsCollaborators={setIsCollaborators}
									list={partnerAgencies.slice(0, 4)}
									loading={loadingPartnerAgencies}
									agencyProjects={agencyProjects}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ModalComptesLies
				modalComptesLies={modalComptesLies}
				setModalComptesLies={setModalComptesLies}
				collaborateurs={isCollaborators}
				clients={isClients}
				partenaires={isPartners}
				agencyProjects={agencyProjects}
				accounts={isCollaborators ? collaborators : isClients ? clients : partnerAgencies}
				setAccounts={isCollaborators ? setCollaborators : isClients ? setClients : setPartnerAgencies}
			/>
		</div>
	);
}

function DesignantPartie({ title, essaiGratuit }) {
	return (
		<div className={'designantPartie'}>
			<div className={'rondBleu'} style={{ backgroundColor: essaiGratuit ? 'rgba(221,112,44,0.35)' : null }} />
			<div
				className={'containerDesignant'}
				style={{
					backgroundColor: essaiGratuit ? 'rgba(221,112,44,0.2)' : null,
					color: essaiGratuit ? '#DD702C' : null,
				}}>
				{title}
			</div>
		</div>
	);
}
