import React, { useContext, useState } from 'react';
import AgencyContext from '../../../../contexts/agencyContext';
import crossIcon from '../../../../assets/crossIcon.png';
import Modal from 'react-modal';
import ModalPassword from '../../../../components/ModalPassword';
import { deleteUserInProject, deleteMessaging } from '../../../../useful/UsefulForProject';
import { useTranslation } from 'react-i18next';
import { firestore } from '../../../../firebase/config';

export default function ModalDeletePartner({
	modal,
	setModal,
	accountData,
	setAccounts,
	setIsModifContactOpen,
	agencyProjects,
}) {
	const { t } = useTranslation();

	const agency = useContext(AgencyContext);

	const [modalPassword, setModalPassword] = useState(false);

	async function suppr() {
		await firestore.doc(`agencies/${agency}/partnerAgencies/${accountData.uid}`).delete();

		for (const projectId of agencyProjects) {
			firestore
				.collection(`projects/${projectId}/accounts`)
				.where('agency', '==', accountData.uid)
				.get()
				.then((querySnapshot) => {
					if (querySnapshot) {
						querySnapshot.forEach(async (documentSnapshot) => {
							if (documentSnapshot && documentSnapshot.exists) {
								await deleteUserInProject(
									documentSnapshot.id,
									'collaborators',
									accountData.uid,
									projectId,
									agency
								);
							}
						});
					}
				});
			await firestore.doc(`agencies/${accountData.uid}/projects/${projectId}`).delete();
		}

		setAccounts((prevState) => prevState.filter((item) => item.uid !== accountData.uid));
		setIsModifContactOpen(false);
	}

	return (
		<>
			<Modal
				isOpen={modal}
				className={'modalConfirmationSuppressionCompte'}
				overlayClassName="OverlayModal"
				closeTimeoutMS={300}>
				<img
					src={crossIcon}
					alt="crossIcon"
					className={'crossIcon'}
					onClick={() => {
						setModal(false);
					}}
				/>

				<div className={'center'}>
					<p className={'felicitation'}>{t('delete_partner.confirmation')}</p>

					<div className={'emailContainer'}>
						<p className={'email'}>{accountData.name}</p>
					</div>

					<p className={'detailFelicitation'}>{t('delete_partner.want_to_delete_partner_agency')}</p>

					<p className={'detailFelicitation'}>{t('delete_partner.by_deleting_infos')}</p>

					<div style={{ display: 'flex', marginTop: 20 }} className={'buttonGroup'}>
						<div
							className={'jeConfirmeButton'}
							onClick={() => {
								setModal(false);
								setModalPassword(true);
							}}>
							<p className={'textJeConfirme'}>{t('common.delete')}</p>
						</div>
					</div>
				</div>
			</Modal>

			<ModalPassword modalPassword={modalPassword} setModalPassword={setModalPassword} toExecute={suppr} />
		</>
	);
}
